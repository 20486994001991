import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { PartTimeHours } from "../pension-timeline-logic.js";

export class ServiceAdjustment extends Event {
    get is_primary() { return true; }
    get id() { return `ADJUST:${this.adjustment.id}:${this.date - 0}: ${this.adjustment.service_hours}: ${this.adjustment.service_years}: ${this.adjustment.service_years}: ${this.adjustment.credited_service_years} `; }
    get str() { return `${this.adjustment.adjustment_type.name.toUpperCase()} ADJ @${this.date} ${[['HRS', 'service_hours'], ['VEST YRS', 'service_years'], ['CRD YRS', 'credited_service_years']].filter(([n, t]) => this.adjustment[t]).map(([n, t]) => `${n}: ${this.adjustment[t]}`).join(' ')} `; }

    constructor(adjustment) {
        super();
        this.adjustment = adjustment;
        this.date = new EventDate(adjustment.adjustment_date);

    }

    apply(state) {
        if (state.date.equals(this.date)) {
            if (this.adjustment.adjustment_type.code === 'PT') {
                new PartTimeHours(this.date, state.basis, this.adjustment.service_hours, this.adjustment, 0).insert(state);
                //state.addServiceHours(this.adjustment.service_hours, state.date, this);
                //state.recordSeries({ date: this.date, series: "part_time_hours", brief_text: `PT HRS: ${this.adjustment.service_hours}`, labels: [`part-time credit`, `${this.adjustment.service_hours} hours`], event: this, data: this.adjustment });
            } else {
                state.addAdjServiceHours(this.adjustment.service_hours, state.date, this);
                state.addVestingYears(this.adjustment.service_years, this.date);
                state.addCreditedYears(this.adjustment.credited_service_years, this.date);
                state.log_service("adjustment applied", this.date);
                const lbls = [[['service_hours', 'hours'], ['service_years', 'vesting years'], ['credited_service_years', 'credited service years']]
                    .filter(([field, unit]) => field in this.adjustment && this.adjustment[field])
                    .map(([field, unit]) => field in this.adjustment ?
                        this.adjustment[field] < 0 ?
                            `(${this.adjustment[field].toLocaleString()} ${unit})` :
                            ` + ${this.adjustment[field].toLocaleString()} ${unit} ` : '')
                    .join(", ")];
                state.recordLabel({ date: this.date, series: "manual_adjustment", labels: lbls, event: this, data: this.adjustment });
            }
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
