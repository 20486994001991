import { EventDate } from "../eventdate.js";
import { MONEY, KMONEY, TENTHS, internal_build, Tag, COMPUTE_CONTRIBUTION_INTEREST, comp_stl, DEBUG_HOURS, DEBUG_HOUR_ACCRUALS, EstimatedContribution, DEBUG_SVC, TimelineLabel, TimelineSpan, TimelineSeries } from "../pension-timeline-logic.js";

export class State {
    snapshot(phase) {
        return {
            date: this.date,
            age: this.age,
            tag_events: this.tag_events,
            tag_list: this.tag_list,
            status: Symbol.keyFor(this.status),
            status_info: this.status_info,
            service_hours: this.service_hours,
            ytd_service_hours: this.ptd_service_hours,
            ptd_service_hours: this.ptd_service_hours,
            ptd_participation_hours: this.ptd_participation_hours,
            vesting_years: this.vesting_years,
            ytd_vesting_years: this.ptd_vesting_years,
            ptd_vesting_years: this.ptd_vesting_years,
            credited_years: this.credited_years,
            ytd_credited_years: this.ptd_credited_years,
            ptd_credited_years: this.ptd_credited_years,
            contribution_balance: this.contribution_balance,
            contribs: [...this.contribs],
            contrib_history: [...this.contrib_history],
            contribution_interest: this.contribution_interest,
            ytd_contribution_interest: this.ytd_contribution_interest,
            active_events: this.active_events,
            all_events: this.all_events,
            salaries: this.salaries,
            sal0: this.sal(0),
            sal1: this.sal(1),
            sal2: this.sal(2),

            projection: this.projection,
            runpoint: this.runpoint,
            endpoint: this.endpoint,
            dob: this.dob,
            deceased: this.deceased,

            basis: this._basis,
            benefit_start: this.benefit_start,
            hired: this.hired,
            participating: this.participating,
            contributing: this.contributing,
            old_dates: this.old_dates,
            vested: this.vested,
            early_ret_elig_date: this.early_ret_elig_date,
            full_early_ret_elig_date: this.full_early_ret_elig_date,
            full_ret_elig_date: this.full_ret_elig_date,
            required_beginning: this.required_beginning,
            disability_last_accrual: this.disability_last_accrual,
            disability_end: this.disability_end,
            opted_out: this.opted_out,
            opted_in: this.opted_in,

            in_service: this.in_service,
            prorate_final_estimate: this.prorate_final_estimate,
            phase: phase,

            employer: this.employer_code,
            group: this.computed_group,
            terminated: this.last_terminated && !this.employer_code ? this.last_terminated : null,


            ret_estimates: { ...this.ret_estimates },


            //performance: this.event_performance
            report: this.report,
            service_logs: [...this.service_logs]
        };
    }

    get report() {
        const { status_info, service_hours, ytd_service_hours, ptd_service_hours, vesting_years, ptd_vesting_years, credited_years, ptd_credited_years, contribution_balance,
            //contribution_interest,
            ytd_contribution_interest, hired, benefit_start, basis, participating, contributing, vested, opted_in, opted_out, early_ret_elig_date, full_early_ret_elig_date, full_ret_elig_date, computed_group, deceased, active_events, service_lost, runpoint, endpoint, projection, ret_estimates, dob, age
        } = this;

        const suspension = active_events.suspension;
        const employment = active_events.employment;
        const retirement = this.lastActivity('retirement');
        const last_employment = this.lastActivity('employment');
        const retirements = this.anyActivity('retirement');
        const first_retired = retirements.length > 1 ? retirements.slice(1).reduce((acc, cur) => !acc.span_ends || acc.span_ends.equals(cur.span_begins) ? acc : cur, retirements[0]) : retirement;

        const tensify = (check, past, future) => {
            return check > runpoint ? future : past;
        };

        const is_fut = this.date > runpoint;

        return [
            // top line ("Full Time at XYZ")
            {
                checkpoint: false,
                key: 'Status',
                val: status_info.messages,
                pri: status_info.messages[0],
                sec: [status_info.messages.slice(1).join(', ')]
            },

            projection ? {
                checkpoint: false,
                key: 'DOB',
                val: dob,
                pri: 'DOB',
                sec: [`${dob?.nb_str} (${age?.toFixed?.(1)} y/o)`],
                fsec: [`${dob?.nb_str} (will be ${age?.toFixed?.(1)} y/o)`],
            } : null,

            // retired?
            retirement && !retirement.span_ends && first_retired ? {
                key: 'Ret. Begin Date',
                val: first_retired?.span_begins,
                date: first_retired?.span_begins,
                pri: "Retired since",
                fpri: 'Retiring on',
                sec: [`${first_retired.span_begins.nb_str}`]
            } : null,

            // retirement over?
            retirement?.span_ends ? {
                key: 'Ret. End Date',
                val: retirement?.span_ends,
                date: retirement?.span_ends,
                pri: "Retirement ended",
                fpri: 'Retirement ending',
                sec: [`${retirement?.span_ends.nb_str}`]
            } : null,

            // employment over?
            (!employment || this.date.equals(employment.span_ends)) && last_employment ? {
                key: 'Emp. End Date',
                val: last_employment?.span_ends,
                date: last_employment?.span_ends,
                pri: "Employment ended",
                fpri: "Employment ending",
                sec: [`${last_employment.span_ends.nb_str}`]
            } : null,

            //service lost?
            this.service_lost || (!basis && last_employment) ? {
                key: 'Service Lost Date',
                val: service_lost,
                date: service_lost,
                pri: 'Lost service',
                fpri: 'Losing service',
                sec: [`${service_lost || '(unknown)'}`]
            } : null,

            // suspended?
            suspension ? {
                key: 'Suspension Begin Date',
                date: suspension?.span_begins,
                pri: 'Suspended',
                sec: [`${suspension?.span_begins?.nb_str || '(unknown)'}`]
            } : null,

            // first hired
            hired ? {
                key: "First Hired Date",
                val: hired,
                date: hired,
                pri: "First hired",
                sec: [`${hired.nb_str}`]
            } : null,

            // hired @
            employment && basis && !basis.equals(employment.span_begins) ? {
                key: 'Emp. Begin Date',
                val: employment?.span_begins,
                date: employment?.span_begins,
                pri: `${employment.rehire ? employment.rehire : 'Hired'} ${employment.employer.code}`,
                sec: [`${employment?.span_begins?.nb_str || '(unknown)'}`]
            } : null,

            //benefit start
            benefit_start ? {
                key: 'Benefit Start Date',
                val: benefit_start,
                date: benefit_start,
                pri: "Benefit Start",
                sec: [`${benefit_start.nb_str}`]
            } : null,

            // basis date
            basis ? {
                key: 'Basis Date',
                val: basis,
                date: basis,
                pri: "Basis",
                sec: [`${basis.nb_str}`]
            } : null,

            
            // opt-out
            opted_out ? {
                key: 'Opted Out Date',
                val: opted_out,
                date: opted_out,
                pri: "Opted out",
                fpri: "Opting out",
                sec: [`${opted_out.nb_str}`]
            } : null,

            // opt-in
            opted_in ? {
                key: 'Opted In Date',
                val: opted_in,
                date: opted_in,
                pri: "Opted in",
                fpri: "Opting in",
                sec: [`${opted_in.nb_str}`]
            } : null,

            // partic date
            participating ? {
                key: 'Participation Begin Date',
                val: participating,
                date: participating,
                pri: "Participant",
                sec: [`since ${participating.nb_str}`],
                fsec: [`on ${participating.nb_str}`]
            } : null,

            // contrib date
            contributing ? {
                key: 'Contribution Begin Date',
                val: contributing,
                date: contributing,
                pri: "Contributor",
                sec: [`since ${contributing.nb_str}`],
                fsec: [`on ${contributing.nb_str}`]
            } : null,

            // vest date
            vested ? {
                key: 'Vesting Date',
                val: vested,
                date: vested,
                pri: "Vested",
                fpri: "Vesting",
                sec: [`since ${vested.nb_str}`],
                fsec: [`on ${vested.nb_str}`]
            } : null,

            // vesting years
            basis || vesting_years ? {
                key: 'Vesting Years',
                val: vesting_years + ptd_vesting_years,
                qty: vesting_years + ptd_vesting_years,
                pri: [`${(vesting_years + ptd_vesting_years).toLocaleString()}`],
                sec: ["vesting years", `(incl. ${ptd_vesting_years.toLocaleString()} PTD)`]
            } : null,

            // credited svc
            basis || credited_years ? {
                key: "Credited Service Years",
                precision: 0.001,
                val: credited_years + ptd_credited_years,
                qty: credited_years + ptd_credited_years,
                pri: [`${(credited_years + ptd_credited_years).toLocaleString()}`],
                sec: ["credited svc. years", credited_years === 40 ? "(at max.)" : `(incl. ${ptd_credited_years.toLocaleString()} PTD)`]
            } : null,

            // EE contrib bal
            contributing || this.last_contribution ? {
                key: "Contribution Balance",
                val: contribution_balance,
                bal: contribution_balance,
                amt: contribution_balance,
                pri: [`Contribution balance ${MONEY(contribution_balance)}`],
                sec: [`(plus ${MONEY(ytd_contribution_interest)} YTD interest)`]
            } : null,

            // early ret elig date?
            (!((deceased || retirement) || full_early_ret_elig_date) && early_ret_elig_date)
                || (!deceased && projection && early_ret_elig_date && early_ret_elig_date > runpoint)
                ? {
                    key: "Early Ret. Eligible Date",
                    val: early_ret_elig_date,
                    date: early_ret_elig_date,
                    pri: "Eligible for early ret.",
                    sec: [`since ${early_ret_elig_date.nb_str}`],
                    fsec: [
                        `on ${early_ret_elig_date.nb_str}`,
                        ret_estimates.early ? `(~${MONEY(ret_estimates.earlyla)}/mo; ${ret_estimates.early.service} yrs. svc.)` : null,
                        ret_estimates?.early?.clamped ? ` (${KMONEY(ret_estimates?.early?.clamped)} annual max. applies)` : null,
                        ret_estimates?.early?.early ? ` (${TENTHS(ret_estimates?.early?.early)} mos. early)` : null,
                    ].filter(f => f),
                    est: ret_estimates.early
                } : null,

            /*
            // early ret estimate?
            (
                (!deceased && !retirement && !full_early_ret_elig_date && early_ret_elig_date)
            || (!deceased && projection && early_ret_elig_date && early_ret_elig_date > runpoint)
            ) && ret_estimates.early && early_ret_elig_date > runpoint
            ? {
                date: early_ret_elig_date,
                pri: `Early ret. annuity`,
                sec: `~${MONEY(ret_estimates.early.sla)} on ${ret_estimates.early.d.nb_str}`,
            } : null,
            */
            // unred ret elig date?
            (!((deceased || retirement) || full_ret_elig_date) && full_early_ret_elig_date)
                || (!deceased && projection && full_early_ret_elig_date && full_early_ret_elig_date > runpoint && !(full_ret_elig_date.equals(full_early_ret_elig_date)))
                ? {
                    key: "Full Early Ret. Eligible Date",
                    val: full_early_ret_elig_date,
                    date: full_early_ret_elig_date,
                    pri: "Eligible for unreduced early ret.",
                    sec: [`since ${full_early_ret_elig_date.nb_str}`],
                    fsec: [
                        `on ${full_early_ret_elig_date.nb_str}`,
                        ret_estimates.unreduced ? `(~${MONEY(ret_estimates.unreduced.sla)}/mo)` : null,
                        ret_estimates.unreduced ? `(~${MONEY(ret_estimates.unreduced.sla)}/mo; ${ret_estimates.unreduced.service} yrs. svc.)` : null,
                        ret_estimates?.unreduced?.clamped ? ` (${KMONEY(ret_estimates?.unreduced?.clamped)} annual max. applies)` : null,
                        ret_estimates?.unreduced?.early ? ` (${TENTHS(ret_estimates?.unreduced?.early)} mos. early)` : null,
                    ].filter(f => f),
                    est: ret_estimates.unreduced
                } : null,


            /*
            // unred ret est?
            (
                (!deceased && !retirement && !full_ret_elig_date && full_early_ret_elig_date)
                || (!deceased && projection && full_early_ret_elig_date && full_early_ret_elig_date > runpoint && !(full_ret_elig_date.equals(full_early_ret_elig_date)))
            ) && ret_estimates.unreduced && full_early_ret_elig_date > runpoint
            ? {
                date: full_early_ret_elig_date,
                pri: `Unreduced early ret. annuity`,
                sec: `~${MONEY(ret_estimates.unreduced.sla)} on ${ret_estimates.unreduced.d.nb_str}`,
            } : null,
            */
            // full ret elig date?
            (!(deceased || retirement) && full_ret_elig_date)
                || (!deceased && projection && full_ret_elig_date && full_ret_elig_date > runpoint)
                ? {
                    key: "Full Ret. Eligible Date",
                    val: full_ret_elig_date,
                    date: full_ret_elig_date,
                    pri: "Eligible for full ret.",
                    sec: [`since ${full_ret_elig_date.nb_str}`],
                    fsec: [
                        `on ${full_ret_elig_date.nb_str}`,
                        ret_estimates.normal ? `(~${MONEY(ret_estimates.normal.sla)}/mo; ${ret_estimates.normal.service} yrs. svc.)` : null,
                        ret_estimates?.normal?.clamped ? ` (${KMONEY(ret_estimates?.normal?.clamped)} annual max. applies)` : null,
                        ret_estimates?.normal?.early ? ` (${TENTHS(ret_estimates?.normal?.early)} mos. early)` : null,
                    ].filter(f => f),
                    est: ret_estimates.normal
                } : null,


            /*
            // full ret est
            (
                (!deceased && !retirement && full_ret_elig_date)
                || (!deceased && projection && full_ret_elig_date && full_ret_elig_date > runpoint)
            ) && ret_estimates.normal && full_ret_elig_date > runpoint
            ? {
                date: full_ret_elig_date,
                pri: `Full ret. annuity`,
                sec: `~${MONEY(ret_estimates.normal.sla)} on ${ret_estimates.normal.d.nb_str}`,
            } : null,
            */
            projection && ret_estimates.target && !isNaN(ret_estimates?.target?.sla) && [early_ret_elig_date, full_early_ret_elig_date, full_ret_elig_date].every(d => !(d?.equals(ret_estimates.target?.d))) ? {
                key: "Estimated SLA (monthly)",
                val: ret_estimates.target.sla,
                amt: ret_estimates.target.sla,
                date: ret_estimates.target.d,
                pri: `Est. SLA ~${MONEY(ret_estimates.target.sla)}/mo`,
                sec: [
                    `on ${ret_estimates.target.d.nb_str}`,
                    `(${ret_estimates?.target?.service} yrs. svc.)`,
                    ret_estimates?.target?.clamped ? ` (${KMONEY(ret_estimates?.target?.clamped)} annual max. applies)` : null,
                    ret_estimates?.target?.early ? ` (${TENTHS(ret_estimates?.target?.early)} mos. early)` : null,
                    ':'
                ].filter(s => s),
                est: ret_estimates.target
            } : null,


            !projection ? {
                checkpoint: false,
                key: 'age',
                val: dob,
                pri: `${age?.toFixed?.(1)}`,
                sec: ['years old'],
                fsec: ['years old (projected)'],
            } : null,

            // group
            {
                key: "Group Code",
                val: computed_group,
                code: computed_group,
                pri: "Computed Group Code",
                sec: [`${computed_group}`]
            },

            //debugging:
            internal_build === 'local' ? {
                date: this.date,
                pri: 'date:',
                sec: [`${this.date}`]
            } : null,
            internal_build === 'local' ? {
                date: this.date,
                pri: 'age:',
                sec: [`${this.age}`]
            } : null,
            internal_build === 'local' ? {
                qty: service_hours,
                pri: 'hours:',
                sec: [`${service_hours}`]
            } : null,
        ].filter(n => n).map(n => ({ ...n, is_future: n?.date ? n?.date > this.runpoint : is_fut }));
    }

    constructor(state) {
        if (state) {
            // copy over:
            this.show_logs = state.show_logs ?? false;
            this.db_factors = state.db_factors;
            this.person_id = state.person_id;
            this.name = state.name;
            this.date = new EventDate(state.date);
            this.status = state.status;
            this.tags = new Map(state.tags);
            this.pending_events = state.pending_events;
            this.parameters = { ...state.parameters };

            this.snapshots = state.snapshots;
            this.years = state.years;
            this.contexts = state.contexts;

            this.nodes = state.nodes;
            this.series_nodes = state.series_nodes;
            this.activities = state.activities;

            this.errors = state.errors;
            this.error_count = new Map(state.error_count);

            this.in_service_spans = [...state.in_service_spans];
            this.participating_spans = [...state.participating_spans];
            this.permanent_withdrawals = [...state.permanent_withdrawals];
            this.period_info = new Map(state.period_info);
            this.ptd_period_info = new Map(state.ptd_period_info);
            this._ytd_service_hours = state._ytd_service_hours;
            this._ytd_service_hour_adj = state._ytd_service_hour_adj;
            this.vesting_years = state.vesting_years;
            this.multipliers = state.multipliers.map(m => ({ ...m }));
            this.multiplier_rollovers = [...state.multiplier_rollovers];
            this.disjoint_periods = [...state.disjoint_periods];
            this.registered_periods = [...state.registered_periods];

            this.service_logs = [...state.service_logs];

            this.service_bank = state.service_bank.map(c => ({
                type: c.type,
                amount: c.amount,
                expires: c.expires,
            }));

            this.free_years = state.free_years;
            this.breaks_in_service = state.breaks_in_service; // count of consecutive one year breaks in service
            this.ytd_deposited_interest = state.ytd_deposited_interest;
            this.contribs = [...state.contribs];
            this.contrib_history = [...state.contrib_history];
            this.raw_contribs = [...state.raw_contribs];
            this.contribution_balance = state.contribution_balance;
            this.contribution_interest = state.contribution_interest;
            this.contribution_cancelations = state.contribution_cancelations;
            this.raw_salaries = [...state.raw_salaries];
            this.real_salaries = [...state.real_salaries];
            this.monthly_salaries = [...state.monthly_salaries];
            this.ret_estimates = { ...state.ret_estimates };
            this.last_contribution = state.last_contribution;
            this.deficient_since = state.deficient_since;

            this.employment = state.employment;

            this.payments = state.payments;

            this.projection = state.projection;
            // Dates:
            this.runpoint = state.runpoint;
            this.endpoint = state.endpoint;
            this.end_cond = state.end_cond;
            this.end_cond_reached = state.end_cond_reached;
            this.expires = state.expires;
            this.dob = state.dob;
            this.deceased = state.deceased;
            this._basis = state._basis; //basis is the most recent hire date, used as base date for monthly service
            this.benefit_start = state.benefit_start; //the date that a person starts to work in a benefit eligible unit
            this.participating = state.participating; //usually is the date that participant accumulated first year of service
            this.hired = state.hired; //hired is the initial hire date after service resets
            this.contributing = state.contributing; //date that participant is required to contribute
            this.vested = state.vested;
            this.early_ret_elig_date = state.early_ret_elig_date;
            this.full_early_ret_elig_date = state.full_early_ret_elig_date;
            this.full_ret_elig_date = state.full_ret_elig_date;
            this.required_beginning = state.required_beginning;
            this.disability_last_accrual = state.disability_last_accrual;
            this.disability_end = state.disability_end;

            this.opted_out = state.opted_out;
            this.opted_in = state.opted_in;
            this.prorate_final_estimate = state.prorate_final_estimate,

            this.employment_dates = [...state.employment_dates];
            this.suspension_dates = [...state.suspension_dates];
            this.suspension_return_dates = [...state.suspension_return_dates];
            this.termination_dates = [...state.termination_dates];

            this.service_hour_mismatch = state.service_hour_mismatch;

        } else {
            this.parameters = {};
            this.steps = 0;
            this.applied_events = 0;
            this.event_performance = [];
            this.person_id = null;
            this.date = null;
            this.status = State.NeverEmployed;
            this.tags = new Map([[Tag.NeverEmployed, new Set()]]);
            this.pending_events = [];
            //this.applied_events = [];
            //this.current_events = [];
            this.snapshots = [];
            this.years = [];
            this.contexts = [];
            //this.recorded_events = [];
            this.nodes = [];
            this.series_nodes = [];
            this.activities = {};
            this.errors = [];
            this.error_count = new Map();

            /*
            this.employments = [];
            this.suspensions = [];
            this.retirements = [];
            this.beneficiaries = [];
            this.benefactors = [];
            */
            this.period_info = new Map();
            this.ptd_period_info = new Map();
            this._ytd_service_hours = 0;
            this._ytd_service_hour_adj = 0;
            this.vesting_years = 0;
            this.multipliers = [];
            this.in_service_spans = [];
            this.participating_spans = [];
            this.permanent_withdrawals = [];
            this.disjoint_periods = [];
            this.registered_periods = [];
            this.service_logs = [];
            this.multiplier_rollovers = [];
            this.subscriptions = [];

            this.service_bank = [];
            this.free_years = 0;
            this.breaks_in_service = 0;
            this.ytd_deposited_interest = 0;
            this.contribs = [];
            this.contrib_history = [];
            this.raw_contribs = [];
            this.contribution_balance = 0;
            this.contribution_interest = 0;
            this.contribution_cancelations = [];
            this.raw_salaries = [];
            this.real_salaries = [];
            this.monthly_salaries = [];
            this.ret_estimates = {};
            this.last_contribution = null;
            this.deficient_since = null;

            this.employment = null;

            this.payments = 0;

            this.projection = null;
            // Dates:
            this.runpoint = null;
            this.endpoint = null;
            this.end_cond = (s) => s?.date?.equals(s?.endpoint);
            this.end_cond_reached = false;
            this.expires = null;
            this.dob = null;
            this.deceased = null;
            this._basis = null;
            this.benefit_start = null;
            this.hired = null;
            this.participating = null;
            this.contributing = null;
            this.vested = null;
            this.early_ret_elig_date = null;
            this.full_early_ret_elig_date = null;
            this.full_ret_elig_date = null;
            this.required_beginning = null;
            this.disability_last_accrual = null;
            this.disability_end = null;

            this.opted_out = null;
            this.opted_in = null;
            this.prorate_final_estimate = true,

            this.employment_dates = [];
            this.suspension_dates = [];
            this.suspension_return_dates = [];
            this.termination_dates = [];

            this.service_hour_mismatch = 0;


            // HACKS:
            this.event_duplication_prevention_map = new Map();
        }
    }

    setWakeup(wake, evt) {
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `${ evt.str } requested wakeup @${ wake.str } `);
        if (wake > this.runpoint && (!this.expires || wake < this.expires)) {
            this.expires = wake;
        }
    }

    get basis() { return this._basis; } 
    set basis(b) {
        if (this._basis !== b) {
            this._basis = b;
            this.announce('basis_change', b);
        }
    }

    get returning() {
        //let ret = 
        return [...this.employment_dates, ...this.suspension_return_dates].filter(d => d > this.date).sort((a, b) => a - b)[0];
        //return ret.length > 0 ? ret[0] : null;
    }
    get departing() {
        // let ret = 
        return [...this.termination_dates, ...this.suspension_dates].filter(d => d > this.date).sort((a, b) => a - b)[0];
        //return ret.length > 0 ? ret[0] : null;
    }
    get next_employed() {
        return this.employment_dates.filter(d => d > this.date).sort((a, b) => a - b)[0];
    }

    get next_terminated() {
        return this.termination_dates.filter(d => d > this.date).sort((a, b) => a - b)[0];
    }

    get last_terminated() {
        return this.termination_dates.filter(d => d < this.date).sort((a, b) => b - a)[0];
    }

    get age() {
        if (!(this.dob && this.date)) {
            return null;
        }
        return this.date.year - this.dob.year;
    }


    get credited_years() {
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date, "GET CREDITED YEARS", `was ${ (this.credited_years_A + this.credited_years_B + this.credited_years_C) } now ${ this.multipliers.reduce((acc, cur) => acc + cur.credited_years, 0) } `)
        return this.multipliers.reduce((acc, cur) => acc + cur.credited_years, 0);
        //return (this.credited_years_A + this.credited_years_B + this.credited_years_C);
    }

    get ytd_service_hours() {
        //return this._ytd_service_hours;
        return this.adj_ytd_service_hours;
    }

    get ptd_service_hours() { return this.getPTDTotalServiceHoursFromInfo(); }
    get ptd_participation_hours() { return this.getPTDTotalParticipHoursFromInfo(); }
    get ptd_adjusted_service_hours() { return this.getPTDTotalAdjustedServiceHoursFromInfo(); }
    get ptd_adjusted_participation_hours() { return this.getPTDTotalAdjustedParticipHoursFromInfo(); }

    get i_service_hours() { return this.getTotalServiceHoursFromInfo(); }
    get service_hours() { return this.getTotalServiceHoursFromInfo(); }
    get i_participation_hours() { return this.getTotalParticipHoursFromInfo(); }
    get i_adjusted_service_hours() { return this.getTotalAdjustedServiceHoursFromInfo(); }
    get i_adjusted_participation_hours() { return this.getTotalAdjustedParticipHoursFromInfo(); }

    get adj_ytd_service_hours() {
        return this._ytd_service_hours + (this._ytd_service_hours + this._ytd_service_hour_adj > 2280 ? 2280 : this._ytd_service_hour_adj);
    }

    get calendar_ytd_service_hours() {
        let last_year = this.date.getFullYear() - 1;
        let last_year_data = this.years[this.years.length - 1];
        if (last_year_data && last_year_data.year !== last_year) {
            last_year_data = this.years.find(y => y.year === last_year);
        }
        return last_year_data ? this.service_hours - last_year_data.state.service_hours : 0;
    }


    get ytd_vesting_years() {
        let normal = this.adj_ytd_service_hours >= 1000 ? 1 : 0;
        //let disability = this.disability_last_accrual ? this.disability_last_accrual.yearsUntil(this.date < this.disability_end ? this.date : this.disability_end) : 0;
        return normal; // + disability > 1 ? 1 : normal + disability;
    }
    get ptd_vesting_years() {
        return this.ptd_service_hours >= 1000 ? 1 : 0;
    }

    get ptd_total_vesting_years() {
        return this.vesting_years + this.ptd_vesting_years;
    }

    get ytd_credited_years() {
        let normal = this.adj_ytd_service_hours >= 2000 ? 1 : this.adj_ytd_service_hours / 2000;
        //console.log(`%cCALC`, comp_stl, `ytd_credited_years @${ this.date } = ${ this.adj_ytd_service_hours } / 2000 = ${normal}`)
        //let disability = this.disability_last_accrual ? this.disability_last_accrual.yearsUntil(this.date < this.disability_end ? this.date : this.disability_end) : 0;
        return normal; //+ disability > 1 ? 1 : normal + disability;
    }
    get ptd_credited_years() {
        let hours = this.ptd_participation_hours;
        let years = hours >= 2000 ? 1 : hours / 2000;
        let y = years;
        let current_years = this.credited_years;
        years = current_years + years <= 40 ? years : 40 - current_years;
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "###\nPTD_CREDITED_YEARS", this.date, `hours: ${hours}, years: ${y}, curr_years: ${current_years}, final: ${years}`)
        return years;
    }

    get ptd_total_credited_years() {
        let years = this.credited_years + this.ptd_credited_years;
        return years > 40 ? 40 : years;
    }

    get ytd_contribution_interest() {
        //if (this.last_contribution) {
        const last = this.contribs.length > 0 ? this.contribs[this.contribs.length - 1].date : null;
        if (last) {
            return COMPUTE_CONTRIBUTION_INTEREST(this.contribution_balance, last.yearsUntilDuodecimal(this.date));
            //FIXME: these should use the same code and return the same #:
            //this.getEstimatedContributions().ytd_int;
        }
        return 0;
    }

    get any_retirement_eligible_date() {
        return this.early_ret_elig_date ?? this.full_early_ret_elig_date ?? this.full_ret_elig_date;
    }

    getBasisMonthFromDate(d, b) {
        const basis = b ? b : (this.basis ? this.basis : this.last_basis);
        const months = (base, date) => Math.floor(((date.getFullYear() - base.getFullYear()) * 12) + (date.getMonth() - base.getMonth()) + (date.getDate() / 30 - base.getDate() / 30));
        return months(basis, d);
    }

    recordPeriodInfo(reason, date = this.date(), hours = 0, adj = 0, particip_override) {
        const basis = this.basis ? this.basis : this.last_basis;
        if (!basis) {
            if (hours === 0 && adj === 0) return;
            console.error("%cCALC", comp_stl, this.person_id, "recordPeriodInfo, but no basis!", reason, basis, date, hours, adj, particip_override);
            if (this.show_logs) console.log("%cCALC", comp_stl, "state", this);
            this.recordError({ evt: null, code: "programming error", msgs: ["programming error", "basis info to record but no basis"] });
            return;
        }
        let monthnum = this.getBasisMonthFromDate(date, basis);
        let period = `${basis.nb_str}-${monthnum}`;
        let participatory = particip_override || this.isParticipatingAt(date);
        let existing = this.period_info.get(period);

        if (existing) {
            if (hours >= 190 && existing.hours >= 190) {
                if (this.show_logs) console.warn("%cCALC", comp_stl, date, existing.date, "probably duplicated period");
            }
            this.period_info.set(period, {
                ...existing,
                dates: [...existing.dates, date],
                adj: existing.hours + hours >= 190 ? 0 : (existing.adj + adj) - (existing.hours + hours),
                hours: existing.hours + hours >= 190 ? 190 : existing.hours + hours,
                participatory: existing.participatory || participatory
            });
            //if (this.show_logs) console.log(`%cCALC`, comp_stl, date, "revised period info", { ...this.period_info.get(period) })
        } else {
            this.period_info.set(period, { date, dates: [date], period, basis: basis, monthnum: monthnum, hours, adj, participatory });
            //if (this.show_logs) console.log(`%cCALC`, comp_stl, date, "set period info", { ...this.period_info.get(period) })
        }

        //console.log("RECORD PERIOD INFO", reason, date, hours, adj);
        this.ptd_period_info.set(period, this.period_info.get(period));
    }

    addAdjServiceHours(hours, date, evt) {
        // FIXME: this to recordPeriodInfo should be deprecatable
        /*
        this.service_hours += hours;
        this._ytd_service_hour_adj += hours;
        */
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `adding ${hours} adjust hours @${date.nb_str}`)
        this.recordPeriodInfo('addAdjSvc', date, 0, hours);
        this.announce('service_hours');
        DEBUG_HOURS(2, `DIRECT adding ${hours} service hours @${this.date} => ${this.service_hours + hours}\t\tFROM: ${evt.str}`);
    }

    log_service(reason, date = this.date) {
        let log = {
            date,
            reason,
            hours_ptd: this.ptd_service_hours,
            service_credited: this.credited_years,
            service_ptd: this.ptd_credited_years,
            service_total: this.ptd_total_credited_years,
            vesting_credited: this.vesting_years,
            vesting_ptd: this.ptd_vesting_years,
            vesting_total: this.ptd_total_vesting_years
        };
        this.service_logs.push(log);
    }

    addServiceHours(hours, date, evt) {
        DEBUG_HOURS(2, `adding ${hours} service hours @${this.date} => ${this.ytd_service_hours + hours} \t\tFROM: ${evt.str} `);
        if (DEBUG_HOUR_ACCRUALS) this.recordLabel({ date: date, series: "hours", labels: [`+ ${hours} h`, evt.str], event: evt });

        /*
        // FIXME: this to recordPeriodInfo should be deprecatable
        if (this._ytd_service_hours + hours <= 2280) {
            this.service_hours += hours;
            this._ytd_service_hours += hours;
        } else {
            let msg = [`tried to add ${hours} hours`, `already had ${this._ytd_service_hours} ytd`];
            console.error(`%cCALC`, comp_stl, this.person_id, msg, date, evt.str);
            this.recordError({ evt: evt, code: "service hour fault", msgs: ['too many hours', ...msg, evt.str] });
            this.service_hours += 2280 - this._ytd_service_hours;
            this._ytd_service_hours = 2280;
        }
    */
        this.recordPeriodInfo(`addservicehours: ${evt ? evt.str : 'no evt'}`, date, hours, 0);
        this.announce('service_hours');
        this.announce('ytd_hours');
    }

    getTotalServiceHoursFromInfo() {
        return Array.from(this.period_info.values()).reduce((sum, next) => sum + next.hours + next.adj, 0);
    }
    getTotalParticipHoursFromInfo() {
        return Array.from(this.period_info.values()).filter(h => h.participatory).reduce((sum, next) => sum + next.hours + next.adj, 0);
    }
    getTotalAdjustedServiceHoursFromInfo() {
        return Array.from(this.period_info.values()).reduce((sum, next) => sum + next.adj, 0);
    }
    getTotalAdjustedParticipHoursFromInfo() {
        return Array.from(this.period_info.values()).filter(h => h.participatory).reduce((sum, next) => sum + next.adj, 0);
    }
    getPTDTotalServiceHoursFromInfo() {
        //return Array.from(this.ptd_period_info.values()).reduce((sum, next) => sum + next.hours + next.adj, 0);
        return this.getPTDTotalParticipHoursFromInfo();
    }
    getPTDTotalParticipHoursFromInfo() {
        return Array.from(this.ptd_period_info.values()).filter(h => h.participatory).reduce((sum, next) => sum + next.hours + next.adj, 0);
    }
    getPTDTotalAdjustedServiceHoursFromInfo() {
        return Array.from(this.ptd_period_info.values()).reduce((sum, next) => sum + next.adj, 0);
    }
    getPTDTotalAdjustedParticipHoursFromInfo() {
        return Array.from(this.ptd_period_info.values()).filter(h => h.participatory).reduce((sum, next) => sum + next.adj, 0);
    }


    /*
    addPTHours(hours, date, evt) {
        this.pt_hours.push({ hours: hours, date: date });
        if (this.show_logs) console.log(`%cCALC`, comp_stl, "banking", hours, 'pt hours', date, [...this.pt_hours]);
    }
    nextPTHours() {
        return this.pt_hours.shift();
    }
    */
    clearYTDHours() {
        this._ytd_service_hours = 0;
        this._ytd_service_hour_adj = 0;
        this._ytd_vest_only_hours = [];

        this.ptd_period_info = new Map();
        this.announce('ytd_hours');
    }

    addVestingYears(years, date) {
        this.vesting_years += years;
        this.announce('vesting_years');
    }

    /*
    // FIXME: this is the old behavior where the multipliers are recorded based on period dates
    // also the multiplier_rollover

    addCreditedYears(years, date) {
        // TODO: may need to track actual date of accumulation, in order to track disjoint benefit periods (caused by unrepaid withdrawals)
        let service_year = date.previousAnniversaryOf(this.basis);
        let current_years = this.credited_years;
        let clamped_years = current_years + years <= 40 ? years : 40 - current_years;
        if (years > 0) {
            let m = this.multipliers.find((m, i, next) => (m.effective === null || m.effective <= service_year) && (!next[i + 1] || service_year < next[i + 1].effective));
            m.credited_years += clamped_years;
            m.raw_years += years;
            m.credits.push({ years: clamped_years, raw: years, date: date });
            if (this.show_logs) console.warn(`%cCALC`, comp_stl, "ADDING CREDITED YEARS", years, date, m);
        }
        this.announce('credit_years');
    }
    */
    addCreditedYears(years, date) {
        // TODO: may need to track actual date of accumulation, in order to track disjoint benefit periods (caused by unrepaid withdrawals)
        //let service_year = date.previousAnniversaryOf(this.basis);
        let current_years = this.credited_years;
        let clamped_years = current_years + years <= 40 ? years : 40 - current_years;
        if (years > 0) {
            let m = this.multipliers.find((m, i, next) => (m.effective === null || m.effective <= date) && (!next[i + 1] || date < next[i + 1].effective));
            m.credited_years += clamped_years;
            m.raw_years += years;
            m.credits.push({ years: clamped_years, raw: years, date: date });
            //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "ADDING CREDITED YEARS", date, `${current_years}+${years}`);
        }
        this.announce('credit_years');
    }

    addServiceSeparation(date) {
        if (this.show_logs) console.warn("%cCALC", comp_stl, "NOT YET IMPLEMENTED");
    }

    realSalaryChange({ id, effective_date, salary }) {
        effective_date = new EventDate(effective_date);
        salary = Number(salary);

        this.real_salaries = [
            ...this.real_salaries
                .filter(s => s.id !== id && !s.effective_date.equals(effective_date)),
            { id, effective_date, salary }
        ].sort((a, b) => a.effective_date - b.effective_date);
    }
    get salaries() {
        // TODO: figure out how to incorporate separated service
        let seen_years = new Set();
        let s = this.raw_salaries
            .sort((a, b) => a.year === b.year ? b.bsalary - a.bsalary : b.year - a.year)
            //.map(({ year, salary }) => ({ year, salary }))
            .filter(s => !seen_years.has(s.year) && seen_years.add(s.year));
        return s;
        //.reduce((o, { year, salary }) => ({ ...o, [year]: salary }), {})
        //return this.raw_salaries./*sort((a, b) => b.year - a.year).slice(0, 3).*/reduce((o, { date, salary }) => ({ ...o, [date.getFullYear()]: salary }), {})
    }
    sal(n) {
        let sal = this.salaries.find(s => s.year === this.date.year - n - 1); //.sort((a, b) => b.year - a.year)[n];

        //if (this.show_logs) console.log(`%cCALC`, comp_stl, `${ this.date }: \nsal(${ n }) => \n${ this.raw_salaries.map(s => `\n${s.year}:${s.salary}`) } => \n${ this.raw_salaries.sort((a, b) => b.year - a.year).map(s => `\n${s.year}:${s.salary}`) } => \n${ sal ? sal.year : 'null' }: ${ sal ? sal.salary : 'null' } `)
        return sal ? sal.bsalary : null;
    }



    /*
    The “Compensation” used to determine your Average Final Compensation under the
    Pension Plan formula is your base pay in effect from month to month and actually paid to
    you, plus any elective salary deferrals you make to a salary deferral plan of your
    employer (like a section 125 or 401(k) plan).
    Base pay includes your regular salary or wages, but does not include overtime pay,
    bonus pay, severance pay, sick leave pay, per diem, expense reimbursements
    (regardless of tax treatment), expense allowances, car allowances, or other fringe
    benefits.
    Federal tax law limits the maximum amount of Compensation that may be taken into
    account in any year in calculating your pension benefit. For 2015, that maximum is
    $265,000, but it may increase in future years based on cost-of-living increases.
    */
    /*
    Your Average Final Compensation is the annual average of your highest 36 consecutive
    months of Compensation. If you do not have 36 consecutive months of Compensation,
    your full period of employment for which you were credited with Years of Credited Service
    is used to determine your Average Final Compensation.
    Any months of employment in which you do not receive any Compensation, are not
    entitled to receive any Compensation, or in which your participant contributions are
    temporarily suspended are excluded in determining your highest 36 consecutive months
    of Compensation to be used for your Average Final Compensation.
    If you have a military leave during your employment as a participant, your Compensation
    for the period of such leave for purposes of the Pension Plan formula will be deemed to
    be at the pay rate you would have received if you had not been on leave or, if that rate
    cannot be determined, your average rate of pay during the 12 months preceding your
    leave, provided you return to employment following military service with reinstatement
    rights under the Uniformed Services Employment and Reemployment Rights Act of 1994
    (USERRA). In addition, any differential wage payments you receive will be included in
    your Compensation to the extent required by the Heroes Earnings Assistance and Relief
    Tax (HEART) Act of 2008.
    */
    push_contrib(c) {
        this.contribs.push(c);
        this.contrib_history.push(c);
    }

    raw_contribution(c) {
        this.raw_contribs = [...this.raw_contribs, { ...c, d: new EventDate(c.contribution_date) }];
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "RAW CONTRIB", this.raw_contribs);
    }

    currentRawContrib() {
        let older = this.raw_contribs.filter(c => c.d < this.date).sort((a, b) => b.d - a.d);
        let newer = this.raw_contribs.filter(c => c.d >= this.date).sort((a, b) => a.d - b.d);
        let last = older ? older[0] : null;
        let next = newer ? newer[0] : null;
        return next ? next : last; //next?.eoy_base_salary : last?.eoy_base_salary;
    }

    projectedAnnualContribSalary() {
        let contrib = this.currentRawContrib();
        if (this.projection && contrib?.d && contrib.d < this.date) {
            let rate = this?.projection?.rate || 0;
            let years = Math.floor(contrib.d.yearsUntil(this.date));
            return contrib.eoy_base_salary * (1 + rate * years);
        }
        return contrib?.eoy_base_salary;
    }

    projectedAnnualHistorySalary(d) {
        let relevant = this.real_salaries.filter(s => s.effective_date <= this.date);
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "PROJECTED SALS", this.date);
        // if (this.show_logs) console.log(`%cCALC`, comp_stl, "REAL SALS", [...this.real_salaries]);
        // if (this.show_logs) console.log(`%cCALC`, comp_stl, "RELEV", [...relevant]);
        //  if (this.show_logs) console.log(`%cCALC`, comp_stl, "LAST", relevant[relevant.length - 1]);
        return relevant.length > 0 ? relevant[relevant.length - 1]?.salary : this.projectedAnnualContribSalary();
    }

    projectedAnnualSalary() {
        if (this.projection?.history && this.real_salaries?.length > 0) {
            return this.projectedAnnualHistorySalary();
        }
        return this.projectedAnnualContribSalary();
    }

    get projected_salaries() {
        //salaries => {year, bsalary, psalary}
        //Override sal with 0 if person is terminated
        let census_salary_history = this.salaries.map(sal => ({ 
            effective_date: new EventDate(new Date(sal.year+1, 0, 0)), 
            salary_amt: sal.bsalary 
        })).filter((e)=> e.salary_amt != undefined);
        const projection_salaries = this.projection?.salaries.map(({ effective_date, salary_amt }) => ({ salary_amt, projection: true, effective_date: new EventDate(effective_date) })).filter((e)=> e.salary_amt != undefined);
        let projected_salaries_start = false;
        let all_salaries = [...census_salary_history, ...projection_salaries].sort((a, b) => a.effective_date - b.effective_date)?.filter?.(s => {
            projected_salaries_start = s.projection || projected_salaries_start;        
            return projected_salaries_start ? s.projection : true;
        });
        return all_salaries;
    }

    get_effective_salary_at(start) {
        //let month_span = [...this.monthly_salaries];
        return this.projected_salaries.sort((a, b) => new Date(b.effective_date) - new Date(a.effective_date)).find(s => s.effective_date <= start).salary_amt;
        }

    projectedProratedSalary(start, end) {
        // FIXME: break into spans with possibly different sals
        if (this.projection?.salaries) {
            const salaries = this.projected_salaries?.map(({ effective_date, salary_amt }) => ({ salary_amt, effective_date: new EventDate(effective_date) }));
            //make sure salary is sorted
            let current = { effective_date: start, salary_amt: this.get_effective_salary_at(start) };
            //next might be null or date 
            let next = salaries?.find?.(s => (s.effective_date > current.effective_date) && (end > s.effective_date)) ?? { effective_date: end };
            let prorated_salary = 0;
            //return current.sal_amt;
            while (current.effective_date < end) {
                prorated_salary += current.salary_amt * current?.effective_date?.yearsUntilDuodecimal?.(next?.effective_date);
                current = next;
                next = salaries?.find?.(s => (s.effective_date > current.effective_date) && (end > s.effective_date)) ?? { effective_date: end };
            }
            return prorated_salary;
            //return this.monthly_salaries.filter(dt => start <= new Date(dt.effective_date) <= end).slice(-1)[0].sal
        }
        let sal = this.projectedAnnualSalary();
        let yrs = this.yearsParticipatingBetween(start, end, true); // Fixme: use duodecimal math
        return yrs * sal;
    }

    addMonthlySalaryData(svcmnt, basis, number, eom) {
        // TODO: This is where we meet want to break into quarters as well
        //let next_projected = this.next_projected_annual_salary() //new sal begin date and amount
        //let intra_month_sal = null
        //if (next_projected.date < "30 days") {
        // intra_month_sal = next_projected
        //}
        let sal = this.projectedAnnualSalary(); 
        if (this.projection?.salaries) {
            let override_salary = this.projection.salaries.sort((a, b) => new Date(b.effective_date) - new Date(a.effective_date)).find(s => new Date(s.effective_date) <= this.date);
            if (override_salary) {
                sal = override_salary.salary_amt;
            }
            // sort the salaries in reverse order and if the date is in there, overwrite it with the inputted salaries             
        }
        this.monthly_salaries = [...this.monthly_salaries, {
            d: new EventDate(this.date),
            basis: new EventDate(basis),
            number,
            eom: new EventDate(eom),
            sal
        }];
        /*
        this.monthly_salaries = [...this.monthly_salaries, {
            d: new EventDate(this.date),
            basis: new EventDate(basis),
            sal_change: intra_month_sal
            number,
            eom: new EventDate(eom),
            sal
        }];
       */
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "MONTHLY SAL", this.date, sal, this.monthly_salaries[this.monthly_salaries.length-1]);
    }


    averageFinalCompensation() {
        const max_compensation = this.parameters?.max_compensation ?? 265000;
        if (this.projection?.avg_salary !== undefined) return {
            avg: this.projection?.avg_salary > max_compensation ? max_compensation : this.projection?.avg_salary,
            clamped: this.projection?.avg_salary > max_compensation
        };

        const highestconsecsals = (sals, win_sz) => {
					let ordered = [...sals].reverse(); // newest first
			    // compute sum for a window on [0, win_sz]
					let best_i = (win_sz - 1);
					let best_sum = ordered.slice(0, win_sz).reduce((sum, m) => sum + m.sal, 0);
					let sum = best_sum;
			    
			    // loop through the rest of the possible windows, [i-win_sz, i]
					for (let i = win_sz; i < ordered.length; i++) {
				    // subtract old left sal and add new right sal to slide sum into window
						sum += (ordered.at(i).sal ?? 0) - (ordered.at(i - win_sz)?.sal ?? 0);
						if (sum > best_sum) {
							best_sum = sum;
							best_i = i;
						}
					}
					return ordered.slice(best_i - (win_sz-1), best_i+1);
				};
        //modify this consecutive amount to be 144 instead now
        const add_days = (d, days) => {
            return new EventDate(d, days, 'days')
        };
        //FIXME:  sals might break on edge case with salary increase within the month
        let q_sals = highestconsecsals(this.monthly_salaries.reduce((acc, cur) => [...acc, ...([0,1,2,3].map(i => (
            {
                basis: cur.basis, 
                sal: cur.sal, 
                number: cur.number*4 + i, 
                d: add_days(cur.d, 7.5 * i), 
                eom: add_days(cur.eom, 7.5 * (3 - i))
            }
                )))
            ],
                 []), 144); // monthly salaries broke into quarter month spans (7.5 days)
        let sals = highestconsecsals(this.monthly_salaries, 36); // monthly sals originally implemented
        //console.warn("144 sals", q_sals);
        //console.warn("36 sals", sals);     
        let clamped_sals = sals.map(s => ({ ...s, is_clamped: s.sal > max_compensation, clamped: s.sal > max_compensation ? max_compensation : s.sal }));
        let clamped = sals.some(s => s.is_clamped) ? max_compensation : null;
        let sum = clamped_sals.map(s => s.clamped).reduce((p, c) => c + p, 0);
        let ret = { avg: sum / sals.length, clamped };
        if (this.show_logs) console.groupCollapsed(`%coverall average final compensation = ${ret.avg} ${clamped ? `(some sals clamped at ${clamped})` : ""}`, comp_stl);
        if (this.show_logs) console.table([...this.monthly_salaries].reverse().map(m => { //this table now has to be adjusted since we broke into 144 spans instead of 36
            let included = clamped_sals.find(s => s.number === m.number);
            return {
                in_best_36: included ? true : false,
                eom: m.eom,
                sal: included?.clamped ?? m.sal
            };
        }));
        if (this.show_logs) console.groupEnd();

        return ret;
    }



    /*
    The Pension Plan formula determines the annual amount you will
    receive if your pension is paid to you beginning at your Normal Retirement Date (when
    you attain age 65) in the form of a Single Life Annuity. As explained below under “When
    May You Begin to Receive Your Pension” and “Forms of Benefit Payment,” if your benefit
    is paid beginning at a different time or in a different form, the annual amount you will
    receive will be actuarially adjusted.
    */
    /*
    Federal tax rules limit the maximum annual benefit that may be paid under the Pension
    Plan to any participant. For pensions beginning in 2015, the maximum annual benefit
    payable to a participant at age 65 may not exceed the lesser of $210,000, or 100% of the
    average of the participant’s highest three years of compensation. The annual maximums
    are adjusted actuarially for pensions that begin to be paid earlier or later than normal
    19
    retirement age (age 65). These maximums may be increased in later years based on
    cost-of-living increases. You will be informed when you apply for your pension benefit if
    these limits may affect you.
    */
    /*
If you are credited with at least five (5), but fewer than 20 Years of Service for vesting
purposes, you may retire and begin to receive your pension as early as age 55.
However, the amount of your monthly payments will be actuarially reduced from the full
amount under the Pension Plan formula. The reduction will be based on your age when
payments begin.
The Pension Plan’s actuary determines, each year, the actuarial factors used to calculate
reduced early retirement payments for pensions that begin to be paid in that year, based
on specified mortality and interest rate assumptions.
    */
    /*
    need beneficiary DOB, default is same as EE

    compute SLA

    J&S, 10yr, Early and lump sum factors come from tables

    */
    computeAgeBreakdown(dob, min) {
        let age = this.date.year - dob.year;
        age = age < min ? min : age;
        let years = Math.floor(age);
        let months = Math.floor(12 * (age - years));
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `NOW=${this.date} - DOB=${dob} => age=${age}, y=${years}, m=${months}`);
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `${this.date.year} - ${dob.year}`)
        return {
            date: new EventDate(this.date),
            dob,
            age,
            years,
            months,
            syears: String(years),
            smonths: String(months),
            sage: String(Math.round(age))
        };
    }


    get beneficiary_dob() {
        if (this?.projection?.beneficiary_dob) {
            return this?.projection?.beneficiary_dob;
        }
        let b = this.activeActivity('beneficiary');
        if (b?.beneficiary?.birth_date) {
            return new EventDate(b?.beneficiary?.birth_date);
        }
        return new EventDate(this.dob);
    }
    get beneficiary_age() {
        return this.date.year - this.beneficiary_dob.year;
    }

    get ret_est_svc() {

        let mults = [...this.multipliers.map(m => ({ ...m }))];

        let service_year = (this.date)?.previousAnniversaryOf(this.basis);
        let ptd_years = this.ptd_credited_years;
        let current_years = this.credited_years;
        ptd_years = current_years + ptd_years <= 40 ? ptd_years : 40 - current_years;

        let previous_years = 0;
        this.registered_periods.forEach(p => {
            //previous_years += p.credited_years;
            /*
            p.mults.forEach((m,i) => {
                mults[i].credited_years -= m.credited_years;
            });*/
        });
        let credited_years = current_years + ptd_years - previous_years;


        if (ptd_years > 0) {
            let m = mults.find((m, i, next) => (m.effective === null || m.effective <= service_year) && (!next[i + 1] || service_year < next[i + 1]?.effective));
            m.credited_years = (m.credited_years ?? 0) + ptd_years;
        }

        return {
            mults,
            credited_years,
            vesting_years: this.ptd_total_vesting_years
        };

    }


    makeRetEstimate() {
        if (!this.basis && !this.hired) {
            if (this.show_logs) console.warn("not an employee");
            return {};
        }
        if (this.age < 10) {
            if (this.show_logs) console.warn("AGE <10 years");
            return {};
        }
        if (this.show_logs) console.group(`%cpension estimates for ${this.date}`, comp_stl);

        let ages = {
            ee: this.computeAgeBreakdown(new EventDate(this.dob), 55),
            beneficiary: this.computeAgeBreakdown(new EventDate(this.beneficiary_dob), 35),
        };

        if (this.show_logs) {
            console.groupCollapsed(`%cestimate effective ages (ee: ${ages.ee.years}y${ages.ee.months}m, bfc: ${ages.beneficiary.years}y${ages.beneficiary.months}m)`, comp_stl);
            console.table(ages, ['date', 'dob', 'age', 'years', 'months']);
            console.groupEnd();
        }
        let withdrawals = [...this.disjoint_periods.filter(p => p.reason === "withdrawal" || p.reason === "perm_withdrawal").map(p => ({ ...p }))];

        let current_svc = this.ret_est_svc;

        if (this.show_logs) {
            console.groupCollapsed(`%coverall computed svc = ${current_svc.credited_years} / ${current_svc.vesting_years} vesting`, comp_stl);
            console.table(current_svc?.mults, ['effective', 'multiplier', 'credited_years', 'raw_years']);
            console.log(current_svc);
            console.groupEnd();
        }

        let service_periods = [
            //...this.disjoint_periods.map(p => ({...p, withdrawn: true})),
            ...this.registered_periods,
            this.computeServicePeriod(this.date)
        ];
        service_periods = service_periods.map((p, i) => ({
            ...p,
            // compute net service (years accrued in this period) by subtracting previous:
            net_service: i === 0 ? p.credited_years : p.credited_years - service_periods[i - 1].credited_years,
            // ditto for the multiplier divisions:
            net_mults: i === 0 ? p.mults : (p.mults.map((m, j) => ({
                ...m,
                credited_years: m.credited_years - service_periods[i - 1].mults[j].credited_years
            }))),
            ages
        }));
        //if (this.show_logs) console.log(`%cCALC`, comp_stl, "RET ESTIMATE DISJOINT", this.disjoint_periods);
        //if (this.show_logs) console.log("AGES", ages,"JS50", this.joint_and_survivor_lookup(ages, factors.joint50));
        //if (this.show_logs) console.log("JS75", this.joint_and_survivor_lookup(ages, factors.joint75));
        //if (this.show_logs) console.log("JS100", this.joint_and_survivor_lookup(ages, factors.joint100));
        let months_early = 65 > ages.ee.age ? Math.round((65 - ages.ee.age) * 12 * 30) / 30 : 0;
        let early_retirement_factor = this.ee_only_loopup(ages, this.db_factors.early_factors);

        if (this.show_logs) console.log(`%capplicable early factor: ${early_retirement_factor} (early by ${Math.round(months_early)} mnths)`, comp_stl);
        let sub_estimates = service_periods.filter((svc_pd)=> svc_pd.net_service != 0).map((
            {
                net_mults, net_service, start, end, average_salary, clamped_salary, ctrb_ests
            }, i) => {

            if (this.show_logs) console.groupCollapsed(`%cservice period ${i} (${start}-${end})...`, comp_stl);
            //FIXME:  this will probably need fixing for withdrawal repayments (repayment remove withdrawal disjoint)
            let withdrawn = withdrawals.some(w => w.date >= start);
            //if (this.show_logs) console.log(`%cCALC`, comp_stl, "WITHDRAWN?", start, end, ctrb_ests.withdrawal_date);
            
            let pmults = net_mults.map(m => ({
                ...m,
                pension: Math.round(100 * average_salary * m.credited_years * m.multiplier) / 100,
                early_factor:
                    // if any of the full service conditions for the multiplier epoch are met...
                    (m?.full_conditions?.some(condition => (condition.age <= ages.ee.age && condition.vesting <= current_svc.vesting_years)))
                        // ...then no adjustment:
                        ? 1
                        // otherwise, it's the previously computed age penalty factor:
                        : early_retirement_factor
            })).map(m => ({
                ...m,
                adjusted: m.early_factor * m.pension
            }));
            let raw_sla = pmults.reduce((acc, cur) => acc + Math.round(cur.adjusted * 100), 0) / 100;
            let unreduced_sla = pmults.reduce((acc, cur) => acc + Math.round(cur.pension * 100), 0) / 100; //unreduced SLA for tax calculations
            if (this.show_logs) {
                console.groupCollapsed(`%cestimated SLA = ${raw_sla}`, comp_stl);
                console.table(pmults, ['effective', 'multiplier', 'credited_years', 'pension', 'early_factor', 'adjusted']); //.map(({effective, multiplier, credited_years, raw_years, credits, pension, early_adjustment_needed, fulladjusted}) => { }));
                console.groupEnd();
            }


            //if (this.show_logs) console.log(`%cCALC`, comp_stl, "EST", est);
            let monthly_raw_sla = raw_sla / 12;
            let contribs = ctrb_ests.bal;
            if (this?.projection?.contribs) {
                contribs = this?.projection?.contribs;
            }

            let early_withdrawal_factor = this.ee_only_loopup(ages, current_svc.credited_years < 20 ? this.db_factors.lump20 : this.db_factors.lump20plus);
            let early_withdrawal_penalty_amount = contribs * early_withdrawal_factor;
            let early_withdrawal_ratio = (early_withdrawal_penalty_amount / 12) / monthly_raw_sla;
            // Should change nontaxable for nontaxable calcs 
            let { taxable, nontaxable } = this.computeTaxable(new EventDate(ages.ee.dob), unreduced_sla, contribs, 0, (contribs - ctrb_ests.total_interest), new EventDate(this.date));

            //raw_sla = withdrawn ? raw_sla * (1-early_withdrawal_factor) : raw_sla;
            let js50 = monthly_raw_sla * this.joint_and_survivor_lookup(ages, this.db_factors.joint50);
            let js75 = monthly_raw_sla * this.joint_and_survivor_lookup(ages, this.db_factors.joint75);
            let js100 = monthly_raw_sla * this.joint_and_survivor_lookup(ages, this.db_factors.joint100);
            let c10 = monthly_raw_sla * this.ee_only_loopup(ages, this.db_factors.certain10);
            let withdrawal_factor = 1 - early_withdrawal_ratio;

            const ret = {
                period_start: start,
                period_end: end,
                ages,

                sla: monthly_raw_sla,
                js50,
                js75,
                js100,
                certain10: c10,

                withdrawn,

                wsla: monthly_raw_sla * withdrawal_factor,
                wjs50: js50 * withdrawal_factor,
                wjs75: js75 * withdrawal_factor,
                wjs100: js100 * withdrawal_factor,
                wcertain10: c10 * withdrawal_factor,

                clamped: clamped_salary,
                service: net_service,
                early: early_retirement_factor < 1 ? months_early : 0,
                early_factor: early_retirement_factor,

                withdrawal_amt: contribs,
                withdrawal_factor,

                total_contribs: contribs - ctrb_ests.total_interest,
                total_interest: ctrb_ests.total_interest,
                contribs,

                avg_salary: average_salary,
                clamped_salary,
                taxable,
                nontaxable
                //taxable: this.computeTaxable(new EventDate(ages.ee.dob), est, contribs, 0, (contribs-ctrb_ests.total_interest), new EventDate(this.date))
            };
            if (this.show_logs) {
                console.table({ ...ret, ages: { ee: ret.ages.ee.age, bfc: ret.ages.beneficiary.age } });
                console.groupEnd();

            }
            return ret;

        });
        //let last = sub_estimates[sub_estimates.length - 1];
        let max_benefit = this.parameters?.max_benefit ?? 210000;
        //max_benefit = max_benefit < average_salary ? max_benefit : average_salary;
        /*
        let maxxed = est > max_benefit;
        est = maxxed ? max_benefit : est;
        */
        let sum = sub_estimates.reduce((acc, cur) => ({
            ...cur,
            period_start: acc.period_start < cur.period_start ? acc.period_start : cur.period_start,
            period_end: acc.period_end > cur.period_end ? acc.period_end : cur.period_end,
            sla: acc.sla + (cur.withdrawn ? cur.wsla : cur.sla),
            js50: acc.js50 + (cur.withdrawn ? cur.wjs50 : cur.js50),
            js75: acc.js75 + (cur.withdrawn ? cur.wjs75 : cur.js75),
            js100: acc.js100 + (cur.withdrawn ? cur.wjs100 : cur.js100),
            certain10: acc.certain10 + (cur.withdrawn ? cur.wcertain10 : cur.certain10),
            wsla: acc.wsla + cur.wsla,
            wjs50: acc.wjs50 + cur.wjs50,
            wjs75: acc.wjs75 + cur.wjs75,
            wjs100: acc.wjs100 + cur.wjs100,
            wcertain10: acc.wcertain10 + cur.wcertain10,
            service: acc.service + cur.service
        }), { service: 0, sla: 0, js50: 0, js75: 0, js100: 0, certain10: 0, wsla: 0, wjs50: 0, wjs75: 0, wjs100: 0, wcertain10: 0 });

        if (this.show_logs) {
            console.groupCollapsed(`%call periods (${sum.period_start}-${sum.period_end})...`, comp_stl);
            console.table({ ...sum, ages: { ee: ages.ee.age, bfc: ages.beneficiary.age } });
            console.groupEnd();
        }

        //if (this.show_logs) console.log(`%cCALC`, comp_stl, "star", start, "end", end, "spans", this.in_service_spans);
        let spans = this.in_service_spans
            //.filter(s => s.date <= end && s.date >= start)
            .reduce((acc, cur) => {
                if ((acc.next.start && acc.next.end) || (cur.beginning && acc.next.start) || (cur.ending && acc.next.end)) {
                    acc.prior = [...acc.prior, acc.next];
                    acc.next = {};
                }
                if (cur.beginning && !acc.next.start) {
                    acc.next.start = cur.date;
                }
                if (cur.ending && !acc.next.end) {
                    acc.next.end = cur.date;
                }
                return acc;
            },
                { prior: [], next: {} });
        spans.next.end = spans.next.end ? spans.next.end : new EventDate(this.endpoint, -1, 'day');
        spans = [...spans.prior, spans.next];
        if (this.show_logs) console.groupEnd();
        return {
            ...sum,
            spans,
            sub_estimates
        };
    }

    joint_and_survivor_lookup({ ee: { sage: e }, beneficiary: { sage: b } }, jtable) {
        let ret = jtable?.[e]?.[b] ?? null;
        return ret;
    }

    ee_only_loopup({ ee: { syears: y, smonths: m } }, ee_table) {
        let ret = ee_table?.[y]?.[m] ?? null;
        return ret;
    }

    computeTaxable(dob, annuity, balance, contribs_pre1987, contribs_post1986, estimate_date) {
        if (this.time_travel) {
            return false;
        }
        // QUESTION/FIXME: sheet mentions "annuity benefit at age 65", but the argument here is the estimated annuity at the target age
        const next_first_of_month_following_dob = new EventDate(dob.getDate() > 1 ? new Date(dob.getFullYear(), dob.getMonth() + 1, 1) : new Date(dob));
        const rounded_age = Math.round(next_first_of_month_following_dob.yearsUntil(estimate_date));
        const rate = Math.round(this.db_factors.tax_rates_120pct_midterm[String(estimate_date.getMonth() + 1)][String(estimate_date.getFullYear())] / 0.002) * 0.002;


        // construct some memoized intermediate functions
        // We can now query this directly from the memmber table in factors
        //const l = memoize((age) => factors.mortalitytable_90cm?.[String(age)]?.lx);
        //const Q = memoize((age) => factors.mortalitytable_90cm?.[String(age)]?.qx);
        //const V = memoize((age) => 1/(Math.pow(1+rate, age)));
        //const I = memoize((age) => age === 20 ? 10000000 : I(age-1)*(1-Q(age-1)));
        //const D = memoize((age) => V(age) * I(age));
        //const N = memoize((age) => age === 20 ? Array(90).fill().reduce((acc,cur, i) => acc+D(i+20), 0) : N(age-1)-D(age-1));
        //const N12 = memoize((age) => N(age)-((11/24)*D(age)));
        //const A12 = memoize((age) => N12(age)/D(age));
        //
        // compute the factor based on age
        //const get_fac = (age) => Math.min(N12(65),N12(age))/D(age);
        const get_fac = (age) => this.db_factors.member?.[String(rounded_age)]?.["65-x|äx12"];

        const fac = get_fac(rounded_age);

        // magic formula (?!?) from tax calc sheet (Taxes.E20)
        let nontaxable = Math.min(Math.round((balance - contribs_pre1987) * contribs_post1986 / (annuity * fac) / 0.01) * 0.01 + contribs_pre1987, contribs_post1986+contribs_pre1987);

        if (this.show_logs) {
            console.groupCollapsed(`%cnontaxable = ${nontaxable}`, comp_stl);
            console.log(`%cnext f-o-m after DOB: ${next_first_of_month_following_dob}, rounded age: ${rounded_age}, 120% mid term rate: ${rate}`, comp_stl);
            console.log(`%cannuity factor: ${fac}, contribs pre-'87: ${contribs_pre1987}, post-'86: ${contribs_post1986}, balance: ${balance}, SLA: ${annuity}`, comp_stl);
            console.log(`%c=> non-taxable = round( (${balance} - ${contribs_pre1987}) * ${contribs_post1986}/(${annuity}*${fac})  ) + ${contribs_pre1987} = ${nontaxable}`, comp_stl);
            console.groupEnd();
        }
        return {
            nontaxable,
            taxable: balance - nontaxable,
        };

    }

    getEstimatedContributions() {
        //FIXME: Make sure that the end date is always being computed at retirement date
        //let est = new EstimatedContribution(this.date, this);
        //let end = new EventDate(new Date(this.date.getFullYear()+1, 0,0));
        let end = new EventDate(new Date(this.date));
        let start = new EventDate(new Date(this.date.getFullYear(), 0, 0));
        let est = new EstimatedContribution(end, this, "this is state.getEstimatedContributions");
        let params = est.computeParameters(this);
        let ctrb = est.makeContribEntry(this, params);
        let yrs = this.yearsParticipatingBetween(start, this.date, true);
        let withdrawal_date = this.withdrawn;
        return {
            bal: this.contribution_balance,
            ytd_int: ctrb.interest * yrs,
            ytd_amt: ctrb.amt * yrs,
            total_contribs: ctrb.total_contribs,
            total_interest: ctrb.total_interest,
            withdrawal_date
        };
        //return this.contribution_balance + this.ytd_contribution_interest;
    }


    /*
    // FIXME: defunct
    earlyReduction(sla, months, params) {
        let value = sla * params.member_months;
        let extra = sla * (params.member_months + months);
        return value/extra;
    }*/
    JSReduction(sla, share, params) {
        let value = sla * params.member_months;
        let both = sla * params.member_months + (share * sla) * params.survivor_months;
        return value / both;
    }

    withdrawalReduction(sla, amt, params) {
        /* FIXME: Dead code. possibly remove if unused
           If you are fully vested in your pension benefit when you receive the withdrawal,
           you will not lose your Service Credit under the Pension Plan, and you will be
           entitled, when you retire, to receive a pension based on your Service Credit, but
           the monthly amount of your pension will be actuarially reduced by the value of
           your withdrawn participant contributions. */
        let value = sla * params.member_months;
        let reduced = value - amt;
        return reduced / value;
    }


    addRetEstimate(name) {
        this.ret_estimates[name] = {
            name,
            d: this.date,
            ...this.makeRetEstimate()
        };
        this.ret_estimates.last = this.ret_estimates[name];
    }

    saveService() {
        //console.log(`%cCALC`, comp_stl, "SAVING PI", Array.from(this.period_info.values()));
        // console.log(`%cCALC`, comp_stl, "SAVING PTDI", Array.from(this.ptd_period_info.values()));
        return {
            date: new EventDate(this.date),
            period_ends: new EventDate(this.date.nextAnniversaryOf(this.basis)),
            period_info: Array.from(this.period_info.entries()).map(([period, entry]) => [period, { ...entry }]),
            //ptd_period_info: Array.from(this.period_info.entries()).map(([period, entry]) => [period, { ...entry }]),
            vesting_years: this.vesting_years,
            ytd_vesting_years: this.ytd_vesting_years,
            multipliers: this.multipliers.map(m => ({ ...m })),
            ptd_credited_years: this.ptd_credited_years
            //service_hours: this.service_hours,
            //ytd_service_hours: this.ytd_service_hours,
        };
        //this.period_info = new Map(state.period_info);
        //this.ptd_period_info = new Map(state.ptd_period_info);
        /*
        this.period_info.set(period, { date, dates: [date], period, basis: basis, monthnum: monthnum, hours, adj, participatory });

        this.period_info.set(period, {
            ...existing,
            dates: [...existing.dates, date],
            adj: existing.hours + hours >= 190 ? 0 : (existing.adj + adj) - (existing.hours + hours),
            hours: existing.hours + hours >= 190 ? 190 : existing.hours + hours,
            participatory: existing.participatory || participatory
        });
getTotalServiceHoursFromInfo() {
    return Array.from(this.period_info.values()).reduce((sum, next) => sum + next.hours + next.adj, 0);
}
getTotalParticipHoursFromInfo() {
    return Array.from(this.period_info.values()).filter(h => h.participatory).reduce((sum, next) => sum + next.hours + next.adj, 0);
}
getTotalAdjustedServiceHoursFromInfo() {
    return Array.from(this.period_info.values()).reduce((sum, next) => sum + next.adj, 0);
}
getPTDTotalServiceHoursFromInfo() {
    return Array.from(this.ptd_period_info.values()).reduce((sum, next) => sum + next.hours + next.adj, 0);
}


addVestingYears(years, date) {
    this.vesting_years += years;
    this.announce('vesting_years');
}

addCreditedYears(years, date) {
    // TODO: may need to track actual date of accumulation, in order to track disjoint benefit periods (caused by unrepaid withdrawals)
    let service_year = date.previousAnniversaryOf(this.basis);
    let current_years = this.credited_years;
    years = current_years + years <= 40 ? years : 40 - current_years;
    if (years > 0) {
        let m = this.multipliers.find((m, i, next) => (m.effective === null || m.effective <= service_year) && (!next[i + 1] || service_year < next[i + 1].effective));
        m.credited_years += years;
        m.credits.push({ years: years, date: date });
    }
    this.announce('credit_years');
}



 let monthnum = this.getBasisMonthFromDate(date, basis);
        let period = `${basis.nb_str}-${monthnum}`;
        let participatory = particip_override || this.isParticipatingAt(date);
        let existing = this.period_info.get(period);

        if (existing) {
            if (hours >= 190 && existing.hours >= 190) {
                if (this.show_logs) console.warn(`%cCALC`, comp_stl, date, existing.date, "probably duplicated period")
            }
            this.period_info.set(period, {
                ...existing,
                dates: [...existing.dates, date],
                adj: existing.hours + hours >= 190 ? 0 : (existing.adj + adj) - (existing.hours + hours),
                hours: existing.hours + hours >= 190 ? 190 : existing.hours + hours,
                participatory: existing.participatory || participatory
            });
            //if (this.show_logs) console.log(`%cCALC`, comp_stl, date, "revised period info", { ...this.period_info.get(period) })
        } else {
            this.period_info.set(period, { date, dates: [date], period, basis: basis, monthnum: monthnum, hours, adj, participatory });
            //if (this.show_logs) console.log(`%cCALC`, comp_stl, date, "set period info", { ...this.period_info.get(period) })
        }
        this.ptd_period_info.set(period, this.period_info.get(period));

        */
    }
    restorePeriodInfo(period, entry) {
        let existing = this.period_info.get(period);
        if (existing) {
            //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "restoring into existing entry", entry, existing);
            this.period_info.set(period, {
                ...existing,
                dates: [...existing.dates, ...entry.dates],
                adj: existing.hours + entry.hours >= 190 ? 0 : (existing.adj + entry.adj) - (existing.hours + entry.hours),
                hours: existing.hours + entry.hours >= 190 ? 190 : existing.hours + entry.hours,
                participatory: existing.participatory || entry.participatory
            });
        } else {
            this.period_info.set(period, entry);
        }
    }

    restoreService(saved, evt) {
        //this.service_hours += saved.service_hours;
        saved.period_info.forEach(([period, entry]) => {
            this.restorePeriodInfo(period, entry);
        });
        this.vesting_years += saved.vesting_years + saved.ytd_vesting_years;
        saved.multipliers.forEach(m => m.credits.forEach(c => {
            this.addCreditedYears(c.years, c.date);
            this.log_service("restore svc", c.date);
        }));
        if (this.date > saved.period_ends) {
            this.addCreditedYears(saved.ptd_credited_years, saved.period_ends);
            this.log_service("restore svc", saved.period_ends);
        }

        this.recordLabel({ date: this.date, series: "service_reset", labels: ["service restored"], event: evt });
        this.announce('service_restored');
    }

    register_service_period(date) {
        let p = this.computeServicePeriod(date);
        this.registered_periods.push(p);
    }

    computeServicePeriod(date) {
        // FIXME: little sus.
        let last_disjoint = this.registered_periods.at(-1);
        let after_last_disjoint = this.in_service_spans.filter(s => !last_disjoint || (s.date > last_disjoint.end));
        let { s, e } = after_last_disjoint.reduce((acc, cur) => {
            return cur.beginning ? 
            { 
                ...acc,
                s: acc.s ? (cur.date < acc.s ? cur.date : acc.s) : cur.date, 
                e: acc.e ? (cur.date > acc.e ? cur.date : acc.e) : cur.date
            } 
            : { ...acc, e: acc.e ? (cur.date > acc.e ? cur.date : acc.e) : cur.date };
        }, { s: null, e: date});
        let { avg, clamped } = this.averageFinalCompensation();
        let ctrb_ests = this.getEstimatedContributions();
        return {
            date,
            ...this.ret_est_svc,
            start: s,
            end: e && e > s ? e : date,
            average_salary: avg,
            clamped_salary: clamped,
            ctrb_ests
        };
    }

    addDisjointServicePeriod(date, withdrawal_id, reason) {
        this.disjoint_periods = [
            ...this.disjoint_periods.filter(p => p.withdrawal_id !== withdrawal_id),
            {
                ...this.computeServicePeriod(date),
                withdrawal_id,
                reason
            }
        ];
        if (this.show_logs) console.warn("%cCALC", comp_stl, "UNIMPL: DISJOINT ADJUSTMENTS", date, ...this.disjoint_periods);
    }

    recordBreakInService() {
        this.breaks_in_service += 1;
        this.announce('service_break');
    }

    // call any receivers of an event
    // if the receivers fn returns true, it
    // remains on the list, cleared otherwise
    announce(event, args) {
        if (this.subscriptions[event]) {
            const subs = this.subscriptions[event];
            this.subscriptions[event] = [];
            subs.forEach(s => { if (s(this, args)) this.subscriptions[event].push(s); });
        }
        /* else {
            if (this.show_logs) console.log(`%cCALC`, comp_stl, "nobody to announce", event);
        }*/
    }

    subscribe(event, fn) {
        if (!this.subscriptions[event]) {
            this.subscriptions[event] = [];
        }
        this.subscriptions[event].push(fn);
        //if (this.show_logs) console.log(`%cCALC`, comp_stl, "subscribed", event, fn);
        return { event: event, fn: fn };
    }

    unsubscribe(handle) {
        if (handle && this.subscriptions[handle.event]) {
            this.subscriptions[handle.event] = this.subscriptions[handle.event].filter(f => f !== handle.fn);
        }
    }

    fiveYearBreak(evt) {
        this.breaks_in_service = 0;
        this.announce('five_year_break');
    }
    resetService(evt, reason) {
        DEBUG_SVC(2, `service reset by ${evt.str} `);
        //DEBUG_CTRB(2, `forfeited ${ this.contribution_balance } `);
        if (this.service_lost &&
            this.vesting_years === 0) return;
        this.service_lost = this.date;
        this._ytd_service_hours = 0;
        this._ytd_service_hour_adj = 0;
        this.vesting_years = 0;
        this.multipliers.forEach(m => { m.credited_years = 0, m.credits = []; });
        this.period_info = new Map();
        this.ptd_period_info = new Map();
        if (!this.employed) {
            this.last_basis = this.basis;
            const { basis, hired, participating, contributing } = this;
            this.old_dates = { basis, hired, participating, contributing };

            this.basis = null;
            this.hired = null;
            this.participating = null;
            this.contributing = null;
        }
        this.addTag(evt.id, Tag.ServiceReset);
        this.removeTag(Tag.Participant);
        this.removeTag(Tag.Contributor);

        let lbl = this.recordLabel({ date: this.date, series: "service_reset", labels: ["service lost", reason].filter(l => l), event: evt });
        this.announce('service_reset');
        return lbl;
        //TODO: may be better to really do this through the announce system:
    }

    get str() {
        let dates = `basis: ${this.basis || "no basis"} \tp: ${this.participating ||
            "NOT PTCPT"} \tc: ${this.contributing || "NOT CNTRB"} \tv: ${this.vested ||
            "NOT VSTD"} \tr: ${this.early_ret_elig_date || "[ER]"}| ${this.full_early_ret_elig_date || "[FER]"}| ${this.full_ret_elig_date ||
            "[RET]"} \tdpt: ${this.departing || "never"} \trtn: ${this.returning ||
            "never"} `;
        return `STATE @${this.date}=${Symbol.keyFor(
            this.status
        )} [${Array.from(this.tags.keys).filter(t => t !== undefined).map(t => Symbol.keyFor(t)).join(", ")}] age: ${this.age?.toFixed(2) || "unknown"} ytdhrs: ${this.ytd_service_hours} hrs: ${this.service_hours} vstyrs: ${this.vesting_years} crdyrs: ${this.credited_years} brks: ${this.breaks_in_service} evts[${this.pending_events.length}]rec[{ this.record.length }] ${dates} [${this.service_bank.map(b => `t:${b.type} a:${b.amount} e:${b.expires.str}`).join(", ")}]`;
    }
    addContributionCancelation(wdrawal, amount, expires) {
        this.contribution_cancelations.push({ amount, expires });
    }


    addActivity(type, activity) {
        if (!this.activities[type]) {
            this.activities[type] = [activity];
        } else {
            this.activities[type] = [...this.activities[type], activity];
        }
        // runpoint cleanup
        //if (this.activities[type].filter(a => a.span_ends >= this.runpoint).length > 1) {
        if (this.activities[type].filter(a => a.span_ends >= this.endpoint).length > 1) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, `addActivity: too many active ${type} `, this.activities[type]);
        }
        this.announce('activity', type);
    }
    anyActivity(type) {
        let ret = this.activities[type];
        return ret ? ret : [];
    }

    anyActiveActivity(type) {
        return this.anyActivity(type).filter(a => !a.span_ends || a.span_ends >= this.date);
    }

    lastActivity(type) {
        const activity = this.activities[type];
        return activity ? activity[activity.length - 1] : null;
    }
    activeActivity(type) {
        const activity = this.lastActivity(type);
        return activity && (!activity.span_ends || activity.span_ends >= this.date) ? activity : null;
    }

    get last_employment() { return this.lastActivity('employment'); }
    get employed() { return this.tags.has(Tag.Employed); }
    get full_time() { return this.tags.has(Tag.FullTime); }
    get part_time() { return this.tags.has(Tag.PartTime); }
    get retired() { return this.tags.has(Tag.Retired); }
    get suspended() { return this.tags.has(Tag.Suspended); }

    isOptedIn() {
        return Boolean(!this.opted_out || (this.opted_in && this.opted_out < this.opted_in && this.opted_in <= this.date));
    }
    isSuspendedWithService() {
        let s = this.lastActivity('suspension');
        return s && (s.suspension_type.paid || s.suspension_type.limit_service_break || s.suspension_type.unlimited);
    }

    get permanently_withdrawn() {
        return this.permanent_withdrawals && this.permanent_withdrawals.length > 0 ? this.permanent_withdrawals[0].date : null;
    }

    addPermanentWithdrawal(id, date, withdrawal_id) {
        this.permanent_withdrawals.push({ id, date, withdrawal_id });
    }
    clearPermanentWithdrawal(id, withdrawal_id) {
        this.permanent_withdrawals = [...this.permanent_withdrawals.filter(w => w.id !== id)];
        this.disjoint_periods = [...this.disjoint_periods.filter(p => p.withdrawal_id !== withdrawal_id)];
    }

    get in_service() {
        return (
            this.employed &&
            !this.tags.has(Tag.Ineligible) &&
            (!this.suspended || this.isSuspendedWithService()) &&
            //(!this.suspended || (st => st && st.paid || st.limit_service_break || st.unlimited)(this.lastActivity('suspension').suspension_type)) &&
            //(!this.opted_out || (this.opted_in && this.opted_out < this.opted_in) || !this.contributing || this.date < this.contributing) &&
            (!this.permanently_withdrawn)
            //FIXME: need condition(s) around withdrawn contributions
        );
    }

    isParticipatingAt(date) {
        if (date.equals(this.date)) {
            return this.currentlyParticipating();
        }
        if (date <= this.date && this.participating_spans.length > 0) {
            let next = this.participating_spans.find(s => s.date >= date);
            return (next.date.equals(date) && !next.ending) || (next.date > date && next.ending);
        }
        if (date > this.date) {
            console.error("%cCALC", comp_stl, this.person_id, "trying to get participation from future");
        }
    }

    currentlyParticipating() {
        return this.in_service && this.isOptedIn();
    }

    yearsParticipatingBetween(start, end, dd) {
        let intervening = this.participating_spans.filter(s => s.date >= start && s.date <= end).sort((a, b) => a.date - b.date);
        if (intervening.length === 0) return dd ? start.yearsUntilDuodecimal(end) : start.yearsUntil(end);
        let points = [...intervening.map(i => i.date), end];
        let ins = 0;
        let outs = 0;
        let last = start;
        points.forEach((p, i) => {
            let s = dd ? last.yearsUntilDuodecimal(p) : last.yearsUntil(p);
            last = p;
            if (i % 2 === 0) {
                ins += s;
            } else {
                outs += s;
            }
        });
        return intervening[0].beginning ? outs : ins;
    }

    get expecting_contributions() {
        // if only using service:
        //let last = this.in_service_spans.length > 0 ? this.in_service_spans[this.in_service_spans.length - 1] : null;
        //let last_in_service = last ? (last.ending ? last.date : this.date) : null;
        //return this.currentlyParticipating() || last_in_service && last_in_service.yearsUntil(this.date) <= 1;
        if (!(this.contributing || (this.old_dates?.contributing && this.old_dates.contributing.yearsUntil(this.date) < 1))) {
            //if (this.show_logs) console.warn(this.date, "NOT EXPECTING CONTRIB", this.contributing, this.old_dates)
            return false;
        }

        let last = this.participating_spans.length > 0 ? this.participating_spans[this.participating_spans.length - 1] : null; // latest span, or null
        let last_particip = last ? (last.ending ? last.date : this.date) : null; // end of last particip period, or current date if in one, 

        // grace period before we expect census data to be entered
        const grace_period = 90;
        /*/
        if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date,
            "LAST PARTIC",
            "cur:", this.currentlyParticipating(),
            "last:", last,
            "last_part:", last_particip ? 'true' : 'false',
            "yearsuntilnow:", last_particip.yearsUntil(this.date),
            "less than 1:", last_particip.yearsUntil(this.date) <= 1,
            "?", true && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear(),
            "C:", true && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear(),
            "B && C", last_particip.yearsUntil(this.date) <= 1 && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear(),
            "last part", last_particip && last_particip.yearsUntil(this.date) <= 1 && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear(),
            "full", this.currentlyParticipating() || last_particip && last_particip.yearsUntil(this.date) <= 1 && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear()
        );
        */
        return this.currentlyParticipating() || last_particip && last_particip.yearsUntil(this.date) <= 1 && last_particip.getFullYear() >= new EventDate(this.date, -grace_period, 'days').getFullYear();

        //Original check in "ExpectContrib ??: 
        // let service_transitions = state.in_service_spans.filter(s => (!this.last_contribution || s.date >= this.last_contribution.date) && s.date <= this.expected);
    }


    get on_paid_suspension() {
        let susp = this.lastActivity('suspension');
        return this.suspended && susp.suspension_type.paid;
    }

    get on_adjusted_suspension() {
        let susp = this.lastActivity('suspension');
        return this.suspended && !susp.suspension_type.paid && (susp.suspension_type.unlimited || susp.suspension_type.limit_service_break);
    }

    openActivity(type) {
        const activity = this.lastActivity(type);
        // runpoint cleanup
        //return activity && activity.span_ends >= this.runpoint ? activity : null;
        return activity && activity.span_ends >= this.endpoint ? activity : null;
    }


    /*
    get last_events() {
        let ret = {};
        Object.entries(this.activities)
            .forEach(([type, activities]) => { if (activities.length > 0) ret[type] = activities[activities.length - 1] });
        return ret;
    }
    */
    get active_events() {
        let ret = {};

        Object.entries(this.activities)
            .map(([type, activities]) => [type, activities.filter(a => !a.span_ends || a.span_ends >= this.date)])
            .forEach(([type, actives]) => { if (actives.length > 0) ret[type] = actives[actives.length - 1]; });
        return ret;
    }
    get all_events() {
        let ret = {};
        Object.entries(this.activities).forEach(([type, activities]) => ret[type] = activities);
        return ret;
    }


    /*
    arctives() {
        if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.constructor.name, "GET ACTVIE", this.employments, JSON.stringify(this.employments));
        this.employments.forEach(e =>
            if (this.show_logs) console.log(`%cCALC`, comp_stl, e.span_ends));
        let ret = {};
        ['employment', 'suspension', 'retirement']
            .filter(e => this[e + 's'].length > 0 && this)
            .map(e => [e, this[e + 's'][this[e + 's'].length - 1]])
            .filter(e => e[1].span_ends >= this.now || e[1].span_ends === null).forEach(e => ret[e[0]] = e[1]);
        return ret;
    }
     
    get active_events() {
        let ret = {};
        ['employment', 'suspension', 'retirement']
            .filter(e => this[e + 's'].length > 0 && this)
            .map(e => [e, this[e + 's'][this[e + 's'].length - 1]])
            .filter(e => e[1].span_ends >= this.now || e[1].span_ends === null).forEach(e => ret[e[0]] = e[1]);
        return ret;
    }
     
    get last_events() {
        let ret = {};
        ['employment', 'suspension', 'retirement']
            .filter(e => this[e + 's'].length > 0)
            .forEach(e => ret[e] = this[e + 's'][this[e + 's'].length - 1]);
        return ret;
    }
    */
    get segment_type() {
        return null;
    }



    get status_info() {
        let warnings = this.errors.filter(e => e.first && e.level === 'migration');
        let alerts = this.errors.filter(e => e.first && e.level === 'alert');
        let errors = this.errors.filter(e => e.first && e.level === 'error');
        let ptype = this.person_type_from_tags();
        let [msg, short_msg] = this.messages_from_person_type(ptype);
        return {
            status: Symbol.keyFor(this.status),
            errors: errors.length > 0 ? errors.map(e => ({ 'msg': e.msg, 'count': this.error_count.get(e.msg) })) : null,
            warnings: warnings.map(e => ({ 'msg': e.msg, 'count': this.error_count.get(e.msg) })),
            alerts: alerts.map(e => ({ 'msg': e.msg, 'count': this.error_count.get(e.msg) })),
            person_type: this.person_type_from_tags(),
            messages: msg,
            short_messages: short_msg
        };
    }

    get employer_code() {
        let emp = this.activeActivity('employment');
        return emp ? emp.employer.code : null;
    }
    get computed_group() {
        /*
            "RET00A": ("RETIREES","status"),
            "VIN00A": ("VESTED-CONTRIB IN","status"),
            "VOUT0A": ("VESTED-CONTRIB REMOVED","status"),
            "X0000A": ("NON-VESTED, CONTRIB IN","status"),
            "X0000B": ("Non-Vested, No Contributions","status"),
            "DE000A": ("Deceased with contributions","status"),
            "DE000B": ("Deceased no contributions","status"),
            */
        if (this.tags.has(Tag.Deceased)) {
            if (this.tags.has(Tag.ContribIn)) {
                return "DE000A";
            }
            return "DE000B";
        }
        if (this.tags.has(Tag.Retired)) { return "RET00A"; }
        //if (this.tags.has(Tag.Employed) && !this.tags.has(Tag.Ineligible) && !this.tags.has(Tag.OptedOut) && !(this.tags.has(Tag.ContributionsRemoved))) {
        if (this.tags.has(Tag.Employed) && !this.tags.has(Tag.Ineligible) && !this.tags.has(Tag.OptedOut) && !(this.permanently_withdrawn)) {
            return this.last_employment.employer.code;
        }
        if (this.tags.has(Tag.Vested)) {
            if (this.tags.has(Tag.ContribIn)) {
                return "VIN00A";
            }
            return "VOUT0A";

        }
        if (this.tags.has(Tag.ContribIn)) {
            return "X0000A";
        }
        return "X0000B";
    }

    person_type_from_tags() {
        if (this.tags.has(Tag.Errors)) { return "errors"; }
        if (this.deceased && this.date >= this.deceased) { return "deceased"; }
        if (this.tags.has(Tag.NeverEmployed) || this.tags.has(Tag.Ineligible)) { return "ineligible"; }
        if (this.tags.has(Tag.Retired) && this.tags.has(Tag.Employed)) { return "working_retiree"; }
        if (this.tags.has(Tag.Retired)) { return "retiree"; }
        if (this.tags.has(Tag.Disability)) { return "disability"; }
        if (this.tags.has(Tag.Employed) || this.tags.has(Tag.FullTime) || this.tags.has(Tag.PartTime)) { return "employee"; }
        if (this.tags.has(Tag.Terminated)) { return "ex-employee"; }
        return 'ex-employee';
    }

    messages_from_person_type(person_type) {
        let msg = [];
        let short_msg = [];
        let last_employment = this.lastActivity('employment');
        let last_employer = last_employment ? last_employment.employer.name : '[NO EMPLOYER]';
        let last_code = last_employment ? last_employment.employer.code : '[UNK]';
        switch (person_type) {
            case 'errors':
                break;
            case 'deceased':
                msg = [...msg, 'Deceased', `${this.deceased} `];
                //msg.push(`Deceased ${ this.deceased } `);
                short_msg.push("deceased");
                break;
            case 'ineligible':
                if (this.tags.has(Tag.Employed)) {
                    msg.push(`Employed at ${last_employer} (ineligible)`);
                    short_msg.push(`ineligible @${last_code} `);
                }
                break;
            case 'working_retiree':
                msg.push(`Working Retired at ${last_employer} `);
                short_msg.push(`ret + emp @${last_code} `);
                break;
            case 'retiree':
                const types = {
                    'FULL': ['Full', 'full'],
                    'FULL EARLY': ['Unreduced', 'unred.'],
                    'EARLY': ['Early', 'early'],
                    'INELIGIBLE': ['Override', 'override']
                };
                let ret = this.activeActivity('retirement');
                if (ret?.status && ret.status.length > 0) {
                    msg.push(`${types[ret.status][0]} Retirement from ${last_employer} `);
                    short_msg.push(`retired(${types[ret.status][1]})`);
                }
                break;
            case 'disability':
                msg.push(`Disability Retirement from ${last_employer} `);
                short_msg.push("disability");
                break;
            case 'employee':
                if (this.tags.has(Tag.PartTime)) {
                    msg.push(`Part-time at ${last_employer}`);
                    short_msg.push(`PT @${last_code}`);
                } else {
                    msg.push(`Full-time at ${last_employer}`);
                    short_msg.push(`FT @${last_code}`);
                }
                if (this.tags.has(Tag.Suspended)) {
                    msg.push(`Suspended from ${last_employer}`);
                    short_msg.push(`susp. @${last_code}`);
                }
                break;
            case 'ex-employee':
                msg.push(`Terminated from ${last_employer}`);
                short_msg.push(`term(last = ${last_code})`);
                break;
        }
        return [msg, short_msg];

    }

    get tag_list() {
        return Array.from(this.tags.keys()).map(t => Symbol.keyFor(t));
    }
    get tag_events() {
        return Array.from(this.tags.entries()).map(([k, v]) => [Symbol.keyFor(k), v]);
    }

    pushSnapshot(snap) {
        this.snapshots.push(snap);
        if (snap.phase === 'present' || snap.phase === 'target') {
            let label = `snapshot @${snap.phase} (${snap.date})`;
            if (this.show_logs) {
                console.groupCollapsed("%cCALC", comp_stl, label);
                console.log("%cCALC", comp_stl, snap);
                console.groupEnd("%cCALC", comp_stl, label);
            }
        }
    }
    get future_events() { return [...this.pending_events, ...this.deferred_events]; }
    _step() {
        // advance to nearest event with a concrete date
        this.steps += 1;
        this._interesting_step = false;
        let initial_in_service = this.in_service;
        let initial_participating = this.currentlyParticipating();

        let next = this.pending_events
            .filter(e => e.date && e.date_insufficient < 3) // ignore dates with extra conditions applied
            .reduce((acc, cur) => (!acc || cur.date < acc.date || (cur.date.equals(acc.date) && cur.is_end) ? cur : acc), null);

        if (!next) { return false; } // nowhen to advance

        next.next_count += 1; // count number of times the same node has been picked

        if (!next.date.equals(this.date)) { //advancing time, snapshot state
            this.pushSnapshot(this.snapshot('post'));
            if (!this.present_day && next.date && next.date > this.runpoint) { // moving past 'now' so save now
                this.date = this.runpoint;
                this.pushSnapshot(this.snapshot('present'));
                this.present_day = { snapshot: this.snapshots[this.snapshots.length - 1], report: this.report };
                this.setWakeup(next.date, next);
                //if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date, next.date, "RECORDING PRESENT", this.present_day);
            }
            if (!this.end_cond_reached && this.end_cond(this)) {
                this.endpoint = this.date;
                this.end_cond_reached = true;
            }
            if (!this.target_date && next.date && next.date > this.endpoint) { // moving past 'end' so save snap
                this.date = this.endpoint;
                this.addRetEstimate("target");
                this.pushSnapshot(this.snapshot('target'));
                this.target_date = { snapshot: this.snapshots[this.snapshots.length - 1], report: this.report };
                this.setWakeup(next.date, next);
                //if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date, next.date, "RECORDING PRESENT", this.present_day);
            }
            if (!(this.projection || this.next_year) && next.date > new EventDate(this.runpoint, 1, 'year')) { // record snapshot a year from now
                this.date = new EventDate(this.runpoint, 1, 'year');
                this.pushSnapshot(this.snapshot('next'));
                this.next_year = { snapshot: this.snapshots[this.snapshots.length - 1], report: this.report };
                //if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date, next.date, new EventDate(this.runpoint, 1, 'year'), "RECORDING NEXT YEAR", this.next_year);
            }
            if (this.date && next.date.getFullYear() > this.date.getFullYear()) { // make an EOY snapshot
                //if (this.projection) this.addContribEstimate(this.date);
                this.ytd_deposited_interest = 0;
                this.date = new EventDate(new Date(this.date.getFullYear() + 1, 0, 0)); // Dec 31
                this.years.push({ state: this.snapshot('annual'), year: this.date.getFullYear() });
                //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "annual", this.date, this.years[this.years.length - 1]);
            }
        }
        this.date = next.date;
        if (next.next_count > 1000) {
            console.error("%cCALC", comp_stl, this.person_id, "loop detected", next);
            this.recordError({ evt: next, code: "calculation loop", msgs: ["calculation loop", `${next.str} `] });
            return false;
        }
        if (this.dob && next.date && next.date < this.dob) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, "time traveler detected", this.person_id, this.name);
            if (this.show_logs) console.warn("%cCALC", comp_stl, `${next.str}: ${next.date} < ${this.dob}`);
            this.time_travel = true;
            if (next.is_primary) {
                this.recordError({ evt: next, code: "event precedes DOB", msgs: ["date precedes DOB", `${next.str} `] });
            } else {
                this.recordError({ evt: next, code: "event precedes DOB", msgs: ["calculation date precedes DOB", `${next.str} `] });
            }
            /*
            else if (!this.errors.find(e => e.code === "event precedes DOB")) {
                this.recordError({ level: 'alert', evt: next, code: "event precedes DOB", msgs: ["check DOB", `${next.str} `] });
            }*/
            //next.date = this.dob ? this.dob : this.runpoint;
            this.date = next.date;
        }

        this.pushSnapshot(this.snapshot('pre'));

        //new ListEnd().insert(this);
        //let last_hash =  this.pending_events.map(e => e.str).join(", ");
        //let count = 0;
        let { applicable, deferred } = this.pending_events.reduce(({ applicable, deferred }, evt) => {
            if (!evt.date || evt.date <= this.date) {
                applicable.push(evt);
            } else {
                deferred.push(evt);
            }
            return { applicable, deferred };
        }, ({ applicable: [], deferred: [] }));
        this.pending_events = applicable;
        this.deferred_events = deferred;
        //if (this.show_logs) console.log(`%cCALC`, comp_stl, `${ this.date }: app: ${ applicable.filter(e => e.constructor.name === 'Contribution').length } /${applicable.length}, def: ${deferred.filter(e => e.constructor.name === 'Contribution').length}/${ deferred.length } `);
        //onsole.log(applicable.filter(e => e.constructor.name === 'Contribution').map(e => e.date));
        /*
        let count = this.pending_events.length;
        let pending = this.pending_events;powerflow
        let deferred = pending.filter(e => !e.date || e.date > this.date);
        this.pending_events = pending.filter(e => e.date && e.date <= this.date);
        if (deferred.length + this.pending_events.length != count) {
            console.error(`%cCALC`, comp_stl, "weird, no match", pending, deferred, this.pending_events);
        }
        */
        let evt_applied;
        let applying;
        let stime;
        let etime;
        let todo;
        applying = true;

        while (applying) {
            todo = this.pending_events;
            this.pending_events = [];
            applying = false;

            for (const evt of todo) {
                //evt = this.pending_events.shift();
                evt_applied = false;
                //stime = performance.now();
                try {
                    evt_applied = evt.apply(this);
                } catch (e) {
                    console.error("%cCALC", comp_stl, this.person_id, e);
                    this.recordError({ evt: evt, code: "programming error", msgs: ['programming error', evt.str, `${e} `] });
                }
                //if (this.show_logs) console.log(`%cCALC`, comp_stl, applied ? "<<APPLIED>>" : "||IGN||", "\t\t", evt.str, "\t\t queue: ", this.pending_events.map(e => e.str).join(", "));
                if (evt_applied) this.applied_events += 1;
                //etime = performance.now();
                // this.event_performance.push({ time: etime - stime, event: evt.id, event_name: evt.constructor.name, applied: applied });
                /*
                if (applied) {
                    this.applied_events.push({ date: this.date, name: evt.constructor.name, id: evt.id, evt: evt });
                }*/
                applying = applying || evt_applied;
            }
        }
        let final_in_service = this.in_service;
        let final_participating = this.currentlyParticipating();
        if (final_in_service !== initial_in_service) {
            //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `${this.date}: in_service: ${initial_in_service} => ${final_in_service} `)
            this.announce('in_service', { was: initial_in_service, now: final_in_service });
            let previous = this.in_service_spans?.at?.(-1) ;
            this.in_service_spans.push({ date: this.date, beginning: final_in_service, ending: !final_in_service });
            if (
                !!final_in_service // starting service
                && previous 
                && previous.ending 
                && previous.date.monthsUntil(this.date) >= 1 // at least 1 month gap                
            ) this.register_service_period(this.date);
        }
        if (final_participating !== initial_participating) {
            //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `${this.date}: participating: ${initial_participating} => ${final_participating} `)
            this.announce('participating', { was: initial_participating, now: final_participating });
            this.participating_spans.push({ date: this.date, beginning: final_participating, ending: !final_participating });

            // update the period info structure to indicate there was participation in this period
            //(either at begin or end of this day)
            this.recordPeriodInfo('step participation', this.date, 0, 0, true);
        }
        this.pending_events = this.pending_events.concat(this.deferred_events);
        return true;
    }

    run() {
        while (this.pending_events.length > 0 && this._step() && !this.target_date) { } // {if (this.show_logs) console.log(`%cCALC`, comp_stl, "stepping...", this.pending_events.length)}
    }

    step() {
        return { running: this.pending_events.length > 0 && this._step(), interesting: this._interesting_step };
    }

    addContext(ctx) {
        this.contexts.push(ctx);
    }


    // Labels are point events with a multi-line label and an optional editable data item
    recordLabel({ anachronism, date, series, brief_text, labels, event, data, show = true, uuid, tag }) {
        if (!anachronism && (!((date && this.date) && this.date.equals(date)))) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, "Anachronistic label event", date, event ? event.str : labels);
        }
        this._interesting_step = true; // this step is producing important visible output

        // make and store the label object
        const node = new TimelineLabel(date, series, brief_text ? brief_text : labels[0], labels, event, data, show, uuid ? uuid : data ? data.id : undefined, tag);
        this.nodes = [...this.nodes, node];
        return node;
    }
    // Spans are durational events with an optional edit component
    recordSpan({ anachronism, start, end, series, brief_text, labels, tags, event, data, uuid }) {
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, "RECORDING SPAN", this.date, start, end, event ? event.str : labels);
        if (!anachronism && (!((([start, end].some(x => x)) && this.date) && ([start, end].some(x => x && this.date.equals(x)))))) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, "Anachronistic span event", this.date, start, end, event ? event.str : labels);
        }
        this._interesting_step = true;
        const node = new TimelineSpan(start, end, series, brief_text ? brief_text : labels[0], labels, tags, event, data, uuid ? uuid : data ? data.id : undefined, data?.projected);
        this.nodes = [...this.nodes, node];
        return node;
    }

    reviseEmploymentSpanEnd(employment_id, new_end) {
        let span = this.nodes.find(n => n.is_span && n.span_class === 'employment' && n?.edit_data?.id === employment_id);
        if (span) {
            span.end = new_end;
        } else {
            throw new Error(`%cCALC`, comp_stl, `No employment span found for ${employment_id}`);
        }
    }
    // Series are sequences of minor events which are displayed and edited in bulk
    recordSeries({ anachronism, date, series, brief_text, labels, event, data, significant = true }) {
        if (!anachronism && (!((date && this.date) && this.date.equals(date)))) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, "Anachronistic series event", date, event ? event.str : labels);
        }
        const last_series_data = this.series_nodes.filter(s => s.series_class === series);
        const last_edit_series_data = last_series_data.length > 0 ? last_series_data[last_series_data.length - 1].edit_series_data : [];
        const all_data = [...last_edit_series_data, data];
        const node = new TimelineSeries(date, series, brief_text ? brief_text : labels[0], labels, event, data, all_data);
        this.series_nodes = [...this.series_nodes, node];
        if (significant) {
            //filter out any prior instances of this series, and append new instance to node list
            this.nodes = [...this.nodes.filter(n => !(n.is_series) || n.series_class !== series), node];
        } else {
            //update the last significant node with the new data
            let priors = this.nodes.filter(n => n.is_series && n.series_class === series);
            if (priors.length > 0) {
                priors[priors.length - 1].edit_series_data = all_data;
            } else {
                this.nodes = [...this.nodes.filter(n => !(n.is_series) || n.series_class !== series), node];
            }
        }
    }


    recordError({ anachronism, level = "error", code, brief_text, date = this.date, evt, msgs, tag, force = false }) {
        //evt, msg, type = "error", extra = null, force = false) {
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, `ERROR: ${ msg } \n${ this.person_id } (${ this.name } / dob: ${this.dob})\n${evt.str}`);
        if (!anachronism && (!((date && this.date) && this.date.equals(date)))) {
            if (this.show_logs) console.warn("%cCALC", comp_stl, "Anachronistic error event", date, evt ? evt.str : msgs);
        }
        let msg = brief_text ? brief_text : msgs[0];
        code = code ? code : msg;
        let count = 1;
        if (this.error_count.has(msg)) {
            count = this.error_count.get(msg) + 1;
        } else {
            this._interesting_step = true;
        }
        let tag_evt = { ...(evt ? evt.snapshot() : {}), id: evt ? `${evt.id}::${level}` : msgs.join('::') };

        this.error_count.set(msg, count);
        this.errors = [...this.errors, { level: level, code: code, msg: msg, first: count === 1, count: count, evt: tag_evt, state: this.snapshot() }];
        this.recordLabel({ anachronism: anachronism, tag: tag !== undefined ? tag : level, date: date, series: level, labels: msgs, brief_text: msg, event: tag_evt, show: count === 1 || force, uuid: tag_evt.uuid ? tag_evt.uuid : undefined });

        if (level === 'error') this.addTag(tag_evt.id, Tag.Errors);
        if (level === 'migration') this.addTag(tag_evt.id, Tag.MigrationIncomplete);
        if (level === 'alert') this.addTag(tag_evt.id, Tag.Alerts);
    }


    addTag(evt_id, tag) {
        /*
    if (!tag) {
    console.error(`%cCALC`, comp_stl, "NO SUCH TAG", evt);
    }*/
        //if(!this.tags.has(tag)) {
        if (!tag && this.show_logs) console.warn("%cCALC", comp_stl, this.date, evt_id, "adding tag", tag); //, Array.from(this.tags.keys()).map(k => Symbol.keyFor(k)));
        if (!this.tags.has(tag)) {
            this._interesting_step = true;
            this.tags.set(tag, new Set([evt_id]));
        } else {
            this.tags.get(tag).add(evt_id);
        }
        //if (this.show_logs) console.log(`%cCALC`, comp_stl, this.date, "added tag", tag, Array.from(this.tags.keys()).map(k => Symbol.keyFor(k)));
        //}
        //if (this._interesting_step) this.announce('add_tag');
    }
    removeTag(tag) {
        //if (this.show_logs) console.warn(`%cCALC`, comp_stl, this.date, "removing tag", tag, Array.from(this.tags.keys()).map(k => Symbol.keyFor(k)));
        if (this.tags.has(tag)) this._interesting_step = true;
        this.tags.delete(tag);
        //if (this.show_logs) console.log(`%cCALC`, comp_stl, this.date, "removed tag", tag, Array.from(this.tags.keys()).map(k => Symbol.keyFor(k)));
        //if (this._interesting_step) this.announce('remove_tag');
    }
}
