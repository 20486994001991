import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { State } from "./State.js";
import { Tag } from "../pension-timeline-logic.js";

export class EmploymentEnd extends Event {
    get is_primary() { return true; }
    get is_end() { return true; }
    get is_primary() { return true; }
    constructor(employment, end_date) {
        super();
        this.employment = employment;
        this.date = new EventDate(end_date ? end_date : employment.end_date);
    }
    get id() { return `EMPLEND:${this.employment.id}:${this.date - 0}: ${this.employment.employer.code} ${this.synthesized_by ? `::SYNTHBY:${this.synthesized_by.id}` : ''}`; }
    get str() { return `EMPLOYMENT END ${this.date}`; }

    /*
    set date(d) { if (state.show_logs) console.warn(`%cCALC`, comp_stl, `EMP END DATE ${this._date} -> ${d}`); this._date = d; }
    get date() { return this._date; }
    */
    insert(state) {
        super.insert(state);
        state.termination_dates.push(this.date);
    }
    apply(state) {
        if (state.date.equals(this.date)) {
            //if (state.show_logs) console.log(`%cCALC`, comp_stl, "trying to end an empl", this.id, state.employment ? state.employment.id : null, state.status, " stack:", state.pending_events.map(e=>e.id).join(", "));
            if (!state.employment) {
                state.recordError({ evt: this, msgs: ["no employment to end"] });
                return true;
            }
            if (state.employment.id === this.employment.id) {
                if (state.status === State.Suspended) {
                    // there's already a suspension in progress
                    let susp = state.lastActivity('suspension'); //last_events['suspension'];
                    if (!susp) state.recordError({ evt: this, msgs: ["employment ending while suspended", "but no suspension active!"] });
                    if (susp) {
                        let end = susp.end_date;
                        if (!end) state.recordError({ evt: this, msgs: ["employment ending while suspended", "no suspension end date"] });
                        if (end) {
                            state.recordError({ evt: this, msgs: ["employment ending while suspended"] });
                            //end.date = state.date;
                            //state.pending_events.push(this);
                            // return false;
                        }

                    }
                }
                state.announce('employment_end', this);
                state.status = State.Unemployed;
                state.employment = null;
                //state.next_employed
                //state.ptd_service_hours
                /*
                if (state.in_service) {
                    state.addDisjointServicePeriod(this.date, this, "employment_end"); // divide service prior to withdrawal for benefit calculations
                }*/
                state.removeTag(Tag.Employed);
                state.removeTag(Tag.FullTime);
                state.removeTag(Tag.PartTime);
                state.removeTag(Tag.Ineligible);
                if (!state.tags.has(Tag.Retired)) {
                    state.addTag(this.id, Tag.Terminated);
                }
            } else {
                state.recordError({ evt: this, code: "employment end mismatch", msgs: ["employment ending, but employed somewhere else!", `expected ${this.employment.employer.code} starting ${new EventDate(this.employment.start_date)}, but got ${state.employment.employer.code} starting ${new EventDate(state.employment.start_date)}`] });
            }
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
