import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { DEBUG_SVC, SuspensionServiceAdjustment, comp_stl, Tag } from "../pension-timeline-logic.js";

export class ComputationPeriod extends Event {
    get id() { return `PERIOD:${this.basis - 0}:${this.number}`; }
    get str() { return `PERIOD[${this.number}] @${this.date} [${this.basis} + ${this.number}]`; }

    resetDate() {
        this.date = new EventDate(this.basis, this.number, 'years');
        this.duplication_key = `${this.basis}::${this.number}`;
    }
    constructor(basis, number, evt) {
        super();
        this.basis = basis;
        this.number = number;
        this.date = new EventDate(basis, number, 'years');
        this.duplication_key = `${basis}::${number}`;
    }
    doEOP(state) {
        DEBUG_SVC(1, `${this.str}@${state.date}: doing EOP bookkeping`);

        //if (state.ptd_service_hours < 501 && !this.adjustments_applied) {
        if (state.ptd_service_hours < 501 && !this.adjustments_applied) {
            //check for adjustments
            let adjustment = 0;
            state.service_bank
                .filter(b => b.amount > 0 && b.expires >= state.date && b.type === "adjusted")
                .forEach(b => {
                    let wanted = 501 - state.ptd_service_hours - adjustment;
                    wanted = wanted < 0 ? 0 : wanted;
                    let withdrawal = wanted <= b.amount ? wanted : b.amount;
                    adjustment += withdrawal;
                    b.amount -= withdrawal;
                });
            if (adjustment > 0) {
                DEBUG_SVC(2, "pushing a service adjustment to apply", adjustment, "banked hours");
                this.adjustments_applied = true;
                new SuspensionServiceAdjustment(this.date, "adjusted", adjustment).insert(state);
                state.pending_events.push(this); // come back after the adjustment has been recorded
                return true;
            }
        }
        let vesting_years = state.ptd_vesting_years;
        if (vesting_years < 1 && state.free_years) {
            let free_adj = 1 - vesting_years;
            free_adj = state.free_years > free_adj ? free_adj : state.free_years;
            state.free_years -= free_adj;
            vesting_years += free_adj;
            if (state.show_logs) console.warn("%cCALC", comp_stl, "ADDING FREE YEARS", state.date, free_adj, state.free_years);
        }

        // state.free_years = (state.basis < EventDate.mandatory_contrib_change ? 3 : 1) - (state.vesting_years + state.ptd_vesting_years);
        state.addVestingYears(vesting_years, this.date);
        let rollover_years = state.multiplier_rollovers.reduce((acc, e) => acc + e.years, 0);
        state.multiplier_rollovers = [];
        let credit_years = state.ptd_credited_years - rollover_years;
        if (rollover_years < 0) { console.error("%cCALC", comp_stl, "NEGATIVE YEARS AFTER ROLLOVER APPLIED"); }
        state.addCreditedYears(credit_years, this.date);

        DEBUG_SVC(1, `\t\tvyrs: ${vesting_years}, cyrs: ${credit_years} (state: ${state.vesting_years} ${state.credited_service_years})`);
        if (state.ptd_service_hours < 501) {
            state.recordBreakInService();
            if (!state.vested && state.tags.has(Tag.PartTime)) {
                const get_ordinal = n => {
                    if (n === 1) return 'st';
                    if (n === 2) return 'nd';
                    if (n === 3) return 'rd';
                    return 'th';
                };

                const lbls = ["part-time break in service", `${state.breaks_in_service}${get_ordinal(state.breaks_in_service)} consecutive`];
                DEBUG_SVC(1, `${this.str}@${state.date}: PT break #${state.breaks_in_service}`);
                state.recordLabel({ date: this.date, series: "service_reset", labels: lbls, event: this });
            }

            if (state.breaks_in_service >= 5) {
                state.fiveYearBreak(this);
                if (!state.vested && state.basis !== null && (state.service_hours + state.ptd_total_vesting_years + state.credited_years > 0)) {
                    DEBUG_SVC(1, `${this.str}@${state.date}: five year break -- resetting all service`);
                    this.reset_lbl = state.resetService(this, '(5 consecutive breaks)');
                }
            }
        } else {
            // reset consecutive counter
            state.breaks_in_service = 0;
        }
        state.clearYTDHours(state.date, this);
        state.log_service('EOP');
    }
    apply(state) {
        if (state.date >= this.date) {
            if (state.basis !== this.basis || state.date >= state.endpoint) { //FIXME: was state.now
                DEBUG_SVC(2, `${this.str}@${state.date}: farewell cruel world`);
                return false; // forget about it
            }
            this.doEOP(state);
            this.number += 1;
            this.resetDate();
            state.deferred_events.push(this);
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
}
