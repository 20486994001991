import { Event } from "./timeline-logic/Event.js";
import { PensionMultiplier } from "./timeline-logic/PensionMultiplier.js";
import { EventDate } from "./eventdate.js";
import { State } from "./timeline-logic/State.js";
import { ServiceMonth } from "./timeline-logic/ServiceMonth.js";
import { MigrationWithdrawalAmountExpectation } from "./timeline-logic/MigrationWithdrawalAmountExpectation.js";
import { MigrationServiceHourBalanceExpectation } from "./timeline-logic/MigrationServiceHourBalanceExpectation.js";
import { ContributionDeficiency } from "./timeline-logic/ContributionDeficiency.js";
import { RecordEOYInterest } from "./timeline-logic/RecordEOYInterest.js";
import { MigrationTagExpectation } from "./timeline-logic/MigrationTagExpectation.js";
import { BenefitPaid } from "./timeline-logic/BenefitPaid.js";
import { AgeEvent } from "./timeline-logic/AgeEvent.js";
import { AgeVesting } from "./timeline-logic/AgeVesting.js";
import { ServiceAdjustment } from "./timeline-logic/ServiceAdjustment.js";
import { EmploymentEnd } from "./timeline-logic/EmploymentEnd.js";
import { Contributing } from "./timeline-logic/Contributing.js";
import { Withdrawal } from "./timeline-logic/Withdrawal.js";
import { WithdrawalRepayment } from "./timeline-logic/WithdrawalRepayment.js";
import { ComputationPeriod } from "./timeline-logic/ComputationPeriod.js";
import { sanitizePII } from "benefits-test-utils/retrieveAndWriteCalcData.js";

export const comp_stl =
  "background: #4F004E; color: #E2DCFF; font-size: smaller; padding: 3px; border-radius: 3px;";
const comp_stl_unimpl =
  "background: #4F0000; color: #E2DCFF; font-size: smaller; padding: 3px; border-radius: 3px;";

// FIXME: global? refactor?
const memoize = (fn) => {
  const cache = new Map();
  return (age) => {
    if (!cache.has(age)) cache.set(age, fn(age));
    return cache.get(age);
  };
};
/*
Your annual pension benefit payable beginning at your Normal Retirement Date in the
form of a single Life Annuity will be the sum of:
1. 2.5% of your Average Final Compensation, multiplied by your Years of Credited
Service for your Computation Periods beginning before January 1, 2001, plus
2. 2.75% of your Average Final Compensation, multiplied by your Years of Credited
Service for your Computation Periods beginning on and after January 1, 2001,
and before January 1, 2014, plus
3. 2.4% of your Average Final Compensation, multiplied by your Years of Credited
Service for your Computation Periods beginning on and after January 1, 2014.


4. 2.2% > 2020 
5. 2.1% > 2021

EventDate.formula_period_A = EventDate.january_1_2001;
EventDate.formula_period_B = EventDate.january_1_2014;

*/
const log_level = 0;
const log_streams = new Set();
//const log_streams = new Set(['SVC', 'HOURS'])

export const round2 = (n) => Math.round(n * 100) / 100.0;

const debug_log = (stream, level, msg) => {
  if (level < log_level || !log_streams.has(stream)) {
    return;
  }
  stream = `[[${stream.slice(0, 10)}]] `.padEnd(14, " ");
  switch (level) {
    case 0:
      console.log("%cCALC", comp_stl, ` ${stream}`, msg);
      break;
    case 1:
      console.log("%cCALC", comp_stl, ` ${stream}`, msg);
      break;
    case 2:
      if (State.show_logs) console.warn("%cCALC", comp_stl, stream, msg);
      break;
    case 3:
      console.error("%cCALC", comp_stl, stream, msg);
      break;
  }
};

export const DEBUG_SVC = (lvl, msg) => debug_log("SVC", lvl, msg);
export const DEBUG_HOURS = (lvl, msg) => debug_log("HOURS", lvl, msg);
export const DEBUG_CTRB = (lvl, msg) => debug_log("CTRB", lvl, msg);

export const DEBUG_HOUR_ACCRUALS = false;

export const TENTHS = (amt) =>
  amt !== null && amt !== undefined && typeof amt === "number"
    ? amt.toLocaleString([], { maximumFractionDigits: 1 })
    : "[ERR]";

export const MONEY = (amt) =>
  amt !== null && amt !== undefined && typeof amt === "number"
    ? amt.toLocaleString([], { style: "currency", currency: "USD" })
    : "$UNKNOWN";
export const KMONEY = (amt) =>
  amt !== null && amt !== undefined && typeof amt === "number"
    ? `${Math.round(amt / 1000).toLocaleString([], {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })}K`
    : "$UNKNOWN";

export const COMPUTE_CONTRIBUTION_INTEREST = (amt, years) => {
  const p = amt; //'principal'
  const r = 0.05; // rate
  const t = years; // years of compounding
  const n = 1; // one compounding per year
  return p * Math.pow(1 + r / n, n * t) - p;
};
const COMPUTE_CONTRIBUTION_INTEREST_CONTINUOUS = (amt, years) => {
  const p = amt; //'principal'
  const r = 0.05; // rate
  const t = years; // years of compounding
  return p * Math.pow(Math.E, r * t) - p;
};

const snapshot_data = (d) => {
  return {
    ...d,
    end_event: d.end_event ? d.end_event.snapshot() : d.end_event,
  };
};
export class TimelineLabel {
  constructor(
    date,
    label_class,
    brief_text,
    labels,
    event,
    edit_data,
    show_label,
    uuid,
    tag
  ) {
    this.date = date;
    this.label_class = label_class;
    this.brief_text = brief_text;
    this.labels = labels;
    this.uuid = uuid
      ? uuid
      : edit_data
      ? edit_data.id
      : event?.uuid
      ? event.uuid
      : null; //label.uuid ? label.uuid : undefined;
    this.event = event ? (event.snapshot ? event.snapshot() : event) : null;
    this.edit_data = edit_data;
    this.show_label = show_label;
    this.is_label = true;
    this.tag = tag;
  }
  snapshot() {
    return { ...this };
    /*
		return (({
				date, label_class, brief_text, labels, extra, uuid, event, edit_data, show_label, is_label, cancelled, tag
		}) => ({
				date, label_class, brief_text, labels, extra, uuid, event, edit_data, show_label, is_label, cancelled, tag
		}))(this)
		*/
  }
  // if the event is superseded later, add flag and msg to render as such
  cancel(msg) {
    this.cancelled = msg;
  }
}

export class TimelineSpan {
  //const node = new TimelineSpan(start, end, series,     brief_text ? brief_text : labels[0], labels, tags, event, data, uuid ? uuid : data ? data.id : undefined, data?.projection);
  constructor(
    start,
    end,
    span_class,
    brief_text,
    labels,
    tags,
    event,
    edit_data,
    uuid,
    scenario
  ) {
    this.start = start;
    this.end = end;
    this.span_class = span_class;
    this.brief_text = brief_text;
    this.labels = labels;
    this.tags = tags ? tags : [];
    this.event = event
      ? { ...(event.snapshot ? event.snapshot() : event) }
      : null;
    this.edit_data = edit_data;
    this.is_span = true;
    this.scenario = scenario;
  }
  snapshot() {
    return { ...this, edit_data: snapshot_data(this.edit_data) };
    /*
		return (({
				start, end, span_class, brief_text, labels, tags, event, edit_data, is_span
		}) => ({
				start, end, span_class, brief_text, labels, tags, event, edit_data: snapshot_data(edit_data), is_span
		}))(this)
		*/
  }
}
export class TimelineSeries {
  constructor(
    date,
    series_class,
    brief_text,
    labels,
    event,
    edit_data,
    edit_series_data,
    uuid
  ) {
    this.date = date;
    this.series_class = series_class;
    this.label_class = series_class; // compatibility with label rendering
    this.brief_text = brief_text;
    this.labels = labels;
    /*
		this.label = label.label ? label.label : label;
		this.extra = label.extra ? label.extra : undefined;
		*/
    this.uuid = uuid
      ? uuid
      : edit_data
      ? edit_data.id
      : event?.uuid
      ? event.uuid
      : null; //label.uuid ? label.uuid : undefined;
    this.event = event ? (event.snapshot ? event.snapshot() : event) : null;
    this.edit_data = edit_data;
    this.edit_series_data = edit_series_data;
    this.show_label = true;
    this.is_series = true;
  }
  snapshot() {
    return { ...this };
    /*
		return (({
				date, series_class, label_class, brief_text, labels, extra, uuid, event, edit_data, edit_series_data, show_label, is_series
		}) => ({
				date, series_class, label_class, brief_text, labels, extra, uuid, event, edit_data, edit_series_data, show_label, is_series
		}))(this)
		*/
  }
}

State.Unemployed = Symbol.for("UNEMPLOYED");
State.NeverEmployed = Symbol.for("NEVER EMPLOYED");
State.Employed = Symbol.for("FULL TIME");
State.EmployedNotEligible = Symbol.for("NOT ELIGIBLE");
State.EmployedPartTime = Symbol.for("PART TIME");
State.Suspended = Symbol.for("SUSPENDED");
State.FullRetired = Symbol.for("FULL RETIREMENT");
State.FullEarlyRetired = Symbol.for("FULL EARLY RETIRED");
State.EarlyRetired = Symbol.for("EARLY RETIRED");
State.WorkingRetired = Symbol.for("WORKING RETIRED");
State.Deceased = Symbol.for("DECEASED");
State.Disability = Symbol.for("DISABILITY");

export class Tag {}

Tag.Participant = Symbol.for("PTCPT");
Tag.Contributor = Symbol.for("CONTRIB");
Tag.OptedOut = Symbol.for("OPTOUT");
Tag.OptedIn = Symbol.for("OPTIN");

Tag.ContribIn = Symbol.for("HASCONTRIB");
Tag.NoContributions = Symbol.for("NOCONTRIB");
Tag.ContributionsRemoved = Symbol.for("CONTRIB_WDRAW");
Tag.ContributionsDeficient = Symbol.for("CONTRIB_DFCT");

Tag.BenefitsPaid = Symbol.for("BENEFITS_PAID");

Tag.Vested = Symbol.for("VEST");
Tag.NotVested = Symbol.for("NOTVEST");

// *current* employment status
Tag.FullTime = Symbol.for("FT");
Tag.PartTime = Symbol.for("PT");
Tag.Employed = Symbol.for("EMP");
Tag.Terminated = Symbol.for("TERM");
Tag.NeverEmployed = Symbol.for("NVEMPL");
Tag.Retired = Symbol.for("RET");
Tag.Disability = Symbol.for("DIS");
// TODO: Death Benefit
// Tag.DeathBenefit
// TODO: early, early full ?

Tag.RetEligible = Symbol.for("RET_ELIG");
Tag.RequiredBeginning = Symbol.for("REQD_BEGIN");
// sticky
//Tag.Eligible = Symbol.for("ELIG");
Tag.Ineligible = Symbol.for("NOTELIG");
Tag.ReEmployed = Symbol.for("REEMP");

Tag.Suspended = Symbol.for("SUSP");

Tag.Migrated = Symbol.for("MGRT");
Tag.Errors = Symbol.for("ERR");
Tag.MigrationIncomplete = Symbol.for("MIGR_INCOMPL");
Tag.Alerts = Symbol.for("ALERT");
Tag.ServiceReset = Symbol.for("RESET");
Tag.Deceased = Symbol.for("DEC");

Tag.Beneficiary = Symbol.for("BENEFIC");

class MultiplierRollover extends Event {
  get id() {
    return `MULT:${this.effective}:${this.multiplier}`;
  }
  get str() {
    return `MULT @${this.date} [${this.multiplier}]`;
  }
  constructor(mult) {
    super();
    this.date = new EventDate(mult.effective, -1, "days");
    this.effective = this.date;
    this.multiplier = mult.multiplier;
  }
  apply(state) {
    if (state.date >= this.date) {
      // if (state.show_logs) console.warn(`%cCALC`, comp_stl, "APPLYING MULT ROLLOVER", state.date, state.ptd_credited_years);
      state.addCreditedYears(state.ptd_credited_years, this.date);
      state.log_service("multiplier rollover", this.date);
      state.multiplier_rollovers = [
        ...state.multiplier_rollovers,
        {
          years: state.ptd_credited_years,
          date: this.effective,
        },
      ];
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
}

export class SuspensionServiceAdjustment extends Event {
  get id() {
    return `SUSPADJ:${this.date - 0}:${this.type}:${this.hours}`;
  }
  get str() {
    return `SUSPENSION SERVICE ADJUSTMENT @${this.date} type: ${this.type} hours: ${this.hours}`;
  }

  constructor(date, type, hours) {
    super();
    this.date = new EventDate(date);
    this.type = type;
    this.hours = hours;
  }

  apply(state) {
    // TODO: any cases where we do not apply?
    //
    if (state.date.equals(this.date)) {
      // if (state.show_logs) console.log(`%cCALC`, comp_stl,  "applying service adjustment", this.hours, "before=", state.ptd_service_hours, state.service_hours)
      ////state.service_hours += this.hours;
      //state.ptd_service_hours += this.hours;
      state.addServiceHours(this.hours, state.date, this);
      //if (state.show_logs) console.log(`%cCALC`, comp_stl,  "applied service adjustment, now=", state.ptd_service_hours, state.service_hours)

      state.recordLabel({
        date: this.date,
        series: "automatic_adjustment",
        labels: ["suspension service", `+ ${this.hours} svc hrs`],
        event: this,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

export class ComputeSimulationTotal extends Event {
  get id() {
    return `SIM TOTAL:${this.date - 0}`;
  }
  get str() {
    return `SIM TOTAL @${this.date} ${this.amount ? MONEY(this.amount) : ""}`;
  }
  constructor(date) {
    super();
    this.date = new EventDate(date);
    this.amount = null;
  }

  //FIXME: check to see if we need this
  //needs_estimated_contrib(state) {
  //    let date = state.date;
  //    let cutoff = new EventDate(new Date(date.getFullYear(), 0, 1));
  //    if (state.last_contribution.date.yearsUntil(state.date) < 1) {
  //        if (state.show_logs) console.warn("%cCALC", comp_stl, "#### CHK CTRB EST ####", state.date, state.last_contribution.date);
  //        return false;
  //    }
  //    let all = state.anyActivity('employment')
  //        .filter(e => state.contributing
  //            && e.span_begins <= date
  //            && (!e.span_ends || e.span_ends > cutoff));
  //    // .filter(e => !(this.recorded.has(contrib_year) && this.recorded.get(contrib_year).has(e.employer_code)));
  //    return (all.length > 0);
  //}

  apply(state) {
    if (state.date.equals(this.date)) {
      let years = 0;
      let nominal_interest = 0;
      let amt = 0;
      let cancelation = 0;
      if (state.last_contribution) {
        //years = state.last_contribution.date.yearsUntil(this.date);
        years =
          state.contribs.length > 0
            ? state.contribs[state.contribs.length - 1].date.yearsUntil(
                this.date
              )
            : 0;
        nominal_interest =
          state.contribution_balance * (0.05 * (Math.round(years * 12) / 12));
        nominal_interest = round2(nominal_interest);
      }
      let incremental_interest =
        nominal_interest - state.ytd_deposited_interest;
      this.amount = incremental_interest;
      if (
        Math.abs(this.amount - round2(state.ytd_contribution_interest)) > 0.25
      ) {
        if (state.show_logs)
          console.warn(
            "%cCALC",
            comp_stl,
            state.date,
            "EOYINT: int and ytd not the same",
            nominal_interest,
            state.ytd_contribution_interest
          );
      }
      if (this.amount > 0) {
        let { total_contribs, total_int } = state.contribs
          .filter((c) => c.total_contribs > 0)
          .reduce(
            (tots, ctr) => ({
              total_contribs: tots.total_contribs + ctr.amt,
              total_int: tots.total_int + ctr.interest,
            }),
            {
              total_contribs: amt,
              total_int: incremental_interest,
            }
          );
        //                console.log("CONT+INT",total_contribs, total_int);
        total_contribs = round2(total_contribs);
        total_int = round2(total_int);
        //let total_int = round2(state.contribs.reduce((tot, ctr) => tot + ctr.interest, interest));
        //TODO: need to confirm whether we want to use nominal vs incremental interest in contribution_balance
        state.contribution_balance = round2(
          state.contribution_balance + incremental_interest + amt - cancelation
        ); //total_contribs + total_int;
        state.contribution_interest = round2(
          state.contribution_interest + incremental_interest
        ); //total_int;
        state.ytd_deposited_interest += round2(incremental_interest);

        let ctrb = {
          type: "interest",
          contribution_id: null,
          contribution_date: this.date,
          date: this.date,
          years_since_last: years,
          auto_int: true,
          amt: 0,
          canceled: 0,
          interest: incremental_interest,
          total_contribs: total_contribs,
          total_interest: total_int,
          balance: state.contribution_balance,
        };
        state.push_contrib(ctrb);
        //if (state.show_logs) console.log(`%cCALC`, comp_stl, "AUTO INT", `d: ${this.date.nb_str}, ysl: ${years},  int: ${interest} -- tc: ${total_contribs} ti: ${total_int} bal: ${state.contribution_balance}`);
        console.table(`%cCALC`, comp_stl, state.contribs.slice());
      }
    }
  }
}

class SalaryHistoryPoint extends Event {
  get is_primary() {
    return true;
  }
  get id() {
    return `SAL:${this.salary.id}:${this.date - 0}:${this.salary.salary}`;
  }
  get str() {
    return `SALARY @${this.date} $${this.salary.salary}`;
  }

  constructor(salary) {
    super();
    this.salary = salary;
    this.date = new EventDate(salary.effective_date);
    this.amount = this.salary.salary;
  }

  apply(state) {
    if (state.date.equals(this.date)) {
      state.raw_salaries.push({
        date: this.date,
        year: this.date.getFullYear(),
        bsalary: null,
        psalary: null,
        actual_salary: this.salary.salary,
      });
      state.recordSeries({
        date: this.date,
        series: "salary",
        brief_text: `sal: ${MONEY(this.salary.salary)}`,
        labels: ["salary", `${MONEY(this.salary.salary)}`],
        event: this,
        data: this.salary,
        significant: true,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class Contribution extends Event {
  get is_primary() {
    return true;
  }
  get id() {
    return `CONTRIB:${this.contribution.id}:${this.date - 0}:${
      this.contribution.ee_pension_contribution
    }`;
  }
  get str() {
    return `CONTRIBUTION @${this.date} $${this.amount}`;
  }

  constructor(contribution) {
    super();
    this.contribution = contribution;
    this.date = new EventDate(contribution.contribution_date);
    this.date = new EventDate(new Date(this.date.getFullYear() + 1, 0, 0)); // Dec 31 of same year
    this.amount = this.contribution?.ee_pension_contribution;
    this.valid = this.amount > 0 || this.contribution.made_up;
  }

  computeAmount(state) {
    return {
      computed_amount: Number(this.contribution.ee_pension_contribution),
      debug: null,
    };
  }

  doCancelations(amt, state) {
    let cancelation = 0;
    let cancelations = state.contribution_cancelations;
    state.contribution_cancelations = [];
    while (cancelation < amt && cancelations.length > 0) {
      let next = cancelations.shift();
      if (next.expires >= state.date && next.amount + cancelation <= amt) {
        cancelation += next.amount;
      } else if (
        next.expires >= state.date &&
        next.amount + cancelation > amt
      ) {
        let used = amt - cancelation;
        state.contribution_cancelations.push({
          ...next,
          amount: next.amount - used,
        });
        cancelation += used; //= amt => amt-cancelation=used
      } else if (next.expires >= state.date) {
        state.contribution_cancelations.push(next);
      } else {
      }
    }
    return cancelation;
  }

  scheduleEOY(state) {
    if (
      state.contribution_balance === 0 &&
      this.contribution.ee_pension_contribution > 0
    ) {
      // Not used anymore. Replaced by scheduleEstimatedContributions to simplify estimated contributions
      let next = new EventDate(this.date);
      next.setFullYear(this.date.getFullYear() + 1);
      new RecordEOYInterest(next).insert(state);
    }
  }

  scheduleEstimatedContributions(state) {
    // This method looks forward to check if we need estimated contribution for the next year
    // First creats "next" date of 1 year past the state Ex: 12/31/23 -> 12/31/24
    // Then we check if there is raw_contrib for the given "next" date
    // Finally run a Estimated contribution for given date or break
    let next_expected_contrib = new EventDate(
      new Date(state.date.getFullYear() + 1, 11, 31)
    );
    let last_raw_contrib = state.raw_contribs.find((c) =>
      next_expected_contrib.equals(c.d)
    ); // last actual contribution
    let first_raw_contrib = state.raw_contribs.sort(
      (a, b) => new Date(a.d) - new Date(b.d)
    )[0]; //need this to check for time travelers
    if (
      state.prorate_final_estimate &&
      next_expected_contrib >= state.endpoint &&
      !last_raw_contrib &&
      state.employed
    ) {
      //checks if we need a prorated salary for the final year
      state.prorate_final_estimate = false;
      new EstimatedContribution(
        state.endpoint,
        state,
        "This is prorated"
      ).insert(state);
    } 
    else if (
      last_raw_contrib ||
      next_expected_contrib >= state.endpoint ||
      state.dob > first_raw_contrib?.d ||
      (!state.contributing && state.contribution_balance<=0)
    ) {
      //Breaks out of loops
      return;
    }
    else {
      new EstimatedContribution(
        next_expected_contrib,
        state,
        "This is Scheduled Estimated Contribution"
      ).insert(state);
    }
  }

  makeContribEntry(state, params) {
    let {
      years,
      cancelation,
      amt,
      interest,
      total_contribs,
      total_int,
      balance,
      state_contribution_balance,
      state_contribution_interest,
      debug,
    } = params;
    return {
      type: "contribution",
      contribution_id: this.contribution.id,
      contribution_date: this.contribution?.contribution_date,
      employer_code: this.contribution?.employer_code,
      date: this.date,
      years_since_last: years,
      amt,
      auto_int: false,
      canceled: cancelation,
      interest: interest,
      total_contribs: total_contribs,
      total_interest: total_int,
      balance,
      debug,
    };
  }

  computeParameters(state) {
    let years = 0;
    let interest = 0;
    let amt = 0;
    if (state.last_contribution) {
      //years = state.last_contribution.date.yearsUntil(this.date);
      years =
        state.contribs.length > 0
          ? state.contribs.at(-1).date.yearsUntil(this.date)
          : 0;
      interest =
        state.contribution_balance * (0.05 * (Math.round(years * 12) / 12));
      interest = round2(interest);
    }
    let {
      debug,
      computed_amount,
      is_projected_contribution,
      projected_salary_amt,
    } = this.computeAmount(state);
    amt = computed_amount; //Number(this.contribution.ee_pension_contribution);
    // DOCO: cancelations have something to do with withdrawal logic

    let { total_contribs, total_int } = state.contribs
      .filter((c) => c.total_contribs > 0)
      .reduce(
        (tots, ctr) => ({
          total_contribs: tots.total_contribs + ctr.amt,
          total_int: tots.total_int + ctr.interest,
        }),
        { total_contribs: amt, total_int: interest }
      );
    total_contribs = round2(total_contribs);
    total_int = round2(total_int);
    let cancelation = this.temporary ? 0 : this.doCancelations(amt, state);
    let state_contribution_balance = round2(
      state.contribution_balance + interest + amt - cancelation
    ); //total_contribs + total_int;
    let state_contribution_interest = round2(
      state.contribution_interest + interest
    ); //total_int;

    //if (!this.temporary) this.scheduleEOY(state);
    if (!this.temporary) this.scheduleEstimatedContributions(state);
    // peel off the last EOY interest and incorporate it
    if (
      !this.temporary &&
      state.contribs.length > 0 &&
      state.contribs[state.contribs.length - 1].auto_int &&
      state.contribs[state.contribs.length - 1].date.equals(this.date)
    ) {
      let automatic = state.contribs.pop();
      interest = automatic.interest;
    }

    return {
      years,
      interest,
      amt,
      total_contribs,
      total_int,
      balance: total_contribs + total_int,
      cancelation,
      state_contribution_balance,
      state_contribution_interest,
      is_projected_contribution,
      debug,
    };
  }

  apply(state) {
    if (state.date.equals(this.date)) {
      let params = this.computeParameters(state);
      //params.balance = params.state_contribution_balance;
      let ctrb = this.makeContribEntry(state, params);
      state.push_contrib(ctrb);
      state.contribution_balance = params.state_contribution_balance;
      state.contribution_interest = params.state_contribution_interest;
      state.ytd_deposited_interest += round2(ctrb.interest);
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, "CONTRIB", `d: ${this.date.nb_str}, ysl: ${years}, amt: ${this.contribution.ee_pension_contribution}, c: ${cancelation}, int: ${interest} -- tc: ${total_contribs} ti: ${total_int} bal: ${state.contribution_balance}`);
      //console.table(`%cCALC`, comp_stl, state.contribs.slice());

      state.last_contribution = this; // TODO: do we need to know what period the contribution covers?
      //FIXME: raise error if balance dropped without a withdrawal

      state.raw_salaries.push({
        date: this.date,
        year: this.date.getFullYear(),
        bsalary: this.contribution.eoy_base_salary,
        psalary: this.contribution.eoy_base_salary,
      });
      if (state.contribution_balance > 0) {
        state.addTag(this.id, Tag.ContribIn);
      } else {
        state.removeTag(Tag.ContribIn);
      }

      this.expected = state.expecting_contributions;
      this.expected_nonzero =
        this.expected && state.contributing && state.contributing <= state.date;

      //DEBUG_CTRB(1, `${state.date}:[${this.str}]  EXPECTED=${this.expected} NONZERO=${this.expected_nonzero}=(${state.contributing}&& ${state.contributing <= state.date})`);
      state.announce("contribution", this);
      if (!this.estimated)
        state.recordSeries({
          date: this.date,
          series: "contribution",
          brief_text: `ctrb: ${MONEY(
            this.contribution.ee_pension_contribution
          )}`,
          labels: ["contributions", `${MONEY(state.contribution_balance)}`],
          event: this,
          data: this.contribution,
          significant: this.contribution.ee_pension_contribution > 0,
        });

      // TODO: we should perhaps also track which employer the census data is from
      // TODO: that way we can alert if we're expecting records, but not from the employer they're coming from

      //alert if ctrbs employer code doesn't match anything we might expect
      let valid_employment_end_date = new EventDate(this.date, -1.2, "year");
      if (
        this.contribution.employer_code &&
        state.employed &&
        !state
          .anyActivity("employment")
          .filter(
            (e) =>
              !e.end_date ||
              new EventDate(e.end_date) >= valid_employment_end_date
          )
          .some((e) => e.employer_code === this.contribution.employer_code)
      ) {
        if (state.show_logs)
          console.warn("%cCALC", comp_stl, "WRONG EMPLOYER", state.date);
        let matches = state
          .anyActivity("employment")
          .filter((e) => e.employer_code === this.contribution.employer_code)
          .map((e) => (e.end_date ? new EventDate(e.end_date) : null));
        let end = matches.length > 0 ? matches[matches.length - 1] : null;
        state.recordError({
          level: "alert",
          code: "wrong employer in census/contrib",
          force: false,
          evt: this,
          brief_text: "census for non-emp",
          msgs: [
            `contribution from ${this.contribution.employer_code}`,
            `${
              end
                ? `termed ${this.contribution.employer_code} on ${end.str}`
                : "no matching employment"
            }`,
          ],
        });
      }
      if (!this.expected) {
        // This is weird - we shouldn't be getting con{tributions / census data
        if (this.contribution.ee_pension_contribution > 0) {
          state.recordError({
            level: "alert",
            code: "unexpected census/contrib data",
            force: false,
            evt: this,
            msgs: ["unexpected contribution"],
          });
        } else if (!state.employed) {
          state.recordError({
            level: "alert",
            code: "unexpected census/contrib data",
            brief_text: "census for non-emp",
            force: false,
            evt: this,
            msgs: [
              "unexpected census",
              `from ${this.contribution.employer_code}`,
            ],
          });
        }
      }
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

export class EstimatedContribution extends Contribution {
  constructor(date, state, source) {
    super({
      id: `::CTRB_EST:${date - 0}::`,
      contribution_date: new EventDate(date),
      estimated: true,
    });
    this.date = date;
    this.valid = true;
    this.amount = null;
    this.source = source;
  }
  get estimated() {
    return true;
  }
  computeAmount(state) {
    let projected_salary_amt = 0;
    let is_projected_contribution = false;

    if (this.amount === null && !this.is_gap(state)) {
      is_projected_contribution = true;
      const last = state.contribs?.[state.contribs.length - 1];
      projected_salary_amt = 0;
      if (last && state.employed && state.contributing) {
        projected_salary_amt = state.projectedProratedSalary(
          last.date,
          this.date
        );
        this.amount = projected_salary_amt * 0.06;
      }
    }
    if (state.show_logs)
      console.warn(`%cCALC`, comp_stl, "EST CONTRIB", state.date, {
        contribs: state.contribs,
        last: state.contribs?.[state.contribs.length - 1],
        projected_salary_amt,
        is_projected_contribution,
        projected_salary_amt,
        source: this.source,
      });
    return {
      computed_amount: this.amount,
      is_projected_contribution,
      projected_salary_amt,
      debug: { source: this.source },
    };
  }

  // check if the scheduled contribution represents a gap in the contribution history
  // we check against a point 6 months BEFORE the simulation runpoint:
  // ex1: runpoint = 11/30/2023, then an expected contribution at 12/31/2022 is a gap
  // ex2: runpoint = 03/31/2023, then an expected contribution at 12/31/2022 is NOT a gap
  is_gap(state) {
    const last_gap_contribution_cutoff = new Date(
      state.runpoint.getFullYear(),
      state.runpoint.getMonth() - 6,
      1
    );
    return this.date < last_gap_contribution_cutoff;
  }

  apply(state) {
    if (this.amount !== undefined) {
      super.apply(state);
      //if (state.show_logs) console.warn(`%cCALC ${state.date}`, comp_stl, `### WEIRD: APPLYING EST. CONTRIB ### ${this.str} ${this.computeAmount(state)}`, comp_stl_unimpl);
    }
  }
}

class ParticipationOptOut extends Event {
  get is_primary() {
    return true;
  }
  get id() {
    return `OPTOUT:${this.participation.id}:${this.date - 0} `;
  }
  get str() {
    return `OPT OUT @${this.date} [${
      this.before_participation ? "OK" : "TOO LATE"
    }]`;
  }

  constructor(participation) {
    super();
    this.participation = participation;
    this.date = new EventDate(participation.form_date);
    this.before_participation = null;
  }

  apply(state) {
    if (state.date.equals(this.date)) {
      if (!state.contributing || this.date <= state.contributing) {
        this.before_participation = true;
      } else {
        this.before_participation = false;
        state.recordError({
          level: "alert",
          evt: this,
          code: "late opt out",
          msgs: [
            "late opt out",
            `participation date was ${state.contributing}`,
          ],
        });
      }
      state.free_years =
        (state.basis < EventDate.mandatory_contrib_change ? 3 : 1) -
        (state.vesting_years + state.ptd_vesting_years);

      state.opted_out = this.date;
      state.opted_out_bonus = state.contributing;
      state.addActivity("opt_out", this);
      state.addTag(this.id, Tag.OptedOut);
      state.removeTag(Tag.OptedIn); // Remove the OptedIn tag
      state.announce("opt_out", this);
      this.lbl = state.recordLabel({
        date: this.date,
        series: "opt_out",
        labels: ["opt out"],
        event: this,
        data: this.participation,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class ParticipationReturn extends Event {
  get is_primary() {
    return true;
  }
  get id() {
    return `OPTIN: ${this.date - 0} `;
  }
  get str() {
    return `PARTICIPATION RETURN @${this.date} [opted_out: ${
      this.opted_out ? this.opted_out : "never opted out"
    }]`;
  }

  constructor(participation) {
    super();
    this.participation = participation;
    this.date = new EventDate(participation.form_date);
    this.opted_out = null;
  }
  apply(state) {
    if (state.date.equals(this.date)) {
      if (state.opted_out && !this.opted_in) {
        // First time through we run on the form date to determine the actual opt-in date, then set up to recur at that point
        this.opted_out = state.opted_out;
        this.opted_in = new EventDate(this.date);
        // Set to first day of following month:
        if (this.opted_in.getDate() > 1) {
          this.opted_in.setMonth(this.opted_in.getMonth() + 1);
          this.opted_in.setDate(1);
        }
        this.date = this.opted_in;
        state.pending_events.push(this);
        return false;
      } else if (state.opted_out && this.opted_in) {
        // This time around we're on the actual opt-in date, so we record the event and label
        state.opted_in = this.opted_in;
        state.removeTag(Tag.OptedOut);
        state.addTag(this.id, Tag.OptedIn); // Add the OptedIn tag
        if (!state.tags.has(Tag.Participant)) {
          new Participating(state.basis).insert(state);
        }
        state.recordLabel({
          date: state.opted_in,
          series: "opt_in",
          labels: ["opt in"],
          event: this,
          data: this.participation,
        });

        // Find the old opt-out so we can cancel the label:
        const opt_out = state.lastActivity("opt_out");
        if (opt_out) {
          opt_out.lbl.cancel("returned");
        }
        state.announce("opt_in", this);
        return true;
      } else {
        // Not an alert: there's nothing to do, so throw up hands
        state.recordLabel({
          date: this.date,
          series: "opt_in",
          labels: ["opt in"],
          event: this,
          data: this.participation,
        });
        state.recordError({
          evt: this,
          code: "opt-in w/o opt-out",
          msgs: ["never opted out"],
        });
        return true;
      }
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

export class PartTimeHours extends Event {
  get is_primary() {
    return true;
  }
  get id() {
    return `PTHOURS:${this.adjustment.id}:${this.date - 0}: ${this.hours}`;
  }
  get str() {
    return `PTHOURS @${this.date} ${this.hours} ${
      this.adjustment ? "original" : `carryover #${this.count}`
    }`;
  }

  constructor(date, basis, hours, adjustment, count) {
    super();
    this.count = count;
    this.hours = hours;
    this.adjustment = adjustment;
    this.basis = basis;
    this.date = date; //new EventDate(adjustment.adjustment_date);
  }
  basisChange(state, basis) {
    if (state.show_logs)
      console.log("%cCALC", comp_stl, "PTHOURS NEW BASIS", basis);
    this.basis = basis;
    this.apply(state);
  }

  apply(state) {
    if (this.basis && state.basis !== this.basis) return false;
    if (state.date.equals(this.date) || state.date >= this.date) {
      if (!this.basis) {
        // some error in dating or ordering means we exist before anyone's employed
        this._sub = state.subscribe("basis_change", (s, b) =>
          this.basisChange(s, b)
        );
        return false;
      }
      /*
			let excess_hours = state.ptd_service_hours + this.hours - 2280;
			if (excess_hours > 0 && this.count < 100) {
					// try applying the excess a month from now
					//if (state.show_logs) console.log(`%cCALC`, comp_stl, this.str, `YTD: ${state.ptd_service_hours}`, `EXCESS`, excess_hours);
					new PartTimeHours(new EventDate(this.date, 1), this.basis, excess_hours, null, this.count + 1).insert(state);
					this.hours = this.hours - excess_hours;
			}*/
      if (!state.tags.has(Tag.PartTime)) {
        if (state.show_logs)
          console.warn("%cCALC", comp_stl, "NOT PART TIME", this.str);
        state.recordError({
          level: "alert",
          code: "part time hours",
          date: state.date,
          msgs: ["not part time", `tried to apply ${this.hours} PT hours`],
          evt: this,
        });
      } else {
        if (this.hours > 0)
          state.addAdjServiceHours(this.hours, state.date, this);
      }
      if (this.adjustment)
        state.recordSeries({
          date: this.date,
          series: "part_time_hours",
          brief_text: `PT HRS: ${this.adjustment.service_hours}`,
          labels: [
            "part-time credit",
            `${this.adjustment.service_hours} hours`,
          ],
          event: this,
          data: this.adjustment,
        });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class SpanEvent extends Event {
  makeEndEvent(state) {
    this.end_evt = {};
  }
  earlyEnd(state) {
    if (this.node) {
      this.node.end =
        this.node.end && this.node.end <= state.date
          ? this.node.end
          : state.date;

      if (!this.end_evt) {
        this.makeEndEvent(state);
        if (state.show_logs)
          console.log(
            "%cCALC",
            comp_stl,
            "MADE END EVENT",
            this.end_evt,
            this.end_evt.constructor.name
          );
        this.end_evt.insert(state);
      }
      if (!this.end_evt.date || this.end_evt.date >= state.date)
        this.end_evt.date = state.date;
    }
  }
  applySubs(state) {
    this._deceased_sub = state.subscribe("deceased", (s) => this.earlyEnd(s));
  }
}

export class EmploymentBegin extends SpanEvent {
  get is_primary() {
    return true;
  }
  constructor(employment, start_date, non_participating) {
    super();
    this.employment = employment;
    //if (state.show_logs) console.log(`%cCALC`, comp_stl, "EMPLOYMENT BEGIN INFO", { ...this.employment });
    this.date = new EventDate(start_date ? start_date : employment.start_date);
    //this.begin = new EventDate(this.date);

    //this.span_begins = new EventDate(employment.start_date);
    //this.span_ends = employment.end_date ? new EventDate(employment.end_date) : null;
    this.employment.span_begins = new EventDate(employment.start_date);
    this.employment.span_ends = employment.end_date
      ? new EventDate(employment.end_date)
      : null;
    this.span_begins = new EventDate(
      start_date ? start_date : new EventDate(employment.start_date)
    );
    this.span_ends = this.employment.span_ends;
    this.span_tags = [];
    this.non_participating = non_participating;
  }
  get id() {
    return `EMPL:${this.employment.id}:${this.date - 0}:${
      this.employment.employer_code
    } ${this.synthesized_by ? `::SYNTHBY:${this.synthesized_by.id}` : ""}`;
  }
  get str() {
    return `EMPLOYMENT BEGIN @${this.date} - ${this.employment.employer.name}`;
  }

  makeEndEvent(state) {
    this.end_evt = new EmploymentEnd(this.employment);
  }

  apply(state) {
    if (
      this.employment.span_ends &&
      this.employment.span_begins > this.employment.span_ends
    ) {
      if (state.show_logs) console.error("end before begin");
      state.recordError({
        evt: this,
        code: "bad dates",
        brief_text: "employment end < beginning",
        msgs: ["employment ends before start"],
      });
      this.span_tags = [...this.span_tags, "ERROR"];
      this.node = state.recordSpan({
        start: this.span_begins,
        end: this.span_ends,
        series: "employment",
        brief_text: `${this.employment.employment_type.name.toLowerCase()} @${
          this.employment.employer.code
        }`,
        labels: [`${this.employment.employer.name}`],
        tags: this.span_tags,
        event: this,
        data: this.employment,
      });
      return false;
    }

    if (state.date.equals(this.date)) {
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, "trying to start an empl", this.id, state.employment ? state.employment.id : null, state.status, " stack:", state.pending_events.map(e=>e.id).join(", "));
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, this, state.status, state.employment);
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, this.employment);
      this.applySubs(state);

      if (state.employment) {
        // Already employed, so what's going on...
        let existing = state.employment;
        if (existing.span_ends?.equals(this.date)) {
          // old employment also ending today, so we just need to defer until it has
          state.pending_events.push(this);
          return false;
        }
        // error - should never have simultaneous employments
        let message = "conflicting employment";
        state.recordError({
          evt: this,
          code: "conflicting employment",
          brief_text: "already employed",
          msgs: [message, `already at ${existing.employer.name}`],
        });

        // make sure editor is available
        this.employment.errors = this.employment.errors
          ? [...this.employment.errors, message]
          : [message];
        this.span_tags = [...this.span_tags, "ERROR"];
        this.node = state.recordSpan({
          start: this.span_begins,
          end: this.span_ends,
          series: "employment",
          brief_text: `${this.employment.employment_type.name.toLowerCase()} @${
            this.employment.employer.code
          }`,
          labels: [`${this.employment.employer.name}`],
          tags: this.span_tags,
          event: this,
          data: this.employment,
        });

        return false;
      }

      if (
        state.deceased &&
        (!this.employment.span_ends ||
          state.deceased < this.employment.span_ends)
      ) {
        // Will be deceased before this employment ends, something should have set an end...
        if (state.show_logs)
          console.warn(
            "%cCALC",
            comp_stl,
            this.str,
            "END DATE -> DEC. DATE",
            state.deceased,
            this.employment.span_ends,
            state.deceased < this.employment.span_ends
          );
        this.employment.span_ends = new EventDate(state.deceased);
        this.employment.end_date = new EventDate(state.deceased);
        state.recordError({
          level: "alert",
          code: "missing end date",
          date: this.employment.end_date,
          msgs: ["no employment end date"],
          evt: this,
        });
      }
      if (state.deceased && this.date >= state.deceased) {
        // Already deceased before start...
        state.recordError({
          evt: this,
          code: "already deceased",
          brief_text: "already deceased",
          msgs: ["already deceased", `can't start employment`],
        });
        this.employment.span_ends = new EventDate(state.deceased, 1, "day");
        this.employment.end_date = new EventDate(state.deceased, 1, "day");
        if (state.show_logs)
          console.warn(
            "%cCALC",
            comp_stl,
            this.str,
            "END DATE -> DEC. DATE + 1 day",
            this.employment.span_ends
          );
      }
      //FIXME: a mess

      state.employment = this.employment;
      state.addActivity("employment", this.employment);

      if (!state.tags.has(Tag.NeverEmployed)) {
        state.addTag(this.id, Tag.ReEmployed);
      } else {
        state.removeTag(Tag.NeverEmployed);
      }

      state.removeTag(Tag.Terminated);
      state.addTag(this.id, Tag.Employed);

      if (this.employment.employment_type.full_time) {
        state.addTag(this.id, Tag.FullTime);
        state.removeTag(Tag.PartTime);
      } else {
        state.addTag(this.id, Tag.PartTime);
        state.removeTag(Tag.FullTime);
      }

      // Set up to handle late joining affiliates or changes in employment type eligibility
      let group_participation_date = this.employment?.employer?.joined
        ? new EventDate(this.employment.employer.joined)
        : null;

      let group_eligible_now =
        !group_participation_date || this.date >= group_participation_date;
      let emp_type_eligible_now =
        (this.date < EventDate.project_staff_elig_change &&
          this.employment.employment_type.eligible_prior_to_sep1_1995) ||
        (this.date >= EventDate.project_staff_elig_change &&
          this.employment.employment_type.eligible_after_sep1_1995);

      let group_eligible_later =
        group_participation_date === null ||
        this.employment.end_date === null ||
        new EventDate(this.employment.end_date) > group_participation_date;
      let emp_type_eligible_later =
        this.employment.employment_type.eligible_after_sep1_1995 &&
        (this.employment.end_date === null ||
          this.employment.end_date === undefined ||
          new EventDate(this.employment.end_date) >
            EventDate.project_staff_elig_change);

      if (group_eligible_now && emp_type_eligible_now && !this.non_participating) {
        // Eligible at this time
        if (this.employment.employment_type.full_time) {
          state.status = State.Employed;
        } else {
          state.status = State.EmployedPartTime;
        }
        state.removeTag(Tag.Ineligible);
        this.span_tags = [
          ...this.span_tags,
          this.employment.employment_type.name.toLowerCase(),
        ];
        // state.recordSpan(this.date, this.employment.end_date ? new EventDate(this.employment.end_date) : null, "employment", this.employment.employer.name, this, this.employment);
        state.recordSpan({
          start: this.span_begins,
          end: this.employment.span_ends,
          series: "employment",
          brief_text: `${this.employment.employment_type.name.toLowerCase()} @${
            this.employment.employer.code
          }`,
          labels: [`${this.employment.employer.name}`],
          tags: this.span_tags,
          event: this,
          data: this.employment,
        });
      } else {
        // not eligible at this time
        state.status = State.EmployedNotEligible;
        state.addTag(this.id, Tag.Ineligible);

        if (!this.non_participating && group_eligible_later && emp_type_eligible_later) {
          // There's a difference in eligibility for this type of employment before and after the cutoff
          // So we'll drop in a pair of synthetic employment end/begin events on that date.
          //if (state.show_logs) console.log(`%cCALC`, comp_stl, "attempting to synthesize a second span where we'll be eligible");

          // first pick the  later of the two possibilities (prj staff / late joining group)
          let transition_date =
            group_participation_date !== null &&
            group_participation_date > EventDate.project_staff_elig_change
              ? group_participation_date
              : EventDate.project_staff_elig_change;

          let newbegin = new EmploymentBegin(this.employment, transition_date);
          let newend = new EmploymentEnd(this.employment, transition_date);

          newend.synthesized_by = this;
          newend.insert(state);
          newbegin.synthesized_by = this;
          newbegin.insert(state);
          //the synthesized begin will take care of inserting the final end, so set a flag
          // on ourself so we don't do it
          this.emitted_end = true;

          if (state.show_logs)
            console.warn(
              "%cCALC",
              comp_stl,
              "TRANSITION DATE",
              transition_date,
              this.span_begins,
              transition_date
            );

          this.span_tags = [
            ...this.span_tags,
            this.employment.employment_type.name.toLowerCase(),
            "non-participating",
          ];
          state.recordSpan({
            start: this.span_begins,
            end: transition_date,
            series: "ineligible",
            brief_text: `${this.employment.employment_type.name.toLowerCase()} @${
              this.employment.employer.code
            }`,
            labels: [this.employment.employer.name],
            tags: this.span_tags,
            event: this,
            data: this.employment,
          });
        } else {
          this.span_tags = [
            ...this.span_tags,
            this.employment.employment_type.name.toLowerCase(),
            "non-participating",
          ];
          state.recordSpan({
            start: this.span_begins,
            end: this.employment.span_ends,
            series: "ineligible",
            brief_text: `${this.employment.employment_type.name.toLowerCase()} @${
              this.employment.employer.code
            }`,
            labels: [this.employment.employer.code],
            tags: this.span_tags,
            event: this,
            data: this.employment,
          });
        }
      }

      if (!state.hired) {
        state.hired = this.date;
      }
      let last = state.activities.employment.at(-2);
      if (state.hired && last) {
        this.employment.rehire = last?.span_ends?.equals(this.date)
          ? "Transferred"
          : "Rehired";
      }
      let one_month_exception = 30 * 24 * 60 * 60 * 1000; //this is 30 days in ms. It is the exception for a new job before the basis changes
      let date_diff =
        new Date(this?.employment?.start_date) - new Date(last?.end_date);

      //if (!state.basis && !state.tags.has(Tag.Ineligible)) {
      //if (!state.tags.has(Tag.Ineligible)) { //original
      if (
        !state.tags.has(Tag.Ineligible) &&
        (!state.basis || date_diff > one_month_exception)
      ) {
        // we want to check if a person has a basis or is within the month exception
        state.basis = this.date;
        if (!state.benefit_start) {
          state.benefit_start = this.date;
        }
        new ServiceMonth(this.date, 0, this).insert(state); // New employment basis
        new ComputationPeriod(this.date, 1, this).insert(state); // New employment basis
        new Participating(state.basis).insert(state);
      }

      if (this.employment.end_date && !this.emitted_end) {
        this.makeEndEvent(state);
        this.end_evt.insert(state);
      }

      // TODO: makes more sense to actually set "context" here: e.g., this employment is extant, these are the previous and next
      // most of which should already be available
      // then have context.js have matchers that can put that together, e.g.:
      // if (ctx.employment && ctx.prior_employment) items.push(merge(ctx.employment, ctx.prior_employment))
      // etc.
      /*
			state.addContext({
					template: 'end_employment',
					args: {
							start: this.employment.span_begins,
							end: this.employment.span_ends,
							id: this.id,
							data: this.employment,
					}
			});
 
			let previous = state.anyActivity('employment').filter(e => e.span_begins < this.employment.span_begins);
			if (state.show_logs) console.log(`%cCALC`, comp_stl, "previous", previous);
			if (previous.length > 0) {
					previous = previous[previous.length - 1];
					if (state.show_logs) console.log(`%cCALC`, comp_stl, "FOUND PREV TO MERGE", previous);
					state.addContext({
							template: 'merge_employment',
							args: {
									previous_data: previous,
									data: this.employment,
									start: this.employment.span_begins,
									end: this.employment.span_ends,
									id: this.id,
							}
					});
			}*/
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  insert(state) {
    super.insert(state);
    state.employment_dates.push(this.date);
  }
  get interesting() {
    return true;
  }
}

class SuspensionBegin extends SpanEvent {
  get is_primary() {
    return true;
  }
  constructor(suspension) {
    super();
    this.suspension = suspension;
    this.date = new EventDate(suspension.start_date);
    //console.warn("Susp begin", this.date)
    this.begin = new EventDate(this.date);
    this.span_tags = [];
  }
  get id() {
    return `SUSP:${this.suspension.id}:${this.date - 0}: ${
      this.suspension.suspension_type.name
    }`;
  }
  get str() {
    return `SUSPENSION STARTING @${this.date} type = ${this.suspension.suspension_type.name}`;
  }
  makeEndEvent(state) {
    this.end_evt = new SuspensionEnd(this.suspension, this.old_status, this);
  }
  insert(state) {
    super.insert(state);
    state.suspension_dates.push(this.date);
  }
  applySubs(state) {
    super.applySubs(state);
    this._emp_end_sub = state.subscribe("employment_end", (s) =>
      this.earlyEnd(s)
    );
  }

  end(state) {
    state.unsubscribe(this._emp_end_sub);
    state.unsubscribe(this._deceased_sub);
  }
  apply(state) {
    if (this.date < state.date) {
      state.pending_events.push(this);
      return false;
    }
    if (state.date.equals(this.date) && state.status === State.Suspended) {
      // error - should never have simultaneous suspensions
      state.recordError({
        evt: this,
        code: "suspension but already suspended",
        msgs: ["already suspended"],
      });
      // come back later if that situation resolves itself
      //state.date_insufficient += 1;
      // state.pending_events.push(this);
      // return false;
    }
    if (state.date.equals(this.date) && !state.employed) {
      //!state.active_events.employment) {
      /*
			if (state.show_logs) console.warn(`%cCALC`, comp_stl, 'weirdness', state);
			state.activities.employment.forEach(e => {
					if (state.show_logs) console.log(`%cCALC`, comp_stl, '\n\n', JSON.stringify(e));
			})
			let last = state.activities.employment[state.activities.employment.length - 1];
			if (state.show_logs) console.log(`%cCALC`, comp_stl, last)
			if (state.show_logs) console.log(`%cCALC`, comp_stl, state.date, last.span_ends, last.span_ends >= state.date);
			if (state.show_logs) console.log(`%cCALC`, comp_stl, state.activeActivity('employment'));
			*/
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, JSON.stringify(state.active_events));
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, state.arctives());
      state.recordError({
        evt: this,
        code: "suspension but not employed",
        brief_text: "nothing to suspend",
        msgs: ["suspension with no active employment"],
      });
      // come back later if that situation resolves itself
      //  state.date_insufficient += 1;
      //  state.pending_events.push(this);
      //  return false;
    }

    if (state.date.equals(this.date) || state.date >= this.date) {
      this.applySubs(state);
      this.date = state.date;
      this.old_status = state.status;
      state.status = State.Suspended;
      state.addTag(this.id, Tag.Suspended);
      //if (state.show_logs) console.log(`%cCALC`, comp_stl, this.id, this.date, state.status, Array.from(state.tags.keys()).map(k => Symbol.keyFor(k)));

      // FIXME: activities badness
      //state.suspensions.push(this.suspension);
      state.addActivity("suspension", this.suspension);

      // TODO: more specific suspension tags?
      // QUESTION: still unclear how the adjustments work and what types of suspensions qualify. Does milit. suspension top out at 501 like other suspensions? (p11) Are there two kinds of milit. suspension?
      let end_date = this.suspension.end_date
        ? new EventDate(this.suspension.end_date)
        : null;
      if (
        this.suspension.suspension_type.paid &&
        !state.service_bank.some((b) => b.type === "limited")
      ) {
        state.service_bank.push({
          type: "limited",
          amount: 501,
          expires: end_date,
        });
      } else if (this.suspension.suspension_type.unlimited) {
        state.service_bank.push({
          type: "unlimited",
          amount: null,
          expires: end_date,
        });
      } else if (this.suspension.suspension_type.limit_service_break) {
        // set expiration date to the next basis anniversary
        let expire_date = end_date
          ? end_date.nextAnniversaryOf(state.basis)
          : null;
        state.service_bank.push({
          type: "adjusted",
          amount: 501,
          expires: expire_date,
        });
      }

      if (end_date) {
        this.makeEndEvent(state);
        this.end_evt.insert(state);
      }
      this.span_tags = [
        ...this.span_tags,
        this.suspension.suspension_type.short_name,
      ];
      if (this.suspension.suspension_type.paid) this.span_tags.push("paid");
      //TODO: maybe tag the limit breaks and unlimited fields too? need better names for them
      this.node = state.recordSpan({
        start: this.begin,
        end: this.suspension.end_date
          ? new EventDate(this.suspension.end_date)
          : null,
        series: "suspension",
        brief_text: this.suspension.suspension_type.name.toLowerCase(),
        labels: ["Suspension"],
        event: this,
        data: this.suspension,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class SuspensionEnd extends Event {
  get is_primary() {
    return true;
  }
  get is_end() {
    return true;
  }
  constructor(suspension, old_status, begin_evt) {
    super();
    this.suspension = suspension;
    this.old_status = old_status;
    this.date = suspension.end_date ? new EventDate(suspension.end_date) : null;
    this.begin_evt = begin_evt;
  }
  get id() {
    return `SUSPEND:${this.suspension.id}:${this.date - 0}:${
      this.suspension.suspension_type.code
    }`;
  }
  get str() {
    return `SUSPENSION ENDING @${this.date} type = ${this.suspension.suspension_type.name}`;
  }
  insert(state) {
    super.insert(state);
    state.suspension_return_dates.push(this.date);
  }
  apply(state) {
    if (this.date < state.date) {
      state.pending_events.push(this);
      return false;
    }
    /*
		if (state.status !== State.Suspended) {
				// error - should never have simultaneous suspensions
				state.recordError({ evt: this, msgs: ["not suspended"] });
				// come back later if that situation resolves itself
				//this.date_insufficient += 1;
				// state.pending_events.push(this);
				// return false;
		}*/
    if (state.date.equals(this.date) || state.date >= this.date) {
      this.date = state.date;
      state.status = this.old_status;
      state.removeTag(Tag.Suspended);
      if (this.suspension.suspension_type.paid) {
        let previous_date = state.service_bank
          .filter((b) => b.type === "limited")
          .reduce((acc, cur) => (acc = cur > acc ? cur : acc), null);
        if (previous_date) {
          previous_date = new EventDate(previous_date);
        }

        /* FIXME: if a subsequent suspension is contiguous (no intervening workdays) suspension bank intact */

        state.service_bank = state.service_bank.filter(
          (b) => b.type !== "limited"
        ); // remove the old adjustment bank, if any
      }
      if (this.suspension.suspension_type.unlimited) {
        state.service_bank = state.service_bank.filter(
          (b) => b.type !== "unlimited"
        ); // remove the old adjustment bank, if any
      }
      this.begin_evt.end(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class Participating extends Event {
  constructor(basis) {
    super();
    this.basis = basis;
  }
  get id() {
    return `PARTICP:${this.date - 0}`;
  }
  get str() {
    return `PARTICIPATING @${this.date} (${this.basis})`;
  }
  insert(state) {
    super.insert(state);
  }
  apply(state) {
    if (state.participating) {
      if (!state.tags.has(Tag.Contributing)) {
        new Contributing(state.hired, state.basis, state.participating).insert(
          state
        );
      }
      return false;
    }
    if (
      (!state.opted_out && state.vesting_years >= 1 && state.employed) ||
      (state.opted_in && state.opted_in <= state.date && state.employed)
    ) {
      this.date = state.date;

      // add a contribution start event -- but only for the first participation start (in cases of (partially) reset service)

      if (!state.tags.has(Tag.Contributing)) {
        new Contributing(state.hired, state.basis, this.date).insert(state);
      }
      // ditto vesting
      state.participating = this.date;
      if (!state.vested) {
        new Vested(state.participating).insert(state);
        new AgeVesting(state.dob, state.participating).insert(state);
      }

      state.addTag(this.id, Tag.Participant);
      const lbl = state.recordLabel({
        date: this.date,
        series: "participation_begin",
        labels: ["participant"],
        event: this,
      });
      state.subscribe("service_reset", () => lbl.cancel("lost service"));
      new NormalRetirementEligible(state.dob, state.participating).insert(
        state
      );
      new EarlyRetirementEligible(state.dob, state.participating).insert(state);
      new UnreducedEarlyRetirementEligible(
        state.dob,
        state.participating
      ).insert(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class Vested extends Event {
  constructor(participating) {
    super();
    this.participating = participating;
  }
  get id() {
    return `VEST:${this.date - 0}`;
  }
  get str() {
    return `VESTED @${this.date}`;
  }
  insert(state) {
    super.insert(state);
  }
  apply(state) {
    if (this.participating !== state.participating) {
      return false;
    }
    if (state.ptd_total_vesting_years >= 5) {
      // && state.ptd_service_hours >= 1000) {
      this.date = state.date;
      state.vested = this.date;
      state.removeTag(Tag.NotVested);
      state.addTag(this.id, Tag.Vested);
      state.recordLabel({
        date: this.date,
        series: "vesting",
        labels: ["vested"],
        event: this,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class DeceasedEvent extends Event {
  get is_primary() {
    return true;
  }
  constructor(deceased_date, dob) {
    super();
    this.date = new EventDate(deceased_date);
    this.age_at_death = dob.yearsUntil(this.date);
  }

  get id() {
    return `DECEASED:${this.date - 0}:${this.age_at_death}`;
  }
  get str() {
    return `Deceased @${this.date} age ${this.age_at_death}`;
  }

  apply(state) {
    if (state.endpoint < this.date) {
      //FIXME: was state.runpoint
      // not reachable within timeline bounds
      return false;
    }
    if (state.date.equals(this.date)) {
      state.addTag(this.id, Tag.Deceased);
      state.removeTag(Tag.RequiredBeginning);
      state.removeTag(Tag.Terminated);
      state.removeTag(Tag.Suspended);
      state.removeTag(Tag.Employed);
      state.removeTag(Tag.RetEligible);
      state.removeTag(Tag.Retired);
      state.removeTag(Tag.Disability);

      // [Tag.RetEligible, Tag.RequiredBeginning, Tag.Suspended, Tag.Disability].forEach(t => state.removeTag(t));
      state.status = State.Deceased;
      state.recordLabel({
        date: state.deceased,
        series: "deceased",
        labels: ["deceased"],
        event: this,
      });

      state.announce("deceased");
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
}

class NormalRetirementEligible extends AgeEvent {
  constructor(dob, participating) {
    super(dob, 65);
    this.participating = participating;
    if (participating && this.date < participating) this.date = participating;
  }
  get id() {
    return `NORMRET:${this.date - 0}`;
  }
  get str() {
    return `Eligible for normal retirement (age = 65) @${this.date}`;
  }
  apply(state) {
    if (state.date < this.date) {
      state.pending_events.push(this);
      return false;
    }
    if (
      state.participating !== this.participating ||
      state.endpoint < this.date ||
      (state.deceased && state.deceased <= this.date) ||
      !state.participating
    ) {
      //FIXME: was state.runpoint
      // never applicable
      return false;
    }
    if (state.date.equals(this.date)) {
      state.full_ret_elig_date = this.date;
      this.age_at_eligibility = state.age;
      if (!state.tags.has(Tag.Retired)) {
        state.addTag(this.id, Tag.RetEligible);
      }
      state.recordLabel({
        date: this.date,
        series: "retirement_eligibility",
        labels: ["normal ret. elig."],
        event: this,
      });
      this.check_for_end_condition(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class UnreducedEarlyRetirementEligible extends AgeEvent {
  constructor(dob, participating) {
    super(dob, 55);
    this.participating = participating;
    if (this.date < participating) this.date = participating;
    this.service_years = 0;
    this.age_at_eligibility = this.age;
    this.date_computed = false;
  }
  get id() {
    return `UNREDRET:${this.date - 0}`;
  }
  get str() {
    return `Eligible for unreduced early retirement @${this.date} (age = ${this.age}, svc yrs = ${this.service_years})`;
  }
  apply(state) {
    if (state.date < this.date) {
      state.pending_events.push(this);
      return false;
    }
    if (
      state.participating !== this.participating ||
      state.endpoint < this.date ||
      state.full_ret_elig_date ||
      (state.deceased && state.deceased <= this.date) ||
      !state.participating
    ) {
      //FIXME: was state.runpoint
      // no longer / never applicable
      return false;
    }

    // Initial date will be age 55.
    // Check to see if we're eligible; if not, we let the
    // system advance until enough service is accumulated
    if (state.date >= this.date && state.ptd_total_vesting_years < 20) {
      this.date_insufficient += 1; // mark to ignore this event as a date point advance source
    }

    // ...Once eligible, we set the exact date and set the computed flag
    if (
      state.date >= this.date &&
      state.ptd_total_vesting_years >= 20 &&
      !this.date_computed
    ) {
      this.date = state.date.nextFirstOfMonth();
      this.date_computed = true;
      this.date_insufficient = 0;
    }

    // When we reach the computed date, we set the eligibility date
    if (this.date_computed && state.date.equals(this.date)) {
      if (state.full_ret_elig_date && state.full_ret_elig_date <= this.date) {
        return false; // skip: already qualified for full
      }
      this.service_years = state.ptd_total_credited_years;
      this.age_at_eligibility = state.age;
      state.full_early_ret_elig_date = this.date;
      if (!state.tags.has(Tag.Retired)) {
        state.addTag(this.id, Tag.RetEligible);
      }
      state.recordLabel({
        date: this.date,
        series: "retirement_eligibility",
        labels: ["unreduced ret. elig."],
        event: this,
      });
      this.check_for_end_condition(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class EarlyRetirementEligible extends AgeEvent {
  constructor(dob, participating) {
    super(dob, 55);
    this.participating = participating;
    if (this.date < participating) this.date = participating;
    this.service_years = 0;
    this.age_at_eligibility = this.age;
    this.date_computed = false;
  }
  get id() {
    return `EARLYRET:${this.date - 0}`;
  }
  get str() {
    return `Eligible for [reduced] early retirement @${this.date} (age = ${this.age}, svc yrs = ${this.service_years})`;
  }
  apply(state) {
    if (this.date > state.date) {
      state.pending_events.push(this);
      return false;
    }
    if (
      state.participating !== this.participating ||
      state.endpoint < this.date ||
      state.full_early_ret_elig_date ||
      state.full_ret_elig_date ||
      (state.deceased && state.deceased <= this.date) ||
      !state.participating
    ) {
      return false; // not applicable
    }

    // Initial date will be age 55.
    // Check to see if we're eligible; if not, we let the
    // system advance until enough service is accumulated
    if (state.date >= this.date && state.ptd_total_vesting_years < 5) {
      this.date_insufficient += 1; // mark to ignore this event as a date point advance source
    }

    // ...Once eligible, we set the exact date and set the computed flag
    if (
      state.date >= this.date &&
      state.ptd_total_vesting_years >= 5 &&
      !this.date_computed
    ) {
      this.date = state.date.nextFirstOfMonth(); // early retirement is always the first of the month
      this.date_computed = true;
      this.date_insufficient = 0;
    }

    // When we reach the computed date, we set the eligibility date
    if (this.date_computed && state.date.equals(this.date)) {
      if (
        state.ptd_total_vesting_years >= 20 ||
        state.full_early_ret_elig_date ||
        state.full_ret_elig_date
      ) {
        return false; // skip: already qualified for unreduced or full
      }
      this.date = state.date;
      this.service_years = state.ptd_total_credited_years;
      this.age_at_eligibility = state.age;
      state.early_ret_elig_date = this.date;
      if (!state.tags.has(Tag.Retired)) {
        state.addTag(this.id, Tag.RetEligible);
      }
      state.recordLabel({
        date: this.date,
        series: "retirement_eligibility",
        labels: ["early ret. elig."],
        event: this,
      });
      this.check_for_end_condition(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

/*
RELATED: Required Beginning Date
The Pension Plan is required under Federal law to begin paying your benefit to you (or to
your Beneficiary, if you are not then living) not later than your Required Beginning Date.
Your Required Beginning Date is April 1 of the calendar year that first follows the later of
(1) the calendar year in which you attain age 701⁄2, or (2) the calendar year in which your
employment terminates. If you do not apply for your pension before your Required
Beginning Date, the Pension Plan will begin making monthly payments to you or to your
Beneficiary as of your Required Beginning Date in an amount sufficient to satisfy Federal
requirements.
 
NOTES: need to track the April 1 following age 70.5, then track both the "missing" payments from a normal retirement at that point vs. the increase in the pension formula from the work
 
So, need event at april 1 of the year following the year in which reached age 70.5
at that point, if NOT employed, (and not retired) this will be the required beginning date, and some kind of automatic pension needs to start and/or error should be flagged (to make a user create one)
if employed, a required beginning date event should be created that starts looking for the end of employment (after which the req beginning will be the Apr 1 of the next year)
AND need a series of "virtual pension payment" events that starts tracking the value of payments if a retirement started at that point. (Which any actual retirement started after that will need to reference for its benefit calculation)
QUESTION: do we need to have "official" benefit calculations for the virtual payments? If so, need an event for that. And need to flag if it's missing - possibly it only becomes an error once there's an actual retirement
*/

class RequiredBeginning extends AgeEvent {
  constructor(dob) {
    super(dob, 70.5);
    this.age_year = this.date.getFullYear();
    const next_april = new EventDate(
      new Date(this.date.getFullYear() + 1, 3, 1)
    );
    this.date = next_april;
    this.reason = "age 70½";
  }
  get id() {
    return `REQUIREDBEGIN:${this.date - 0}`;
  }
  get str() {
    return `Required Begin @${this.date} (age = ${this.age})`;
  }
  on_timeline(state) {
    return (
      this.date <= state.endpoint &&
      (!state.deceased || this.date <= state.deceased)
    ); //FIXME: was state.runpoint
  }
  finish(state) {
    this.date = state.date;
    state.required_beginning = this.date;
    if (!state.tags.has(Tag.Retired)) {
      state.addTag(this.id, Tag.RetEligible);
      state.addTag(this.id, Tag.RequiredBeginning);
      state.recordLabel({
        date: this.date,
        series: "retirement_eligibility",
        labels: ["required beginning", `(${this.reason})`],
        event: this,
      });
    }
    return true;
    //this.date_insufficient += 1; // mark to ignore this event as a date point advance source
  }
  apply(state) {
    if (this.date < state.date) {
      state.pending_events.push(this);
      return false;
    }
    if (!state.basis) {
      if (state.returning) {
        this.date = new EventDate(
          new Date(state.returning.getFullYear() + 1, 3, 1)
        );
        if (!this.on_timeline(state)) return false;
        state.pending_events.push(this);
      }
      return false;
    }
    if (state.date >= this.date && !this.empl_year) {
      const empl_end = state.employment?.end_date
        ? new EventDate(state.employment.end_date)
        : null;
      this.empl_year = empl_end ? empl_end.getFullYear() : this.age_year;
      if (this.empl_year > this.age_year) this.reason = "employment ended";
      this.date = new EventDate(
        new Date(
          this.empl_year >= this.age_year
            ? this.empl_year + 1
            : this.age_year + 1,
          3,
          1
        )
      );
      if (!this.on_timeline(state)) return false;
    }

    if (state.date >= this.date && state.employment) {
      return false; // working forever, no reqd beginning
    }
    if (state.date >= this.date) {
      return this.finish(state);
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class RetirementBegin extends SpanEvent {
  get is_primary() {
    return true;
  }
  constructor(retirement) {
    super();
    this.retirement = retirement;
    this.date = new EventDate(retirement.start_date);
    this.retirement_type = "INELIGIBLE";
    this.retirement.span_begins = new EventDate(retirement.start_date);
    this.retirement.status = this.retirement_type;
    this.retirement.span_ends = retirement.end_date
      ? new EventDate(retirement.end_date)
      : null;
    this.span_tags = [];
  }
  get id() {
    return `RETIRE:${this.date - 0}`;
  }
  get str() {
    return `RETIREMENT BEGIN @${this.date} [${this.retirement_type}]`;
  }

  makeEndEvent(state) {
    this.end_evt = new RetirementEnd(
      this.retirement,
      this.retirement_type,
      this.prior_status
    );
  }

  apply(state) {
    if (state.show_logs)
      console.warn("%cCALC", comp_stl, "APPLY RET", this.date, state.date);
    if (
      (this.date.equals(state.date) || this.date <= state.date) &&
      !state.any_retirement_eligible_date
    ) {
      const message = "not retirement eligible";
      // TODO: could be an alert?
      if (this.not_eligible_once === undefined)
        state.recordError({
          level: "alert",
          brief_text: "not eligible",
          code: "retiring but not eligible",
          evt: this,
          msgs: [message],
        });
      this.not_eligible_once = true;
      //this.date_insufficient += 3; // mark to ignore this event as a date point advance source
      this.span_tags = [...this.span_tags, "ERROR"];
      //this.retirement.errors = this.retirement.errors ? [...this.retirement.errors, message] : [message];
    }
    if (
      (this.date.equals(state.date) || this.date <= state.date) &&
      state.employment
    ) {
      //} || state.tags.has(Tag.Employed))) {
      const message = "retired before termination";
      this.span_tags = [...this.span_tags, "ERROR"];
      if (this.still_employed_once === undefined)
        state.recordError({
          code: "retiring but still employed",
          evt: this,
          msgs: [message],
        });
      this.still_employed_once = true;
      this.retirement.errors = this.retirement.errors
        ? [...this.retirement.errors, message]
        : [message];
    }
    if (this.date.equals(state.date) || this.date <= state.date) {
      //&& state.early_ret_elig_date) {
      this.applySubs(state);
      this.date = state.date;
      this.prior_status = state.status;
      let start_date = this.date;
      //state.removeTag(Tag.RetEligible);
      state.removeTag(Tag.Terminated);
      state.removeTag(Tag.Ineligible);
      state.removeTag(Tag.RequiredBeginning);
      state.addTag(this.id, Tag.Retired);
      if (state.full_ret_elig_date && this.date >= state.full_ret_elig_date) {
        this.retirement_type = "FULL";
        this.retirement.status = this.retirement_type;
        state.status = State.FullRetired;
      } else if (
        state.full_early_ret_elig_date &&
        this.date >= state.full_early_ret_elig_date
      ) {
        this.retirement_type = "FULL EARLY";
        this.retirement.status = this.retirement_type;
        state.status = State.FullEarlyRetired;
      } else if (
        state.early_ret_elig_date &&
        this.date >= state.early_ret_elig_date
      ) {
        this.retirement_type = "EARLY";
        this.retirement.status = this.retirement_type;
        state.status = State.EarlyRetired;
      }

      //FIXME: activities
      state.addActivity("retirement", this.retirement);

      if (this.retirement.end_date) {
        this.makeEndEvent(state);
        this?.end_evt?.insert(state);
      }
      //TODO:
      //calculate benefits
      // insert retirement end
      // handle simultaneous employment
      // ...
      this.span_tags = [
        ...this.span_tags,
        this.retirement.status.toLowerCase(),
        this.retirement.benefit_annuity_type_code,
      ];
      this.node = state.recordSpan({
        start: this.retirement.span_begins,
        end: this.retirement.span_ends,
        series: "retirement",
        tags: this.span_tags,
        brief_text: `${this.retirement_type.toLowerCase()} retirement`,
        labels: ["Retirement"],
        event: this,
        data: this.retirement,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class RetirementEnd extends Event {
  get is_primary() {
    return true;
  }
  get is_end() {
    return true;
  }
  constructor(retirement, type, previous_status, override_date) {
    super();
    this.retirement = retirement;
    this.date = override_date
      ? override_date
      : retirement.end_data
      ? new EventDate(retirement.end_date)
      : null;
    this.retirement_type = type;
    this.previous_status = previous_status;
  }
  get id() {
    return `ENDRETIRE:${this.date - 0}`;
  }
  get str() {
    return `RETIREMENT END @${this.date} [${this.retirement_type}]`;
  }
  apply(state) {
    if (this.date && this.date.equals(state.date)) {
      state.status = this.previous_status;
      state.removeTag(Tag.Retired);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class DeathBenBegin extends SpanEvent {
  get is_primary() {
    return true;
  }
  constructor(benefit) {
    super();
    this.benefit = benefit;
    this.date = new EventDate(benefit.start_date);
    this.benefit.span_begins = new EventDate(benefit.start_date);
    this.benefit.span_ends = benefit.end_date
      ? new EventDate(benefit.end_date)
      : null;
    this.apply_after = this.date;
    this.span_tags = [];
  }
  get id() {
    return `DEATH BENEFIT:${this.benefit.id}:${this.date - 0}`;
  }
  get str() {
    return `DEATH BENEFIT BEGIN @${this.date}`;
  }
  makeEndEvent(state) {
    this.end_evt = new DeathBenEnd(this.benefit, state.date, this.prior_status);
  }

  apply(state) {
    if (this.date < state.date) {
      state.pending_events.push(this);
      return false;
    }
    if (
      (this.date.equals(state.date) || this.date <= state.date) &&
      (!state.deceased || state.deceased_date > state.date)
    ) {
      const message = "not deceased";
      // TODO: alert?
      if (this.not_eligible_once === undefined)
        state.recordError({
          brief_text: "not eligible",
          code: "death benefit but not deceased",
          evt: this,
          msgs: [message],
        });
      this.not_eligible_once = true;
      this.date = state.deceased_date;
      this.span_tags = [...this.span_tags, "ERROR"];
      this.benefit.errors = this.benefit.errors
        ? [...this.benefit.errors, message]
        : [message];
    }
    if (this.date.equals(state.date) || this.date <= state.date) {
      this.date = state.date;
      this.applySubs(state);
      this.prior_status = state.status;
      let start_date = this.date;
      //state.removeTag(Tag.RetEligible);
      //state.addTag(this.id, Tag.DeathBenefit);
      //state.status = State.Disability;

      //FIXME: activities badness
      state.addActivity("retirement", this.benefit);
      state.addActivity("death_benefit", this.benefit);

      let end_date = this.benefit.end_date;

      if (end_date <= state.endpoint) {
        //FIXME: was state.runpoint
        this.end_evt = new DeathBenEnd(
          this.benefit,
          end_date,
          this.prior_status
        );
        this.end_evt.insert(state);
      }
      this.span_tags = [...this.span_tags];
      this.node = state.recordSpan({
        start: this.benefit.span_begins,
        end: end_date <= state.endpoint ? end_date : null,
        brief_text: "death benefit",
        series: "retirement",
        labels: ["Death Benefit"],
        event: this,
        data: this.benefit,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}
class DeathBenEnd extends Event {
  get is_primary() {
    return true;
  }
  get is_end() {
    return true;
  }
  constructor(benefit, end_date, previous_status, override_date) {
    super();
    this.benefit = benefit;
    this.date = end_date ? end_date : new EventDate(benefit.end_date);
    this.previous_status = previous_status;
  }
  get id() {
    return `END DEATH BENEFIT:${this.benefit.id}:${this.date - 0}`;
  }
  get str() {
    return `DEATH BEN END @${this.date}`;
  }
  apply(state) {
    if (this.date.equals(state.date)) {
      state.status = this.previous_status;
      //state.removeTag(Tag.DeathBenefit);
      this.benefit.span_ends = this.date;
      //state.recordLabel({ date: this.date, series: "retirement_eligibility", labels: ['disability expired'], event: this, data: this.disability });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

/* FIXME: /QUESTIONS:
 
 - should disability be able to overlap with employment? error if it does?
 - should an error/alert/tag be set if disability ends at retirement age?
 */

class DisabilityBegin extends SpanEvent {
  get is_primary() {
    return true;
  }
  constructor(disability) {
    super();
    this.disability = disability;
    this.date = new EventDate(disability.start_date);
    this.disability.span_begins = new EventDate(disability.start_date);
    this.disability.span_ends = disability.end_date
      ? new EventDate(disability.end_date)
      : null;
    this.apply_after = this.date;
    this.total_credit_years_applied = 0;
    this.span_tags = [];
  }
  get id() {
    return `DISABILITY:${this.disability.id}:${this.date - 0}`;
  }
  get str() {
    return `DISABILITY BEGIN @${this.date}`;
  }
  makeEndEvent(state) {
    this.end_evt = new DisabilityEnd(
      this.disability,
      state.date,
      this.prior_status
    );
  }

  apply(state) {
    if (this.date < state.date) {
      state.pending_events.push(this);
      return false;
    }
    if (
      (this.date.equals(state.date) || this.date <= state.date) &&
      state.credited_years < 10
    ) {
      const message = "disability requires 10 credited svc years";
      // TODO: alert?
      if (this.not_eligible_once === undefined)
        state.recordError({
          brief_text: "not eligible",
          code: "disability but insufficent service",
          evt: this,
          msgs: [message],
        });
      this.not_eligible_once = true;
      this.span_tags = [...this.span_tags, "ERROR"];
      this.disability.errors = this.disability.errors
        ? [...this.disability.errors, message]
        : [message];
    }
    if (
      (this.date.equals(state.date) || this.date <= state.date) &&
      state.status === State.Disability
    ) {
      const message = "already on disability!";
      if (this.overlap === undefined)
        state.recordError({
          brief_text: "already on disability",
          code: "disability but already on disability",
          evt: this,
          msgs: [message],
        });
      this.overlap = true;
      this.span_tags = [...this.span_tags, "ERROR"];
      this.disability.errors = this.disability.errors
        ? [...this.disability.errors, message]
        : [message];
    }

    if (this.date.equals(state.date) || this.date <= state.date) {
      this.date = state.date;
      this.applySubs(state);
      this.prior_status = state.status;
      let start_date = this.date;
      //state.removeTag(Tag.RetEligible);
      state.addTag(this.id, Tag.Disability);
      state.status = State.Disability;

      //FIXME: activities badness
      state.addActivity("retirement", this.disability);
      state.addActivity("disability", this.disability);

      let age_end = new AgeEvent(state.dob, 65).date;
      let end_date =
        this.disability.end_date && this.disability.end_date < age_end
          ? this.disability.end_date
          : age_end;

      if (state.show_logs)
        console.warn("%cCALC", comp_stl, "disability will end on", end_date);
      if (end_date <= state.endpoint) {
        //FIXME: was state.runpoint
        this.end_evt = new DisabilityEnd(
          this.disability,
          end_date,
          this.prior_status
        );
        this.end_evt.insert(state);
      }
      this.span_tags = [...this.span_tags];
      this.node = state.recordSpan({
        start: this.disability.span_begins,
        end: end_date <= state.endpoint ? end_date : null,
        brief_text: "disability retirement",
        series: "retirement",
        labels: ["Disability"],
        event: this,
        data: this.disability,
      }); // FIXME: was state.runpoint

      state.disability_last_accrual = this.date;
      state.disability_end = end_date;

      // Create an event to accrue service years
      new DisabilityCreditYear(
        /*
				state.pending_events.find(e =>
						e.constructor.name === 'ServiceMonthEnd'
						|| e.constructor.name === 'ServiceMonthBegin')
						? this.date.nextAnniversaryOf(state.basis) : this.date,
						*/
        this,
        this.date,
        end_date,
        state.basis
      ).insert(state);
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class DisabilityCreditYear extends Event {
  constructor(disability_begin, previous_date, end_date, basis) {
    super();
    this.basis = basis;
    this.end = end_date;
    this.date = previous_date.nextAnniversaryOf(basis);
    this.date = end_date < this.date ? end_date : this.date;
    this.years = Math.round(previous_date.yearsUntil(this.date) * 100) / 100;
    this.disability = disability_begin;
  }
  get id() {
    return `DisabilityCreditYear:${this.disability.id}:${this.basis - 0}:${
      this.date
    }`;
  }
  get str() {
    return `Disability Credit Years @${this.date}: vest yrs: ${
      this.vest_yrs ? this.vest_yrs : this.years
    } credit yrs: ${
      this.credit_yrs ? this.credit_yrs : this.years
    }, total credit yrs: ${this.disability.total_credit_years_applied}`;
  }

  apply(state) {
    if (state.date.equals(this.date)) {
      this.vest_yrs = this.years;
      let remaining_credit = 20 - this.disability.total_credit_years_applied;
      this.credit_yrs =
        this.years <= remaining_credit ? this.years : remaining_credit;
      this.disability.total_credit_years_applied += this.credit_yrs;

      //state.vesting_years += this.vest_yrs;
      state.addVestingYears(this.vest_yrs, this.date);
      state.addCreditedYears(this.credit_yrs, this.date);
      state.log_service("disability credit", this.date);

      if (this.end > this.date) {
        /*
				new DisabilityCreditYear(
						this.disability,
						this.date,
						this.end,
						state.basis).insert(state);*/
        let previous_date = new EventDate(this.date);
        this.date = previous_date.nextAnniversaryOf(this.basis);
        this.date = this.end < this.date ? this.end : this.date;
        this.years =
          Math.round(previous_date.yearsUntil(this.date) * 100) / 100;
      }
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
}

class DisabilityEnd extends Event {
  get is_primary() {
    return true;
  }
  get is_end() {
    return true;
  }
  constructor(disability, end_date, previous_status, override_date) {
    super();
    this.disability = disability;
    this.date = end_date
      ? end_date
      : disability.end_date
      ? new EventDate(disability.end_date)
      : null;
    this.previous_status = previous_status;
  }
  get id() {
    return `ENDDISABILITY:${this.disability.id}:${this.date - 0}`;
  }
  get str() {
    return `DISABILITY END @${this.date}`;
  }
  apply(state) {
    if (this.date.equals(state.date)) {
      state.status = this.previous_status;
      state.removeTag(Tag.Disability);

      //if (state.show_logs) console.log(`%cCALC`, comp_stl, this.disability.span_ends, this.date, !state.future_events.find(e => e.constructor.name === 'RetirementBegin'), "future", state.future_events);
      if (
        (!this.disability.span_ends || this.disability.span_ends > this.date) &&
        !state.future_events.find(
          (e) => e.constructor.name === "RetirementBegin"
        )
      ) {
        new RetirementBegin({
          ...this.disability,
          start_date: this.date,
        }).insert(state);
      }
      this.disability.span_ends = this.date;
      //state.recordLabel({ date: this.date, series: "retirement_eligibility", labels: ['disability expired'], event: this, data: this.disability });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class BeneficiaryDesignate extends Event {
  get is_primary() {
    return true;
  }
  constructor(beneficiary) {
    super();
    this.beneficiary = beneficiary;
    this.date = new EventDate(beneficiary.designation_date);
    this.beneficiary.span_begins = new EventDate(beneficiary.designation_date);
    this.beneficiary.span_ends = beneficiary.removal_date
      ? new EventDate(beneficiary.removal_date)
      : null;
    this.beneficiary_name = ["first_name", "middle_name", "last_name"]
      .map((n) => this.beneficiary.beneficiary[n])
      .filter((n) => n !== null)
      .join(" ");

    this.beneficiary_dob = this.beneficiary?.beneficiary?.dob;
  }
  get id() {
    return `DESGBEN:${this.beneficiary.id}:${this.date - 0}: ${[
      "first_name",
      "middle_name",
      "last_name",
    ]
      .map((n) => this.beneficiary.beneficiary[n])
      .filter((n) => n !== null)
      .join(":")}`;
  }
  get str() {
    return `BENEFICIARY ADDED @${this.date} ${this.beneficiary_name}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      // FIXME: activities badness
      //state.beneficiaries.push(this.beneficiary);
      state.addActivity("beneficiary", this.beneficiary);

      if (this.beneficiary.removal_date) {
        new BeneficiaryRemove(this.beneficiary).insert(state);
      }

      state.recordLabel({
        date: this.date,
        series: "beneficiary",
        labels: [
          this.beneficiary_name,
          `${this.beneficiary.relationship.name}/${this.beneficiary.beneficiary_type.name}`,
        ],
        event: this,
        data: this.beneficiary,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class BeneficiaryRemove extends Event {
  get is_primary() {
    return true;
  }
  constructor(beneficiary) {
    super();
    this.beneficiary = beneficiary;
    this.date = new EventDate(beneficiary.removal_date);
    this.beneficiary_name = ["first_name", "middle_name", "last_name"]
      .map((n) => this.beneficiary[n])
      .filter((n) => n !== null)
      .join(" ");
  }
  get id() {
    return `REMVBEN:${this.beneficiary.id}:${this.date - 0}: ${[
      "first_name",
      "middle_name",
      "last_name",
    ]
      .map((n) => this.beneficiary.beneficiary[n])
      .filter((n) => n !== null)
      .join(":")}`;
  }
  get str() {
    return `BENEFICIARY REMOVED @${this.date} ${this.beneficiary_name}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      // FIXME: activities badness
      //state.beneficiaries = state.beneficiaries.filter(b => b.id != this.beneficiary.id);

      state.recordLabel({
        date: this.date,
        series: "beneficiary",
        labels: [`- ${this.beneficiary_name}`],
        event: this,
        data: this.beneficiary,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class GotBenefactor extends Event {
  get is_primary() {
    return true;
  }
  constructor(benefactor, dob) {
    super();
    this.benefactor = benefactor;
    this.date = new EventDate(benefactor.designation_date);
    if (dob && this.date < dob) {
      this.date = dob;
    }
    this.benefactor.span_begins = new EventDate(benefactor.designation_date);
    this.benefactor.span_ends = benefactor.removal_date
      ? new EventDate(benefactor.removal_date)
      : null;
    this.benefactor_name = ["first_name", "middle_name", "last_name"]
      .map((n) => this.benefactor.benefactor[n])
      .filter((n) => n !== null)
      .join(" ");
  }
  get id() {
    return `BECOMEBEN:${this.benefactor.id}:${this.date - 0}:${[
      "first_name",
      "middle_name",
      "last_name",
    ]
      .map((n) => this.benefactor.benefactor[n])
      .filter((n) => n !== null)
      .join(":")}`;
  }
  get str() {
    return `ADDED AS BENEFICIARY @${this.date} for ${this.benefactor_name}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      // FIXME: activities badness
      //state.benefactors.push(this.benefactor);
      state.addActivity("benefactor", this.benefactor);

      if (this.benefactor.removal_date) {
        new LostBenefactor(this.benefactor).insert(state);
      }

      state.addTag(this.id, Tag.Beneficiary);
      state.recordLabel({
        date: this.date,
        series: "benefactor",
        labels: [`designated as beneficiary for ${this.benefactor_name}`],
        event: this,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class LostBenefactor extends Event {
  get is_primary() {
    return true;
  }
  constructor(benefactor) {
    super();
    this.benefactor = benefactor;
    this.date = new EventDate(benefactor.removal_date);
    this.benefactor_name = ["first_name", "middle_name", "last_name"]
      .map((n) => this.benefactor[n])
      .filter((n) => n !== null)
      .join(" ");
  }
  get id() {
    return `STOPBEN:${this.benefactor.id}:${this.date - 0}:${[
      "first_name",
      "middle_name",
      "last_name",
    ]
      .map((n) => this.benefactor.benefactor[n])
      .filter((n) => n !== null)
      .join(":")}`;
  }
  get str() {
    return `REMOVED AS BENEFICIARY @${this.date} for ${this.benefactor_name}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      // FIXME: activities badness
      //state.benefactors = state.benefactors.filter(b => b.id != this.benefactor.id);

      //state.removeTag(Tag.Beneficiary);
      state.recordLabel({
        date: this.date,
        series: "benefactor",
        labels: [`removed as beneficiary of ${this.benefactor_name}`],
        event: this,
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationWarning extends Event {
  constructor(warning) {
    super();
    this.warning = warning;
    this.date = new EventDate(warning.date.split(" ")[0]);
    this.uuid = warning.uuid;
    const inf_regex = /suspension inferred/;
    if (!this.warning.code && inf_regex.test(warning.message)) {
      this.warning.code = "suspension inferred";
    }
    this.warning.code = warning.code ? warning.code : warning.message;
  }
  get id() {
    return `MIGRATION_WARNING:${this.uuid}`;
  }
  get str() {
    return `MIGRATION WARNING @${this.date}: [${this.warning.label}] ${this.warning.message}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      state.recordError({
        level: "migration",
        code: this.warning.code,
        evt: this,
        msgs: [this.warning.message, this.warning.extra],
      });
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationContribDateExpectation extends Event {
  constructor(expectation) {
    super();
    this.expectation = expectation;
    this.expected_date = new EventDate(expectation.date.split(" ")[0]);
    this.date = new EventDate(this.expected_date);
    this.date.add(1, "day"); // make sure we come in after the contributor event
    this.uuid = expectation.uuid;
  }
  get id() {
    return `EXPECT_CONTRIB_DATE:${this.date - 0}:${this.uuid}`;
  }
  get str() {
    return `EXPECTED CONTRIBUTOR STATUS @${this.date}: MET = ${
      this.met === undefined ? "unknown" : this.met ? "MET" : "*UNMET*"
    }`;
  }
  apply(state) {
    if (state.runpoint < this.date) {
      return false;
    }
    if (this.date.equals(state.date) || this.date <= state.date) {
      this.met = this.expected_date.equals(state.contributing);
      if (!this.met) {
        state.recordError({
          level: "migration",
          code: "contributor date mismatch",
          date: this.expected_date,
          evt: this,
          msgs: ["contrib date was here", this.expected_date.str],
          anachronism: true,
        });
      }
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationGroupCodeExpectation extends Event {
  constructor(active_group_code, migration_date) {
    super();
    this.expectation = active_group_code;
    this.date = new EventDate(migration_date.split("T")[0]);
    this.met = null;
    this.uuid = this.id;
  }
  get id() {
    return `EXPECT_GROUP:@${this.date - 0}:${this.expectation}`;
  }
  get str() {
    return `EXPECTED GROUP ${this.expectation} @${this.date}: MET = ${
      this.met === undefined ? "unknown" : this.met ? "MET" : "*UNMET*"
    }`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      this.met = state.computed_group === this.expectation;
      if (!this.met) {
        state.recordError({
          level: "migration",
          code: "active group expectation mismatch",
          evt: this,
          msgs: [
            `migrated group was ${this.expectation}`,
            `computed group is ${state.computed_group}`,
          ],
        });
      }
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationContribBalanceExpectation extends Event {
  constructor(expectation) {
    super();
    this.expectation = expectation;
    this.date = new EventDate(expectation.date.split(" ")[0]);
    //this.date.add(30, 'day'); // offset a little to distance from the associated contrib
    this.balance = expectation.value;
    this.actual_balance = null;
    this.uuid = expectation.uuid;
  }
  get id() {
    return `EXPECT:${this.uuid}:CONTRIB_BAL:${this.date - 0}:${this.balance}`;
  }
  get str() {
    return `EXPECT CONTRIB BALANCE @${this.date}: $${this.balance} <=> $${
      this.actual_balance
    } ${this.met ? "MET" : "*UNMET*"}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      this.actual_balance = state.contribution_balance;

      this.met = Math.abs(this.actual_balance - Number(this.balance)) < 0.01;
      if (!this.met) {
        state.recordError({
          level: "migration",
          code: "contribution balance mismatch",
          evt: this,
          msgs: [
            "contrib balance mismatch",
            `expected ${MONEY(this.balance)}, got ${MONEY(
              this.actual_balance
            )}`,
          ],
        });
      }
      //if (state.show_logs) console.warn(`%cCALC`, comp_stl, `off by ${ Math.abs(this.balance - this.actual_balance) }:: ${ this.balance } vs ${ state.service_hours } -${ state.ptd_service_hours }=${ this.actual_balance }`)
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationEmpCodeExpectation extends Event {
  constructor(emp) {
    super();
    this.emp = emp;
    this.date = new EventDate(emp.date.split(" ")[0]);
    //this.date.add(30, 'day'); // offset a little to distance from the associated contrib
    this.employment_id = emp.id;
    this.message = emp.value;
    this.uuid = emp.uuid;
  }
  get id() {
    return `EXPECT_EMP_CODE:${this.uuid}:${this.date - 0}:${
      this.employment_id
    }`;
  }
  get str() {
    return `EXPECT MANUAL EMP CODE CHANGE @${this.date}: ${
      this.employment_id
    } ${this.met ? "MET" : "*UNMET*"}`;
  }
  apply(state) {
    if (this.date.equals(state.date) || this.date <= state.date) {
      let emp = state.lastActivity("employment");
      if (!emp || emp.id !== this.employment_id) {
        this.date_insufficient += 1;
        state.pending_events.push(this);
        return false;
      }
      this.actual_code = emp.employer.code;

      this.met = this.actual_code !== "UNK";
      if (!this.met) {
        state.recordError({
          level: "migration",
          evt: this,
          code: "missing employer code",
          msgs: ["failed to guess employer", "set manually"],
        });
      }
      return true;
    }
    state.pending_events.push(this);
    return false;
  }
  get interesting() {
    return true;
  }
}

class MigrationComment extends Event {
  constructor(comment, migration_date) {
    super();
    this.comment = comment;
    this.date = new EventDate(migration_date.split("T")[0]);
    this.uuid = comment.uuid;
  }
  get id() {
    return `COMMENT:${this.uuid}`;
  }
  get str() {
    return `COMMENT: ${this.comment.message}`;
  }
  apply(state) {
    state.addTag(this.id, Tag.MigrationIncomplete);
  }
  get interesting() {
    return false;
  }
}

class SimpleTimeline {
  constructor(
    person,
    projection,
    host_build,
    db_factors,
    log,
    runpoint = new EventDate()
  ) {
    if (log) console.group("%cCALC", comp_stl, "modeling...");
    if (!person) {
      return;
    }
    this.person = person;
    this.projection = projection;
    this.db_factors = db_factors;

    this.begin = performance.now();

    let state = new State();
    this.runpoint = new EventDate(runpoint);

    this.show_logs = log;
    state.show_logs = log;

    state.runpoint = this.runpoint;
    state.db_factors = this.db_factors;
    state.dob = person.birth_date ? new EventDate(person.birth_date) : null;
    state.projection = projection;
    state.deceased = person.deceased_date
      ? new EventDate(person.deceased_date)
      : null;

    let assumptions = projection?.assumptions;
    if (assumptions?.calculation_fudges) {
      state.projection.use_fudges = true;
    }
    if (assumptions?.end_condition?.type) {
      switch (assumptions?.end_condition?.type) {
        case "years": {
          state.endpoint = new EventDate(
            state.runpoint,
            assumptions?.end_condition?.years,
            "year"
          );
          state.end_cond = (s) => s.date >= s.endpoint;
          let evt = new ComputeSimulationTotal(state.endpoint);
          evt.insert(state);
          break;
        }
        case "age": {
          let evt = new AgeEvent(state.dob, assumptions?.end_condition?.age);
          evt.insert(state);
          let evt_2 = new ComputeSimulationTotal(state.endpoint);
          evt_2.insert(state);
          state.endpoint = new EventDate(evt.date);
          state.end_cond = (s) => s.date >= evt.date;
          break;
        }
        case "ret": {
          state.endpoint = new EventDate(
            state.runpoint,
            assumptions?.end_condition?.age,
            "year"
          );
          switch (assumptions?.end_condition?.ret) {
            case "full":
              state.end_cond = (s) => s.full_ret_elig_date;
              break;
            case "early":
              state.end_cond = (s) => s.early_ret_elig_date;
              break;
            case "unreduced":
              state.end_cond = (s) => s.full_early_ret_elig_date;
              break;
          }
          break;
        }
        case "date": {
          state.endpoint = new EventDate(assumptions?.end_condition?.date);
          let evt = new ComputeSimulationTotal(state.endpoint);
          evt.insert(state);
          break;
        }
      }
    } else {
      state.endpoint = new EventDate(state.runpoint, 13);
    }
    if (assumptions?.salary?.type) {
      switch (assumptions?.salary?.type) {
        case "pct":
          state.projection.rate = Number(assumptions?.salary?.pct) / 100.0;
          break;
        case "census":
          state.projection.rate = 0;
          //eft stands for estimated future salary
          break;
        case "manual":
          state.projection.avg_salary = assumptions?.salary?.manual;
          break;
        case "history":
          state.projection.salaries = assumptions.salary.salaries;
          state.projection.avg_salary = undefined;
          state.projection.rate = 0;
          state.projection.history = true;
          break;
      }
    }

    if (assumptions?.beneficiary?.dob) {
      state.projection.beneficiary_dob = new EventDate(
        assumptions?.beneficiary?.dob
      );
    }
    if (assumptions?.contributions_est?.est) {
      state.projection.contribs = assumptions?.contributions_est?.est;
    }
    this.populate(state, this.person, projection);
    this.state = state;
  }
  run() {
    if (this.show_logs) console.log("%cCALC", comp_stl, "RUNNING");
    this?.state?.run();
    return this.makeOutput();
  }

  run_all() {
    this?.state?.run();
  }

  step() {
    return this?.state?.step();
  }

  makeOutput() {
    //if (this.show_logs) console.log(`%cCALC`, comp_stl, this.state.nodes.map(n => n.snapshot()))
    if (this.show_logs)
      console.log(
        "%cCALC",
        comp_stl,
        "COMPUTATION REPORT",
        this?.state?.present_day
          ? this.state.present_day.report
          : this?.state?.report
      );
    if (this.show_logs) console.groupEnd();
    //console.log(`STATE: ${JSON.stringify(this.state,null,2)}`)
    this.output = {
      debug_capture: {
        runpoint: this.state?.runpoint,
        person: sanitizePII(this.person),
        projection: this.projection,
        target_snapshot: this.state?.snapshots.find(
          (s) => s.phase === "target"
        ),
        db_factors: this.db_factors,
      },
      person_id: this.person.id,
      expires: this?.state?.expires,
      state: this?.state?.present_day?.snapshot || this?.state?.snapshot(),
      contexts: [...this?.state?.contexts],
      report: this?.state?.present_day
        ? this?.state?.present_day.report
        : this?.state?.report,
      next_year: this?.state?.next_year
        ? this?.state?.next_year?.snapshot
        : this?.state?.snapshot(),
      future_state: this.projection
        ? this?.state?.target_date?.snapshot
        : this?.state?.snapshot(),
      future_report: this.projection
        ? this?.state?.target_date?.report
        : this?.state?.report,
      series_nodes: this?.state?.series_nodes
        .filter(
          (n) =>
            (n.date && n.date <= this?.state?.endpoint) ||
            (n.start && n.start <= this?.state?.endpoint)
        )
        .map((n) => n.snapshot())
        .map((n) =>
          n.end && n.end > this?.state?.endpoint ? { ...n, end: null } : n
        ),
      future_series_nodes: null,
      nodes: this?.state?.nodes
        .filter(
          (n) =>
            (n.date && n.date <= this.state?.endpoint) ||
            (n.start && n.start <= this?.state?.endpoint) ||
            n.edit_data
        )
        .map((n) => n.snapshot())
        .map((n) =>
          n.end && n.end > this?.state?.endpoint ? { ...n, end: null } : n
        ),
      //nodes: this.state.nodes.filter(n => n.date <= this.state.runpoint).map(n => n.snapshot()),
      future_nodes: this?.state?.nodes
        .filter((n) => n.date > this?.state?.runpoint)
        .map((n) => n.snapshot()),
      errors: this?.state?.errors,
      now: this?.state?.runpoint,
      runpoint: this?.state?.runpoint,
      endpoint: this?.state?.endpoint,
      projection: this?.state?.projection,
      service_logs: this?.state?.service_logs,
      dates: this?.state?.snapshots.map((s, i, l) => ({
        phase: s.phase,
        date: s.date,
        next_date:
          s.phase === "pre"
            ? l[i + 2]
              ? l[i + 2].date
              : null
            : l[i + 1]
            ? l[i + 1].date
            : null,
        state: s,
      })),
      years: this?.state?.years
        .filter(({ year }) => year <= this.state.runpoint.getFullYear())
        .reduce((o, { year, state }) => ({ ...o, [year]: state }), {}),
      compute_time: performance.now(), //- this?.begin,
      performance: {
        time_by_class: this?.state?.event_performance?.reduce((acc, evt) => {
          if (!acc[evt.event_name]) acc[evt.event_name] = 0;
          acc[evt.event_name] += evt.time;
          return acc;
        }, {}),
        freq_by_class: this?.state?.event_performance?.reduce((acc, evt) => {
          if (!acc[evt.event_name]) acc[evt.event_name] = 0;
          acc[evt.event_name] += 1;
          return acc;
        }, {}),
        applied_time_by_class: this?.state?.event_performance
          ?.filter((e) => e.applied)
          .reduce((acc, evt) => {
            if (!acc[evt.event_name]) acc[evt.event_name] = 0;
            acc[evt.event_name] += evt.time;
            return acc;
          }, {}),
        applied_freq_by_class: this?.state?.event_performance
          ?.filter((e) => e.applied)
          .reduce((acc, evt) => {
            if (!acc[evt.event_name]) acc[evt.event_name] = 0;
            acc[evt.event_name] += 1;
            return acc;
          }, {}),
        not_applied_time_by_class: this?.state?.event_performance
          ?.filter((e) => !e.applied)
          .reduce((acc, evt) => {
            if (!acc[evt.event_name]) acc[evt.event_name] = 0;
            acc[evt.event_name] += evt.time;
            return acc;
          }, {}),
        not_applied_freq_by_class: this?.state?.event_performance
          ?.filter((e) => !e.applied)
          .reduce((acc, evt) => {
            if (!acc[evt.event_name]) acc[evt.event_name] = 0;
            acc[evt.event_name] += 1;
            return acc;
          }, {}),
      },
    };
    return this.output;
  }

  populate(state, person, projection) {
    state.person_id = person.id;
    state.name = `${person.first_name} ${person.last_name} `;

    state.parameters = { max_compensation: 265000 };

    let first_employed = null;

    if (person.employments || projection?.changes?.employment) {
      person.employments?.forEach((emp) => {
        let changes = projection?.changes?.employment?.find(
          (e) => e.id === emp.id
        );
        if (changes) {
          emp = { ...emp, ...changes, projected: changes };
          changes.used = true;
        }
        new EmploymentBegin(emp).insert(state);
        let start = new EventDate(emp?.start_date);
        if (!first_employed || start < first_employed) {
          first_employed = start;
        }
      });
      projection?.changes?.employment
        ?.filter?.((e) => !e.used)
        ?.forEach?.((e) => {
          new EmploymentBegin({ ...e, __typename: "employment" }).insert(state);
          let start = new EventDate(e?.start_date);
          if (!first_employed || start < first_employed) {
            first_employed = start;
          }
        });
    }

    // Multipliers that determine early retirement multipliers. Age increased from 55 to 60.
    // Updated 12/1/2023
    // FIXME: should be in a table
    const multipliers = [
      {
        effective: null,
        multiplier: 0.025,
        full_conditions: [
          { age: 55, vesting: 20 },
          { age: 65, vesting: 0 },
        ],
      },
      {
        effective: new EventDate("2001-01-01"),
        multiplier: 0.0275,
        full_conditions: [
          { age: 55, vesting: 20 },
          { age: 65, vesting: 0 },
        ],
      },
      {
        effective: new EventDate("2014-01-01"),
        multiplier: 0.024,
        full_conditions: [
          { age: 55, vesting: 20 },
          { age: 65, vesting: 0 },
        ],
      },
      {
        effective: new EventDate("2020-01-01"),
        multiplier: 0.022,
        full_conditions: [
          { age: 60, vesting: 20 },
          { age: 65, vesting: 0 },
        ],
      },
      {
        effective: new EventDate("2021-01-01"),
        multiplier: 0.021,
        full_conditions: [
          { age: 60, vesting: 20 },
          { age: 65, vesting: 0 },
        ],
      },
    ]
      .map((m, i, l) => ({
        ...m,
        ends: l?.[i + 1]?.effective,
      }))
      .filter((m) => (first_employed && m.ends >= first_employed) || !m.ends);

    multipliers.forEach((m) => {
      new PensionMultiplier(m).insert(state);
    });
    multipliers
      .filter((m) => m.effective !== null)
      .forEach((m) => {
        new MultiplierRollover(m).insert(state);
      });

    if (person.salary_history) {
      person.salary_history?.forEach((sal) => {
        // FIXME: overlaps with RealSalary
        new SalaryHistoryPoint(sal).insert(state);
      });
    }

    if (person.suspensions || projection?.changes?.suspension) {
      person.suspensions.forEach((susp) => {
        let changes = projection?.changes?.suspension?.find(
          (e) => e.id === susp.id
        );
        if (changes) {
          susp = { ...susp, ...changes, projected: changes };
          changes.used = true;
        }
        new SuspensionBegin(susp).insert(state);
      });
      projection?.changes?.suspension
        ?.filter((e) => !e.used)
        .forEach((e) => new SuspensionBegin(e).insert(state));
    }
    if (person.benefits || projection?.changes?.benefit) {
      let add_ret = (ret) => {
        //FIXME: handle disability and death benefit events
        switch (ret.benefit_type.code) {
          case "RET":
            new RetirementBegin(ret).insert(state);
            break;
          case "DIS":
            new DisabilityBegin(ret).insert(state);
            break;
          case "DEATH":
            if (state.show_logs)
              console.warn("%cCALC", comp_stl, "CREATING DEATH BEN", ret);
            new DeathBenBegin(ret).insert(state);
            break;
          default:
            if (state.show_logs)
              console.warn("%cCALC", comp_stl, "CREATING GENERIC BEN", ret);
            new RetirementBegin(ret).insert(state);
        }
      };
      person.benefits.forEach((ret) => {
        let changes = projection?.changes?.benefit?.find(
          (e) => e.id === ret.id
        );
        if (changes) {
          ret = { ...ret, ...changes, projected: changes };
          changes.used = true;
        }
        add_ret(ret);
      });
      projection?.changes?.benefit
        ?.filter((r) => !r.used)
        .forEach((r) => add_ret(r));
    }
    if (person.contributions || projection?.changes?.contribution) {
      person.contributions.forEach((contrib) => {
        let changes = projection?.changes?.contribution?.find(
          (e) => e.id === contrib.id
        );
        if (changes) {
          contrib = { ...contrib, ...changes, projected: changes };
          changes.used = true;
        }
        new Contribution(contrib).insert(state);
        state.raw_contribution(contrib);
      });
      projection?.changes?.contribution
        ?.filter((c) => !c.used)
        .forEach((c) => {
          new Contribution(c).insert(state);
          state.raw_contribution(c);
        });
    }
    if (person.benefits_paid || projection?.changes?.benefit_payment) {
      person.benefits_paid.forEach((payment) => {
        let changes = projection?.changes?.benefit_payment?.find(
          (e) => e.id === payment.id
        );
        if (changes) {
          payment = { ...payment, ...changes, projected: changes };
          changes.used = true;
        }
        new BenefitPaid(payment).insert(state);
      });
      projection?.changes?.benefit_payment
        ?.filter((e) => !e.used)
        .forEach((e) => new BenefitPaid(e).insert(state));
    }
    if (person.adjustments || projection?.changes?.service_adjustment) {
      person.adjustments.forEach((adj) => {
        let changes = projection?.changes?.service_adjustment?.find(
          (e) => e.id === adj.id
        );
        if (changes) {
          adj = { ...adj, ...changes, projected: changes };
          changes.used = true;
        }
        new ServiceAdjustment(adj).insert(state);
      });
      projection?.changes?.service_adjustment
        ?.filter((e) => !e.used)
        .forEach((e) => new ServiceAdjustment(e).insert(state));
    }

    if (person.withdrawals || projection?.changes?.withdrawal) {
      person.withdrawals.forEach((wdraw) => {
        let changes = projection?.changes?.withdrawal?.find(
          (e) => e.id === wdraw.id
        );
        if (changes) {
          wdraw = { ...wdraw, ...changes, projected: changes };
          changes.used = true;
        }
        new Withdrawal(wdraw).insert(state);
      });
      projection?.changes?.withdrawal
        ?.filter((e) => !e.used)
        .forEach((e) => new Withdrawal(e).insert(state));
    }

    if (
      person.withdrawal_repayments ||
      projection?.changes?.withdrawal_repayment
    ) {
      person.withdrawal_repayments.forEach((repay) => {
        let changes = projection?.changes?.withdrawal_repayment?.find(
          (e) => e.id === repay.id
        );
        if (changes) {
          repay = { ...repay, ...changes, projected: changes };
          changes.used = true;
        }
        new WithdrawalRepayment(repay).insert(state);
      });
      projection?.changes?.withdrawal_repayment
        ?.filter((e) => !e.used)
        .forEach((e) => new WithdrawalRepayment(e).insert(state));
    }
    if (
      person.participation_changes ||
      projection?.changes?.participation_change
    ) {
      let add_pc = (pc) => {
        switch (pc.change_type.code) {
          case "OUT":
            new ParticipationOptOut(pc).insert(state);
            break;
          case "RTN":
            new ParticipationReturn(pc).insert(state);
            break;
        }
      };

      person.participation_changes.forEach((pc) => {
        let changes = projection?.changes?.participation_change?.find(
          (e) => e.id === pc.id
        );
        if (changes) {
          pc = { ...pc, ...changes, projected: changes };
          changes.used = true;
        }
        add_pc(pc);
      });
      projection?.changes?.participation_changes
        ?.filter((e) => !e.used)
        .forEach((e) => add_pc(e));
    }

    if (person.beneficiaries || projection?.changes?.beneficiary) {
      person.beneficiaries.forEach((benf) => {
        let changes = projection?.changes?.beneficiary?.find(
          (e) => e.id === benf.id
        );
        if (changes) {
          benf = { ...benf, ...changes, projected: changes };
          changes.used = true;
        }
        new BeneficiaryDesignate(benf).insert(state);
      });
      projection?.changes?.beneficiary
        ?.filter((e) => !e.used)
        .forEach((e) => new BeneficiaryDesignate(e).insert(state));
    }
    if (person.benefactors || projection?.changes?.benefactor) {
      person.benefactors.forEach((benf) => {
        let changes = projection?.changes?.benefactor?.find(
          (e) => e.id === benf.id
        );
        if (changes) {
          benf = { ...benf, ...changes, projected: changes };
        }
        new GotBenefactor(benf, state.dob).insert(state);
      });
      projection?.changes?.benefactor
        ?.filter((e) => !e.used)
        .forEach((e) => new GotBenefactor(e, state.dob).insert(state));
    }

    if (person.salary_history || projection?.changes?.salary_history) {
      person.salary_history.forEach((sal) => {
        let changes = projection?.changes?.salary_history?.find(
          (e) => e.id === sal.id
        );
        if (changes) {
          sal = { ...sal, ...changes, projected: changes };
        }
        state.realSalaryChange(sal);
      });
      projection?.changes?.salary_history
        ?.filter((sal) => !sal.used)
        ?.forEach((sal) => state.realSalaryChange(sal));
    }

    if (person.migrations) {
      person.migrations.forEach((migr) => {
        let pension_benefit = migr?.original_data?.benefit
          ? migr.original_data.benefit.find(
              (b) => b.benefitname.code === "Pension"
            )
          : null;
        let pensions = pension_benefit?.pension ? pension_benefit.pension : [];
        let active_groups = [];
        pensions.forEach((p) => {
          if (!p.pension_group) return;
          active_groups = [
            ...p.pension_group.filter((g) => g.status.code === "A"),
          ];
        });
        if (active_groups.length > 1) {
          console.error(
            "%cCALC",
            comp_stl,
            person.id,
            "WEIRD: MORE THAN ONE ACTIVE GROUP",
            active_groups
          );
          state.recordError({
            level: "migration",
            code: "more than one active group",
            evt: this,
            msgs: [
              "more than one active group",
              `${active_groups.map((g) => g.group.code).join(",")}`,
            ],
          });
        }

        let fixed = migr.fixed ? migr.fixed : {};

        active_groups.forEach((g) => {
          let exp = new MigrationGroupCodeExpectation(
            g.group.code,
            migr.import_date
          );
          if (fixed[exp.uuid]) return;
          exp.insert(state);
        });

        if ("emp_code" in migr.expectations)
          migr.expectations.emp_code.forEach((emp_code) => {
            if (fixed[emp_code.uuid]) return;
            new MigrationEmpCodeExpectation(emp_code).insert(state);
          });
        if ("has_tag" in migr.expectations)
          migr.expectations.has_tag.forEach((tag) => {
            if (fixed[tag.uuid]) return;
            new MigrationTagExpectation(tag, migr.import_date).insert(state);
          });
        if ("does_not_have_tag" in migr.expectations)
          migr.expectations.does_not_have_tag.forEach((tag) => {
            if (fixed[tag.uuid]) return;
            new MigrationTagExpectation(tag, migr.import_date, true).insert(
              state
            );
          });
        //if ('pension_contribution_balance' in migr.expectations) migr.expectations.pension_contribution_balance.forEach(bal => {
        //    new MigrationContribBalanceExpectation(bal).insert(state);
        //});
        if ("pension_service_total_hours" in migr.expectations)
          migr.expectations.pension_service_total_hours.forEach((svc) => {
            //if (fixed[svc.uuid]) return;
            new MigrationServiceHourBalanceExpectation(
              svc,
              fixed[svc.uuid]
            ).insert(state);
          });
        if ("withdrawal_amount" in migr.expectations)
          migr.expectations.withdrawal_amount.forEach((amt) => {
            //if (state.show_logs) console.warn(`%cCALC`, comp_stl, amt)
            if (fixed[amt.uuid]) return;
            new MigrationWithdrawalAmountExpectation(amt).insert(state);
          });
        if ("contrib_date" in migr.expectations)
          migr.expectations.contrib_date.forEach((ctrb) => {
            if (fixed[ctrb.uuid]) return;
            new MigrationContribDateExpectation(ctrb).insert(state);
          });
        if (migr.warnings)
          migr.warnings
            .filter((w) => w.date && w.date !== null)
            .forEach((warn) => {
              if (fixed[warn.uuid]) return;
              new MigrationWarning(warn).insert(state);
            });
        if (migr.warnings)
          migr.warnings
            .filter((w) => w.date === null)
            .forEach((comment) => {
              if (fixed[comment.uuid]) return;
              new MigrationComment(comment, migr.import_date).insert(state);
            });
      });
    }
    new AgeEvent(state.dob, 65).insert(state);
    new AgeEvent(state.dob, 55).insert(state);
    new AgeEvent(state.dob, 70.5).insert(state);
    new RequiredBeginning(state.dob).insert(state);
    new ContributionDeficiency().insert(state);

    state.recordLabel({
      date: state.runpoint,
      series: "present",
      labels: ["present"],
      anachronism: true,
    });
    if (state.projection)
      state.recordLabel({
        date: state.endpoint,
        series: "present",
        labels: [`${state.endpoint}`],
        anachronism: true,
      });
    if (state.deceased) {
      new DeceasedEvent(state.deceased, state.dob).insert(state);
    }
  }
}

//let internal_build = "local";
export let internal_build = 1;

export { SimpleTimeline };

/* FIXME: RETURN TO WORK AFTER RET

!! INTEREST: 5% on prior year, prorated to date of view (someone termed 10 years ago would be collecting interest, even though no contribs)

If you retire and begin receiving your monthly pension payments under the Pension Plan,
and you then return to work for AFSCME or a Participating Affiliate as an eligible
employee, the following rules will apply:
 If you work for fewer than 40 hours per month, your pension payments will
continue to be paid.

 If you work for more than 40 hours per month, your pension payments will stop
and will be “suspended” while you continue to work. You will not receive any
pension payments while you are employed, and your pension payments will begin
29again after you stop working. Also, the amount of your monthly pension
payments, when they begin again, will not be adjusted to take into account the
value of the “missed” payments during your employment past age 65. You will
receive a notice about any such suspension applicable to you due to your
continued employment.

 You will begin to participate in the Pension Plan again immediately as of the date
of reemployment. When you later stop working and your pension payments begin
again, your pension will be recalculated based on your total Years of Credited
Service and your Average Final Compensation as of the date your payments
begin again, and your monthly payments will be adjusted to take into account the
benefit payments you received before you were reemployed.

If you continue working past the April 1 of the calendar year following the year in
which you attain age 70 1⁄2, the suspension referred to above will no longer apply,
and your benefit payments, after you retire again, will be adjusted to take into
account the greater of (1) the value of the “missed” payments during your
continued employment after that April 1, or (2) the additional benefit you earn
during that period of continued employment.

FIXME: DISABILITY
To be eligible for a disability benefit, a participant must:
 Become disabled while employed by AFSCME or a Participating Affiliate;
 Be credited with at least ten(10) Years of Credited Service; and

When a participant receiving disability benefits attains age 65, disability payments cease,
		and the participant is entitled to apply to receive his or her pension benefit, subject to all
the rules applicable to such applications.In addition, the following rules apply to a
participant receiving disability benefits:
1. Additional Years of Service and Years of Credited Service are credited to a
disabled participant who is receiving disability benefits under the Pension Plan, up
to the date on which he or she becomes eligible to receive an unreduced normal
retirement benefit.
311. The maximum number of additional Years of Credited Service for which a
participant may receive credit while receiving disability benefits is 20 Years of
Credited Service.
2. The additional Years of Service and Years of Credited Service credited while a
participant is disabled are counted in determining the participant’s pension benefit
at age 65. A participant’s Average Final Compensation, for purposes of the
participant’s pension benefit, will be determined based on the participant’s
Compensation as of the date the disability began.
3. A participant receiving disability benefits is not required to make participant
contributions.

		NOTES: Possibly model this as a special kind of employment - it has many of the same characteristics
				*/
