import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { MONEY } from "../pension-timeline-logic.js";

export class MigrationWithdrawalAmountExpectation extends Event {
    constructor(expectation) {
        super();
        this.expectation = expectation;
        this.withdrawal_date = new EventDate(expectation.date.split(' ')[0]);
        this.date = this.withdrawal_date; //new EventDate(this.withdrawal_date, 1, 'day');

        //this.date.add(20, 'day');
        this.withdrawal_amount = Number(expectation.value);
        this.uuid = expectation.uuid;
        this.withdrawal_id = expectation.id;
        this.met = false;
        this.actual_wdraw_amt = 0;
    }
    get id() { return `EXPECT_WDRAW_AMT:${this.uuid}:${this.date - 0}:${this.withdrawal_amount}`; }
    get str() {
        return `EXPECT WDRAW AMT @${this.date}: ${this.withdrawal_amount} <=> ${this.actual_wdraw_amt} ${this.met ? 'MET' : '*UNMET*'}`;
    }
    apply(state) {
        if (this.date.equals(state.date) || this.date <= state.date) {
            let candidates = state.anyActivity('withdrawal').sort((a, b) => Math.abs(this.date - a.date) - Math.abs(this.date - b.date));
            let withdrawal = candidates.length > 0 ? candidates[0] : null; //state.anyActivity('withdrawal').find(w => w.withdrawal && w.withdrawal.id === this.withdrawal_id);
            if (withdrawal) {
                this.actual_wdraw_amt = withdrawal.pred_total;
                let diff = this.actual_wdraw_amt - this.withdrawal_amount;
                this.met = Math.abs(diff) < 0.01;

                if (!this.met) {
                    state.recordError({
                        level: 'migration', evt: this,
                        code: "withdrawal amount mismatch",
                        msgs: [`withdrew ${MONEY(Math.abs(diff))} ${diff > 0 ? 'more' : 'less'} than expected`,
                        `expected ${MONEY(this.withdrawal_amount)}; 
                        got ${MONEY(this.actual_wdraw_amt)}`]
                    });
                }
                return true;
            } else {
                this.date_insufficient += 1;
                if (state.date > this.date) {
                    state.recordError({ level: 'migration', code: "withdrawal missing", evt: this, msgs: ["expected a withdrawal here", `expected withdrawal_id: ${this.withdrawal_id}`] });
                    return false;
                }
            }
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
