import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { Tag, MONEY } from "../pension-timeline-logic.js";

export class BenefitPaid extends Event {
    get is_primary() { return true; }
    constructor(payment) {
        super();
        this.payment = payment;
        this.date = new EventDate(payment.payment_date);
    }
    get id() { return `BENEFITPAID:${this.payment.id}:${this.date - 0}:${this.payment.net_amount}`; }
    get str() {
        return `BENEFIT @${this.date} net: $${this.payment.net_amount} payee: ${this.payment.payee.first_name} ${this.payment.payee.last_name}`;
    }

    /*
if (state.last_contribution) {
                let years = state.last_contribution.yearsUntil(this.date);
                state.contribution_balance = state.contribution_balance * (1 + 0.05 * years);
                state.contribution_interest = state.contribution_balance * (0.05 * years);
            }
            state.contribution_balance += this.contribution.ee_pension_contribution;// + this.contribution.interest;
            state.last_contribution = this.date; // TODO: do we need to know what period the contribution covers?
            //FIXME: raise error if balance dropped without a withdrawal
 
            state.salaries.push({
                year: this.date.getFullYear(),
                salary: this.contribution.eoy_base_salary,
            });
   if (
                [State.Employed, State.EmployedPartTime].includes(state.status) &&
                state.contributing
            ) {
                // We should expect another contribution in a year or so
                let deficient = new ContributionDeficiency(state.last_contribution, this.str);
                if (deficient.date <= state.runpoint) state.pending_events.push(deficient);
            }
            if (
                [State.Retired, State.EarlyRetired, State.FullEarlyRetired, State.FullRetired, State.Terminated, State.Unemployed, State.Deceased].includes(state.status) || (state.status === State.Suspended && state.lastActivity('suspension').suspension_type.paid)
            ) {
                // This is weird - we shouldn't be getting contributions
                state.recordError({this, "contribution w/o employment"});
            }
            */
    apply(state) {
        if (state.date.equals(this.date)) { //FIXME: check for active retirement/disability/etc
            state.payments += this.payment.net_amount;
            state.addTag(this.id, Tag.BenefitsPaid);
            state.recordSeries({
                date: this.date,
                series: "payment",
                brief_text: `paid ${MONEY(this.payment.net_amount)}`,
                labels: [`${this.payment.lump_sum ? 'lump_sum' : 'annuity'} payment${this.payment.lump_sum ? '' : 's'}`,
                `${MONEY(state.payments)}`],
                event: this,
                data: this.payment
            });
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
