import { Event } from "./Event.js";

export class PensionMultiplier extends Event {
    get str() { return `MULTIPLIER ${this.multiplier.multiplier * 100}% @${this.multiplier.effective_date}`; }
    get id() { return `MULTIPLIER:${this.multiplier.multiplier * 100}:${this.multiplier.effective_date}`; }
    constructor(m) {
        super();
        this.multiplier = m;
        m.credited_years = 0;
        m.raw_years = 0;
        m.credits = [];
    }
    insert(state) {
        state.multipliers.push(this.multiplier);
    }
}
