import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { comp_stl, MONEY, round2 } from "../pension-timeline-logic.js";

export class WithdrawalRepayment extends Event {
    get is_primary() { return true; }
    get id() { return `REPAYMENT:${this.repayment.id}:${this.date - 0}:${this.repayment.total}+${this.repayment.interest}`; }
    get str() { return `REPAYMENT @${this.date} $${this.repayment.total}+${this.repayment.interest} `; }

    constructor(repayment) {
        super();
        this.repayment = repayment;
        this.date = new EventDate(repayment.repayment_date);
        this.total = repayment.total;
        this.interest = repayment.interest;
    }

    apply(state) {
        if (state.date.equals(this.date)) {
            let withdrawals = [...state.anyActiveActivity('withdrawal')].sort((a, b) => b.date - a.date);
            if (state.show_logs) console.log("%cCALC", comp_stl, "AVAILABLE WDRAWALS", withdrawals, state);
            if (state.show_logs) console.log("%cCALC", comp_stl, `repayment: ${this.total}+${this.interest} = ${this.total + this.interest}`);

            let repaid = [];
            let unpaid = [];
            if (state.projection?.use_fudges) {
                if (state.show_logs) console.warn("%cCALC", comp_stl, "FUDGING REPAYMENT");
                repaid = withdrawals;
                withdrawals = [];
            }
            let total = 0; //`this.total;
            let interest = 0; //this.interest

            // push all the withdrawals which we have enough to repay
            let next = withdrawals.shift();

            let available = this.total + this.interest;

            //while (next && total <= this.total && interest <= this.interest) {
            while (next && total + interest <= available) {
                next.back_interest = next.backInterestThrough(state.date);
                total += next.total;
                interest += Math.round(100 * next.back_interest) / 100;
                //if (total <= this.total && interest <= this.interest || ) {
                if (state.show_logs) console.log("%cCALC", comp_stl, `trying to repay [${next.total}+${next.back_interest} (${total + interest})] with ${available}`);
                if (total + interest <= available) {
                    repaid.push(next);
                } else {
                    unpaid.push(next);
                }
                next = withdrawals.shift();
            }
            this.covered_withdrawals = repaid;

            const count = this.covered_withdrawals.length;
            const used_t = MONEY(total);
            const used_int_t = MONEY(interest);
            let unused = this.total - total;
            let unused_int = this.interest - interest;
            if (unused_int < 0) {
                unused += unused_int;
                unused_int = 0;
            }
            const unused_t = MONEY(unused);
            const unused_int_t = MONEY(unused_int);

            if (state.show_logs) console.log("%cCALC", comp_stl, "REPAYMENT", repaid, count, used_t, used_int_t, unused, unused_int);
            if (state.show_logs) console.log("%cCALC", comp_stl, "UNPAID", unpaid);

            //state.contribution_balance += this.total + this.interest;
            let { total_contribs, total_int } = state.contribs.filter(c => c.total_contribs > 0).reduce((tots, ctr) => ({ total_contribs: tots.total_contribs + ctr.amt, total_int: tots.total_int + ctr.interest }), { total_contribs: this.total, total_int: this.interest });
            total_contribs = round2(total_contribs);
            total_int = round2(total_int);

            //let total_contribs = round2(state.contribs.reduce((tot, ctr) => tot + ctr.amt, this.total));
            //let total_int = round2(state.contribs.reduce((tot, ctr) => tot + ctr.interest, this.interest));
            state.contribution_balance = round2(state.contribution_balance + this.interest + this.total);
            state.contribution_interest += this.interest;
            state.ytd_deposited_interest += round2(this.interest);


            state.push_contrib({
                type: 'repayment',
                is_repayment: true,
                contribution_id: null,
                repayment_id: this.repayment.id,
                contribution_date: this.date,
                date: this.date,
                years_since_last: 0,
                amt: this.total,
                auto_int: false,
                canceled: 0,
                interest: this.interest,
                total_contribs: total_contribs,
                total_interest: total_int,
                balance: state.contribution_balance
            });

            let lbls = [count > 1 ? `repaid ${count} withdrawals totalling ${used_t}` : `repaid ${MONEY(this.total)}`, `+${MONEY(this.interest)} interest`];
            if (total + interest < this.total + this.interest) lbls.push(`[surplus: ${unused_t} +${unused_int_t}]`);

            if (state.show_logs) console.log("%cCALC", comp_stl, "CLEARING PERM WITHDRAWALS", this.covered_withdrawals);
            this.covered_withdrawals.forEach(w => state.clearPermanentWithdrawal(w.id, w.withdrawal.id));
            state.recordLabel({ date: state.date, series: "withdrawal", labels: lbls, event: this, data: this.repayment });
            state.announce("repayment", this);

            if (this.covered_withdrawals.length === 0) {
                // TODO: check with bfits to see if they want this as alert or error (first make sure we're not throwing it too often)
                if (unpaid[0]) {
                    state.recordError({
                        level: 'alert',
                        evt: this,
                        code: "repayment insufficent",
                        msgs: ["repayment insufficient",
                            `expecting ${MONEY(unpaid[0].total)} + ${MONEY(unpaid[0].back_interest)} interest`]
                    });

                } else {
                    let expired = [...state.anyActivity('withdrawal')].filter(w => w.span_ends < state.date);
                    if (expired.length > 0) {
                        state.recordError({
                            evt: this,
                            code: "repayment w/o withdrawal",
                            msgs: ["late repayment"]
                        });
                    } else {
                        state.recordError({
                            evt: this,
                            code: "repayment w/o withdrawal",
                            msgs: ["no withdrawal to repay"]
                        });
                    }
                    return true;
                }
            }
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
}
