import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { Tag, comp_stl } from "../pension-timeline-logic.js";

export class MigrationTagExpectation extends Event {
    constructor(tag_expectation, migration_date, negative = false) {
        super();
        this.expectation = tag_expectation;
        this.negative = negative;
        this.date = new EventDate(migration_date.split('T')[0]);
        this.tag_text = tag_expectation.value;
        this.tag = Symbol.for(tag_expectation.value);
        this.tag_name = Object.keys(Tag).find(n => Tag[n] === this.tag);
        if (!this.tag_name) console.error("%cCALC", comp_stl, "unable to find name for", this.tag_text);
        this.met = null;
        this.uuid = tag_expectation.uuid;
    }
    get id() { return `EXPECT:${this.uuid}:${this.negative ? 'MISSING_' : ''} TAG:${this.date - 0}:${this.tag_text}`; }
    get str() {
        return `EXPECTED ${this.negative ? 'MISSING_' : ''} TAG ${this.tag_text} @${this.date}: MET = ${this.met === undefined ? 'unknown' : this.met ? 'MET' : '*UNMET*'}`;
    }
    apply(state) {
        if (this.date.equals(state.date) || this.date <= state.date) {

            this.met = this.negative ? !state.tags.has(this.tag) : state.tags.has(this.tag);
            if (!this.met) {
                state.recordError({ level: "migration", code: "tag expectation mismatch", evt: this, msgs: [this.negative ? `should not be [${this.tag_name}]` : `should be [${this.tag_name}]`] });
            }
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
