import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";

export class MigrationServiceHourBalanceExpectation extends Event {
    constructor(expectation, accepted) {
        super();
        this.expectation = expectation;
        this.accepted = accepted;
        this.original_date = new EventDate(expectation.date.split(' ')[0]);
        //this.date.add(20, 'day');
        this.migrated_hours = Number(expectation.value);
        this.computed_hours = null;
        this.uuid = expectation.uuid;
    }
    get id() { return `EXPECT_SVCHR_BAL:${this.uuid}:${this.date - 0}:${this.migrated_hours}`; }
    get str() { return `EXPECT SVC HR BALANCE @${this.date}: WANT=${this.migrated_hours}, GOT=${this.computed_hours} ${this.met ? 'MET' : '*UNMET*'}`; }

    insert(state) {
        this.sub(state);
    }

    sub(state) {
        if (this._sub) {
            state.unsubscribe(this._sub);
            this._sub = null;
        }
        this._sub = state.subscribe('basis_change', (s, b) => this.basisChange(s, b));
    }


    basisChange(state, basis) {
        if (basis && this.basis !== basis && !this.applied) {
            this.basis = basis;
            let months = this.basis.monthsUntil(this.original_date);
            this.possibles = [
                new EventDate(this.basis, Math.floor(months) - (3 / 30)),
                new EventDate(this.basis, Math.floor(months) + (3 / 30)),
                new EventDate(this.basis, Math.ceil(months) - (3 / 30)),
                new EventDate(this.basis, Math.ceil(months) + (3 / 30)),
            ];
            this.date = this.possibles.shift();
            //DEBUG_HOURS(0,`[BASIS CHANGE@${state.date}]:: (${this.str}@${state.date}): adjusting date ${this.original_date} => ${this.date}`)
            if (!this.pushed) {
                state.deferred_events.push(this);
                this.pushed = true;
            }
            return true;
        }
    }
    finalize(state) {
        this.applied = true;
        if (!this.met) {
            //DEBUG_HOURS(3, `${this.str}@${state.date}:  originally ${this.original_date}`);
            let diff = this.computed_hours - this.migrated_hours;
            if (state.service_hour_mismatch !== diff) {
                let increase = diff - state.service_hour_mismatch;
                let widened = Math.abs(diff) > Math.abs(state.service_hour_mismatch);
                //DEBUG_HOURS(1, this.str, `diff: ${diff} prior: ${state.service_hour_mismatch} inc: ${increase}  inc>0: ${increase > 0} == gap ${increase > 0 ? 'widened' : 'reduced'} :: ${widened}`)
                //FIXME: probably want a zero crossing check in here, if we go directly from e.g. 'X additional missing' to 'Y additional extra'
                const line1 = state.service_hour_mismatch !== 0 ? `${Math.abs(increase).toLocaleString()} ${widened ? 'additional' : 'fewer'} ${diff > 0 ? 'surplus' : 'missing'} svc hours` : `${Math.abs(diff).toLocaleString()} ${diff > 0 ? 'surplus' : 'missing'} svc hours`;
                const line2 = `computed ${this.computed_hours.toLocaleString()} - migrated ${this.migrated_hours.toLocaleString()} = ${diff.toLocaleString()}`;

                if (!this.accepted) state.recordError({ level: 'migration', code: "service hours mismatch", force: true, evt: this, msgs: [line1, line2] });
                state.service_hour_mismatch = diff;
            }
        }
    }
    apply(state) {
        if (this.date.equals(state.date) || this.date <= state.date) {
            this.computed_hours = state.service_hours; // - state.ptd_service_hours;
            this.met = Math.abs(this.computed_hours - this.migrated_hours) < 0.5;
            if (!this.met && this.possibles.length > 0) { // if at first you don't succeeed...
                //DEBUG_HOURS(2, `ATTEMPTED: ${this.str}@${state.date}:  originally ${this.original_date}`);
                this.date = this.possibles.shift();
                state.deferred_events.push(this);
                return false;
            }
            this.finalize(state); // wherever we are, there we shall be
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
