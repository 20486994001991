import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";


export class WithdrawalRepaymentEligibilityExpires extends Event {
    get id() { return `REPAYELIG:${this.withdrawal.id}:${this.date - 0}:${this.withdrawal.amount}`; }
    get str() { return `REPAYMENT ELIG @${this.date} $${this.withdrawal.amount} `; }

    constructor(withdrawal, next_employment) {
        super();
        this.withdrawal = withdrawal;
        if (next_employment) this.date = new EventDate(next_employment, 5, 'years');
    }

    _endWindow(state) {
        //TODO: add these tags
        //state.removeTag(Tag.RepayEligible);
        // state.addTag(this.id, Tag.PermanentWithdrawal);
        state.recordLabel({ date: state.date, series: "withdrawal", labels: [`withdrawal repayment window end${state.date > state.runpoint ? 's' : 'ed'}`], event: this, data: this.participation });
        this.withdrawal.span_ends = state.date;
        state.addDisjointServicePeriod(this.withdrawal.date, this.withdrawal.id, "perm_withdrawal"); // divide service prior to withdrawal for benefit calculations
    }

    _applyRepayment(state, repayment) {
        const withdrawal = repayment.covered_withdrawals.find(w => w === this.withdrawal);
        if (withdrawal) {
            withdrawal.span_ends = state.date;
            withdrawal.repaid = true;
            withdrawal.label.cancel("repaid");
            state.unsubscribe(this.reset_handler);
            state.unsubscribe(this.repaid_handler);
            this.date = this.reset_handler = this.repaid_handler = undefined;
            this.withdrawal.span_ends = state.date;
            if (withdrawal.saved_service) {
                state.restoreService(withdrawal.saved_service, repayment);
                if (withdrawal.reset_lbl) {
                    withdrawal.reset_lbl.cancel('repaid');
                }
            }
        }
    }

    insert(state, creator) {
        if (this.date) super.insert(state, creator);
        if (!state.vested) this.reset_handler = state.subscribe('five_year_break', (s, d) => this._endWindow(s));
        this.repaid_handler = state.subscribe('repayment', (s, p) => {
            if (p?.covered_withdrawals.some(w => w === this.withdrawal)) this._applyRepayment(s, p);
        });
    }

    apply(state) {
        if (!this.date) return false;
        if (state.date.equals(this.date)) {
            this._endWindow(state);
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
}
