// synced with prod
const app_style = 'background: #4F004E; color: #E2DCFF; font-size: smaller; padding: 3px; border-radius: 3px;';
import { LitElement, html, css } from 'lit';
import '../shared-components/login.js';
import '../shared-components/router.js';
import '../shared-components/snackbar.js';
import { colors, shadows } from '../shared-components/styles.js';
import { CognitoAuthMgr } from '../shared-components/utilities/authmgr.js';
import { timeline_colors, timeline_classes } from "../components/colors.js";

import { client } from '../queries/client.js';
// Pages:
import { PeoplePage } from '../pages/people.js';
import { PersonPage } from '../pages/person.js';
import { AppSettingsPage } from '../pages/app-settings.js';
import { UserPrefsPage } from '../pages/user-prefs.js';
import { ImportExportPage } from '../pages/import-export.js';
import { ImportFactorsPage } from '../pages/import-factors.js';
import { ReportsPage } from '../pages/reports.js';
import { ViewReport } from '../pages/report.js';
import { FastProcessPage } from '../pages/process-fast.js';
import { ProcessPending } from '../components/bg-process-pending.js';


const routes = [
  { component: PeoplePage, path: "^/$", nav: "/", name: "People"},
  { component: ImportExportPage, path: "^/files[/]?$", nav: "/files", name: "Import/Export"},
  { component: ImportFactorsPage, path: "^/factors[/]?$", nav: "/factors", name: "Import/Factors"},
  { component: ReportsPage, path: "^/reports[/]?$", nav: "/reports", name: "Reports"  },
  { component: AppSettingsPage, path: "^/settings[/]?$", nav: "/settings", name: "Settings" },

  // second level 
  { component: PersonPage, path: "^/people/[view|edit].*", parent: '/'},
  { component: ViewReport, path: "^/reports/view.*", parent: '/reports', name: "Reports" },
  { component: ViewReport, path: "^/reports/(.+)[/]?", parent: '/reports', name: "Reports" },

  // not in nav menu
  { component: PeoplePage, path: "^/people[/]?$", name: "Search", icon: 'search' },
  { component: UserPrefsPage, path: "^/prefs[/]?$" },
  { component: FastProcessPage, path: "^/process(fast)*[/]?$", name: "Process (Fast)" },
];

const authmgr = new CognitoAuthMgr({
  login: () => client.clearStore(),
  logout: () => client.clearStore(),
  pool: { UserPoolId: 'us-east-1_8eABgmznc', ClientId: '70jm43tqfje6ii2ifulhh3ulb7' }
});
window.authmgr = authmgr;

const benefits_app_style = css`
        :host {
          overflow: visible;
          font-family: 'Roboto', 'Noto', sans-serif;
          display: block;
          position: relative;
          --top-bar-color: var(--paper-green-700);
          ${colors}
          ${shadows}
          ${timeline_colors}
        }
        ${timeline_classes}

        kale-router {
          z-index: -1;
        }
        kale-router[authorized] {
          z-index: 10;
        }
        kale-router[suppress] {
          display: none;
        }
        login-box {
          z-index: 100;
        }
        login-box[authorized]{
          display: none;
        }
        #buildnumber {
          position: fixed;
          bottom: 0px;
          left: 0px;
          font-size: 10px;
          color: black;
          z-index: 1000;
          opacity: 0.5;
          font-weight: 100;
        }

        .test-overlay {
            z-index: 1000;
            position: fixed;
            top: -50vh;
            left: -50vw;
            //width: 200vw;
            //height: 200vh;
            opacity: 0.10;
            pointer-events: none;
        }
`;

class BenefitsApp extends LitElement {
  static styles = benefits_app_style

  constructor() {
    super();
    document.addEventListener("snackbar", e => this.showSnack(e.detail));
    window.addEventListener("update-available", e => this.new_version_notice(e.detail));
    window.addEventListener("current-version", e => this.version_notice(e.detail));
    this.login = null;
    this.app_name = "Benefits";
    this.version_string = "v0.0.0-unknown"
  }

  async firstUpdated() {
    window?.report_version?.();
  }

  get logged_in() {
    return this.login_state && this.login_state === 'LOGGED IN'
  }
  version_notice({release}) {
    console.debug("%cAPP: CURRENT VERSION", app_style, release);
    this.current_release = release;
    this.version_string = `${release?.semver ? `v${release?.semver} ` : ''}(${release?.commit})`
    window.localStorage.setItem('benefits_build_number', release?.commit);
    window.localStorage.setItem('benefits_version', release?.semver);

    const host = window.location.host.split(':')[0].split('.')[0].toLowerCase();
    const dev_strings = {
      'benefits-test': `test build ${this.version_string}`,
      'localhost': `local dev build`
    }
    this.dev_string = dev_strings[host];
    this.app_name = this.dev_string ? `Benefits [${this.dev_string.toUpperCase()}]` : 'Benefits';
  }
  async new_version_notice(update) {
    let seen = ['commit', 'semver', 'stage'].every(v => this?.pending_release?.[v] === update?.new_release?.[v]);
    console.warn("%cAPP: NEW VERSION NOTICE", app_style, `seen=${seen}`, update?.new_release);
    if (!seen) {
      this.pending_release = {...update?.new_release, do_update: update?.do_update};
    
      if (this.update_snack) await this.update_snack?.cancel?.();
      this.update_snack = await this.showSnack({
        text: html`
          <div>new version available</div> 
          <div style="font-size: 70%">
          <span style="font-weight: 900">${update.new_release.semver ?? ''}</span> <a style="color: white;" href=${`https://github.com/afscme/benefits/tree/${update.new_release.commit}`}>${update.new_release.commit}</a>
        </div>
          `,
        action_text: 'install',
        action: update.do_update,
        duration: this.logged_in ? 1000 : 60*60,
        kind: 'upgrade'
      });
    }
  }
  render() {
    
    return html`
      ${this.logged_in ? html`
      <kale-router 
        .routes=${routes} 
        .app=${{ name: "Benefits System", version: this.version_string, release: this.current_release, pending_release: this.pending_release }} 
        .process=${this.process_progress}
        @pagetitle=${e => { document.title = e.detail.title ? `${this.app_name} | ${e.detail.title}` : this.app_name }}
        @signout=${e => this.handleSignout()} 
        @snackbar=${e => this.showSnack(e.detail)} 
        @panel-opened=${async e => { if (this.update_snack) { await this.update_snack?.cancel?.(); this.update_snack = null; } }}
        .login=${this.login}
        ></kale-router>

        
        ` : html``}
      <login-box id="login" header="Benefits System" .version=${this.version_string} @snackbar=${e => this.showSnack(e.detail)} ?authorized=${this.login_state === 'LOGGED IN'} @login=${e => this.handleLogin(e)}></login-box>
      <kale-snackbar id="snacks"></kale-snackbar>

      <div id="buildnumber">${this.version_string}</div>
      ${
      // translucent warning overlay for test builds
      this.dev_string ? html`
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 25px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold; font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); `}></div>
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 20px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold; font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); transform: translate(100px, 100px);`}></div>
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 15px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold;font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); transform: translate(-100px, -100px); `}></div>
        `: ''
      }

    `;
    /*
    
    <svg 
    width='100' height='100' style='background-color: lightblue'> <g transform='translate(25, 75) rotate(-45)'><text x='0' y='0' style=' fill: black; text-transform: uppercase; font-weight: 100; '>test</text></g>
</svg>


    <svg width="100" height="100" style="background-color: lightblue">
                <g transform="translate(25, 75) rotate(-45)"><text x="0" y="0" style="
        fill: black;
        text-transform: uppercase;
        font-weight: 100;
    ">test</text></g>
              </svg>
    
    
    */

  }
  handleLogin(e) {
    this.login_state = e.detail.state;
    this.login = e.detail.login;
    this.update_snack?.cancel?.();
    this.background_processor = new ProcessPending(status => this.updateProcessorStatus(status));
  }
  updateProcessorStatus(status) {
    this.process_progress = {...status};
  }
  handleSignout() {
    window.authmgr.logout();
    this.background_processor?.cancel?.();
    this.background_processor = null;
  }

  async showSnack(s) {
    if (!this.snacks) {
      this.snacks = this.renderRoot.getElementById('snacks')
    }
    if (this.snacks) {
      return await this.snacks.queueSnack(s);
    }
  }
  static get properties() {
    return {
      login_state: { type: String },
      login: { type: Object },
      current_release: { type: Object },
      pending_release: { type: Object },
      process_progress: { type: Object },
      version_string: { type: String },
    };
  }
}

window.customElements.define('benefits-app', BenefitsApp);
