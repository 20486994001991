import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { DEBUG_CTRB, MONEY, Tag } from "../pension-timeline-logic.js";

export class ContributionDeficiency extends Event {
    get id() { return `DEFICNT: ${this.date - 0} `; }
    get str() { return `CONTRIBUTION DEFICIENCY @${this.date} (expected a contribution by: ${this.expected})`; }

    constructor() {
        super();
        this.last_contribution = null; //new EventDate(last_contribution);
        this.date = null; //new EventDate(last_contribution, 1, "year");



        //this.date.add(60, "day");  // grace period
        //this.deficient_date = new EventDate(last_contribution, 1, "year");
        this.recorded = new Map();
    }

    subscribe(state) {
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  SUBSCRIBING`);
        if (this._basis_sub) {
            state.unsubscribe(this._basis_sub);
            this._basis_sub = null;
        }
        if (this._contrib_sub) {
            state.unsubscribe(this._contrib_sub);
            this._contrib_sub = null;
        }
        this._basis_sub = state.subscribe('basis_change', (s, b) => this.basisChange(s, b));
        this._sub = state.subscribe('contribution', (s, c) => this.contribution(s, c));
    }

    basisChange(state, basis) {
        this.date = basis ? new EventDate(new Date(basis.getFullYear() + 1, 0, 1)) : null;
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  BASIS=${basis} -> DATE=${this.date}`);
    }

    nextDate(state) {
        let old = this.date;

        this.expected = this.date ? new EventDate(new Date(this.date.getFullYear() + 1, 0, 1)) : null;
        //this.deadline = this.expected; //new EventDate(this.expected, 90, 'days'); // a little leeway
        this.deadline = new EventDate(this.expected, state.projection ? 0 : 30, 'days'); //new EventDate(this.expected, 90, 'days'); // a little leeway
        this.date = this.deadline;
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  NEXT DATE -> ${old} -> ${this.date}`);
        // runpoint cleanup
        //if (this.date <= state.runpoint) state.deferred_events.push(this);
        if (this.date <= state.endpoint) {
            state.deferred_events.push(this);
        }
    }
    checkPercentage(state, ctrb) {
        DEBUG_CTRB(1, `${state.date}:[${this.str}] zero: ${(!ctrb.ee_pension_contribution > 0)} checking pct: ${ctrb.ee_pension_contribution}/${ctrb.eoy_base_salary} = ${ctrb.eoy_base_salary ? ctrb.ee_pension_contribution / ctrb.eoy_base_salary : '<DIV0>'}`);


        if (!ctrb.eoy_base_salary || !ctrb.eoy_base_salary > 0) {
            state.recordError({ level: 'alert', code: "missing salary data", date: state.date, msgs: ["missing salary"], evt: this });
            return;
        }
        if (ctrb.made_up) {
            return;
        }
        /*
        if (!ctrb.ee_pension_contribution > 0) {
            this.doDeficient(state, state.date);
            return;
        }*/
        let actual_rate = (ctrb.ee_pension_contribution || 0) / ctrb.eoy_base_salary;
        let service_fract = 2280 / state.calendar_ytd_service_hours; // TODO: figure this out, maybe track "contribution hours" or sth. - or should pension_salary theoretically already account for missing time?
        let adjusted_rate = actual_rate * (1 / service_fract);
        let target_rate = 0.06;
        /*
        if (state.show_logs) console.log(`%cCALC`, comp_stl, `
            ctrb: ${ctrb.ee_pension_contribution}
            sal: ${ctrb.eoy_base_salary}
            rate: ${actual_rate}
            svc: ${service_fract} (2280/${state.calendar_ytd_service_hours})
            adj: ${adjusted_rate}

        `)*/
        if (Math.abs((target_rate - adjusted_rate) / target_rate) > 0.2 && Math.abs((target_rate - actual_rate) / target_rate) > 0.2) { // 20% slop; TODO: maybe make this configurable?
            const direction = actual_rate === 0 ? 'zero' : (target_rate - adjusted_rate < 0 ? 'high' : 'low');
            state.recordError({
                level: 'alert',
                code: "wrong contrib amount",
                brief_text: `${direction} ctrb`,
                force: false,
                date: state.date,
                msgs: [
                    `${direction} contribution`,
                    `ee_ctrb = ${MONEY(ctrb.ee_pension_contribution)}, expected ~$${Math.round(service_fract * target_rate * ctrb.eoy_base_salary)}`
                ],
                evt: this
            });

            if (actual_rate === 0) {
                state.addTag(`${this.id}::alert`, Tag.ContributionsDeficient);
                if (!state.deficient_since) {
                    state.deficient_since = state.date;
                }
            }
        }
    }

    /*
    get date() {
        return this._date;
    }
    set date(d) {
        if (state.show_logs) console.warn(`%cCALC`, comp_stl, `date ${this._date} -> ${d}`);
        this._date = d;
    }*/
    contribution(state, ctrb) {
        this.last_contribution = ctrb;

        if (ctrb.expected_nonzero) this.checkPercentage(state, ctrb.contribution);

        let contrib_year = new Date(ctrb.date).getFullYear();
        if (this.recorded.has(contrib_year)) {
            let exist = this.recorded.get(contrib_year);
            exist.add(ctrb.contribution.employer_code);
        } else {
            this.recorded.set(contrib_year, new Set([ctrb.contribution.employer_code]));
        }

        //this.expected = new EventDate(ctrb.date, 1, 'year');
        /*
        let cdate = new EventDate(ctrb.date);
        let next = cdate.yearsUntil(this.expected) < 0.5 ? this.expected : new Date(this.expected.getFullYear() + 1, 0, 1);
        this.expected = new EventDate(next);
        if (state.show_logs) console.log(`%cCALC`, comp_stl, this.num, `CTRB DATE ${ctrb.date} ${old}->${this.expected}`)
        this.deadline = new EventDate(this.expected, 30, 'days'); //new EventDate(this.expected, 90, 'days'); // a little leeway
        this.date = this.deadline;
        */
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  GOT CONTRIB -> ${ctrb.str}(${ctrb.expected ? 'EXPCTD' : 'UNEXPECTED'}${ctrb.expected_nonzero ? '->NONZERO' : ''}), NEXT -> ${this.date}`);
        //if (this.date <= state.runpoint) state.deferred_events.push(this);
        return true;
    }


    doDeficient(state, date) {
        date = date ? date : this.expected;
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  DO DEF`);
        if (!date) { date = state.date; }
        let cutoff = new EventDate(new Date(date.getFullYear() - 1, 0, 1)); // 1 year prior
        let contrib_year = cutoff.getFullYear();
        let missing_employers = state.anyActivity('employment')
            .filter(e => state.contributing
                && e.span_begins <= date
                && (!e.span_ends || e.span_ends > cutoff))
            .filter(e => !(this.recorded.has(contrib_year) && this.recorded.get(contrib_year).has(e.employer_code)));
        //.map(e => e.employer_code)
        if (missing_employers.length > 0) {
            const { expecting_nonzero, expecting_census } = missing_employers.reduce((acc, e) => {
                if (state.contributing && date >= state.contributing && (!e.span_ends || e.span_ends > state.contributing)) {
                    acc.expecting_nonzero.push(e);
                } else {
                    acc.expecting_census.push(e);
                }
                return acc;
            }, { expecting_nonzero: [], expecting_census: [] });
            //const expecting_census_only = missing_employers.filter(e => state.contributing && date < state.contributing && (!e.span_ends || e.span_ends > state.contributing));
            expecting_nonzero.forEach(e => {
                state.addTag(`${this.id}::alert`, Tag.ContributionsDeficient);
                //state.recordLabel({ date: this.deficient_date, series: "deficient_contributions", labels: ["deficient contributions"], event: this });
                DEBUG_CTRB(1, `${state.date}:[${this.str}]  DEFICIENT`);
                state.recordError({ level: 'alert', code: "deficient contributions", date: date, anachronism: true, msgs: ["missing contributions", `expecting contribution for ${e.employer_code}`], evt: this });
                if (!state.deficient_since) {
                    state.deficient_since = date;
                }
            });

            expecting_census.forEach(e => {
                DEBUG_CTRB(1, `${state.date}:[${this.str}]  DEFICIENT SAL.`);
                state.recordError({ level: 'alert', code: "missing census/contrib data", date: date, msgs: ["missing census", `missing data from ${e.employer_code}`], evt: this });
            });
        }
    }
    apply(state) {
        if (state.date > state.runpoint) { return false; }
        if (state.date.equals(this.date)) {
            //let years_since = this.last_contribution ? this.last_contribution.date.yearsUntil(this.expected) : null;
            DEBUG_CTRB(1, `${state.date}:[${this.str}]  APPLYING`);
            //let service_transitions = state.in_service_spans.filter(s => (!this.last_contribution || s.date >= this.last_contribution.date) && s.date <= this.expected);
            //let expecting_contributions = service_transitions.length > 0 || state.in_service2;
            //if (state.show_logs) console.log(`%cCALC`, comp_stl, "CONTRIBUTIONS DEF", this.str, state.date, expecting_contributions, service_transitions, state.in_service, state.expecting_contributions)
            if (state.expecting_contributions) { this.doDeficient(state); }
            this.nextDate(state);
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
