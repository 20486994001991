import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { comp_stl, Tag } from "../pension-timeline-logic.js";

export class Contributing extends Event {
    constructor(first_hired, basis, participating) {
        super();
        this.participating = participating;
        this.basis = basis;
        this.date = participating;
        this.first_hired = first_hired;
        if (first_hired < EventDate.mandatory_contrib_change) {
            let anniversary_date = new EventDate(first_hired, 3, "years");
            this.date = participating > anniversary_date ? participating : anniversary_date;
        } else {
            this.date = participating;
        }
    }
    get id() { return `CTRBTOR:${this.basis}:${this.date - 0}-${this.participating - 0}`; }
    get str() {
        return `MAND. CONTRIBUTOR @${this.date} (participant @${this.participating})`;
    }
    insert(state) {
        super.insert(state);
    }
    apply(state) {
        if (this.participating !== state.participating || state.basis !== this.basis) {
            if (state.show_logs) console.warn("%cCALC", comp_stl, "[DEFUNCT]", this.str, state.date, state.par);
            return false; // we are no longer applicable to this timeline
        }
        if (state.show_logs) console.log("CTBRTING", this.date, state.date);

        if (this.date.equals(state.date) || this.date <= state.date) {
            if (!state.in_service) {
                state.subscribe('in_service', (state, { was, now }) => {
                    if (!now) {
                        return true; // keep listening
                    }

                    this.date = state.date;
                    state.pending_events.push(this);
                });
                return false;
            }
            /*
            if (!state.employed && !this.original_date) {
                console.error(`%cCALC`, comp_stl, state.date, "SHOULD BE EMPLOYED!");
                this.original_date = this.date;
                //FIXME: this should really be redone using a subscription - perhaps to 'service_change' events
                this.date = state.returning; //run again when employed
                if (this.date) state.pending_events.push(this); // only requeue if we'll be called on
                return false
            }*/
            if (!state.isOptedIn()) {
                //console.error(`%cCALC`, comp_stl, state.date, "SHOULD BE OPTED IN");
                // subscribe to any opt in events and sleep until then
                if (state.show_logs) console.log("%cCALC", comp_stl, state.date, "CONTR: NOT OPTED IN: SLEEPING");
                state.subscribe('opt_in', (state) => {
                    if (state.show_logs) console.log("%cCALC", comp_stl, state.date, "CONTR: WOKE UP: GOT OPT IN");
                    this.date = state.date;
                    state.pending_events.push(this);
                });
                return false;
            } else {
                if (!state.contributing) {
                    this.date = state.date;
                    state.contributing = this.date;
                    if (state.in_service) state.addTag(this.id, Tag.Contributor);
                    const lbl = state.recordLabel({ date: this.date, series: "contribution_begin", labels: ["contributor"], event: this });
                    state.subscribe('service_reset', () => lbl.cancel('lost service'));
                }
                return true;
            }
        }

        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
