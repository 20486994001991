import { AgeEvent } from "./AgeEvent.js";
import { Tag } from "../pension-timeline-logic.js";

export class AgeVesting extends AgeEvent {
    constructor(dob, participating) {
        super(dob, 65);
        this.participating = participating;
        if (this.date < this.participating) {
            //console.warn("%cCALC", comp_stl, `setting age vest date forward ${this.date} => ${this.participating}`)
            this.date = this.participating;
        }
    }
    get id() { return `AGEVEST:${this.date - 0}`; }
    get str() { return `Vested based on age @${this.date}`; }
    apply(state) {
        if (state.participating !== this.participating || state.endpoint < this.date || state.vested || !state.currentlyParticipating()) { //FIXME: was state.runpoint
            // no longer / never applicable
            return false;
        }
        if (state.date >= this.date && state.basis && state.currentlyParticipating()) {
            this.date = this.date >= state.participating ? this.date : state.participating;
            state.vested = this.date;
            state.removeTag(Tag.NotVested);
            state.addTag(this.id, Tag.Vested);
            state.recordLabel({ date: this.date, series: "vesting", labels: ["vested", `age=${this.dob.yearsUntil(this.date).toFixed(0)}`], event: this });
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
