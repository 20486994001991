import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';

import { address_fields } from './address.js';
import { email_fields } from './email.js';
import { phone_fields } from './phone.js';
import { person_note_fields } from './note.js';
import { beneficiary_fields } from './beneficiary.js';
import { employment_fields } from './employment.js';
import { suspension_fields } from './suspension.js';
import { benefit_fields } from './benefit.js';
import { benefit_payment_fields } from './benefit_payment.js';
import { participation_change_fields } from './participation.js';
import { contribution_fields } from './contribution.js';
import { withdrawal_fields } from './withdrawal.js';
import { withdrawal_repayment_fields } from './withdrawal_repayment.js';
import { service_adjustment_fields } from './service_adjustment.js';
import { person_tag_fields } from './tag.js';
import { person_migration_fields } from './migration.js';
import { projection_fields } from './projection.js';
import { data_checkpoint_fields } from './data_checkpoint.js';
import { salary_history_fields } from './salary_history.js';


export const simple_person_fields = gql`
  fragment SimplePersonFields on person {
    id
    first_name
    middle_name
    last_name
    ssn
    birth_date
    deceased_date
    person_suffix_code
    person_title_code
    person_marital_status_code
    person_gender_code
  }
`

export const person_fields = gql`
fragment PersonFields on person {
  id
  legacy_pension_id
  first_name
  middle_name
  last_name
  ssn
  birth_date
  deceased_date

  person_suffix_code
  suffix { code name }
  person_title_code
  title { code name }
  person_marital_status_code
  marital_status { code name }
  person_gender_code
  gender { code name }

  updated


  addresses { ...AddressFields }
  emails { ...EmailFields }
  phones { ...PhoneFields }
  bookmarks { person_id user_email }
  notes { ...NotesFields }

  benefactors { 
    designation_date
    removal_date
    benefactor {
      id
      first_name
      middle_name
      last_name
    }
    beneficiary_relationship_code
    relationship {
        code
        name
    }
    beneficiary_type_code
    beneficiary_type {
      code
      name
    }
  }

  beneficiaries { ...BeneficiaryFields }
  employments { ...EmploymentFields }
  suspensions { ...SuspensionFields }
  benefits { ...BenefitFields }
  benefits_paid { ...BenefitPaymentFields }
  participation_changes { ...ParticipationChangeFields }
  contributions { ...ContributionFields }
  withdrawals{ ...WithdrawalFields }
  withdrawal_repayments { ...WithdrawalRepaymentFields }
  adjustments { ...ServiceAdjustmentFields }
  tags { ...PersonTagFields }
  migrations { ...MigrationFields }
  projections { ...ProjectionFields }
  salary_history { ...SalaryHistoryFields }
  data_checkpoints { ...DataCheckpointFields }

  timeline_cache { state report errors nodes years }
  changes { id user timestamp action table old new object_id}
}
${address_fields}
${email_fields}
${phone_fields}
${person_note_fields}
${beneficiary_fields}
${employment_fields}
${suspension_fields}
${benefit_fields}
${benefit_payment_fields}
${participation_change_fields}
${contribution_fields}
${withdrawal_fields}
${withdrawal_repayment_fields}
${service_adjustment_fields}
${person_tag_fields}
${person_migration_fields}
${projection_fields}
${salary_history_fields}
${data_checkpoint_fields}
`


// Info included in people listing
export const person_search_fragment = gql`
fragment PersonSearchFields on person {
  id
  first_name
  middle_name
  last_name
  birth_date
  deceased_date
  suffix { name }
  addresses {
    assigned_date
    address_status_code
    address_state_code
    preferred
    city
  }
  emails { email email_status_code }
  phones { number phone_type_code phone_status_code }

  employments {
    employer_code
    employer { name }
    start_date
  }
  notes { id }
  tags {
    person_tag_type_code
    tag_type { priority name deprecated }
  }
  bookmarks { user_email }
}
`


/// Used for transmittal row matching
export const person_match_fragment = gql`
fragment PersonMatchFields on person {
  id
  first_name
  middle_name
  last_name
  birth_date
  ssn
  deceased_date
  suffix {
    name
  }
  employments {
    employer_code
    employer {
      code
      name
    }
    start_date
    end_date
  }
  contributions {
    id
    contribution_date
    eoy_base_salary
    ytd_base_pay
    ee_pension_contribution
    pension_salary
    made_up
    employer_code
  }
} `

export const person_info_query = gql`
query person_info($PersonId: uuid!){
  person(where: { id: { _eq: $PersonId } }){
         ...PersonFields
  }
}
${person_fields}
`

/*

export class PersonInfo extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_info_query;
  }
  data_impl({ data: { person: [d] } }) {
    return d;
  }
  vars_from_impl(data) {
    if ('id' in data) {
      return { PersonId: data.id }
    }
  }
}*/

export class PersonInfo extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_info_query;
  }
  data_impl({ data: { person: [d] } }) {
    return d;
  }
  vars_from_impl(data) {
    if ('person_id' in data) {
      return { PersonId: data.person_id }
    } else if ('id' in data) {
      return { PersonId: data.id }
    }
  }
}

export const person_insert_query = gql`
mutation insert_person($changeMap: person_insert_input!){
  person: insert_person(
    objects: [$changeMap]
  ) {
    returning {
         ...PersonFields
    }
  }
}
${ person_fields}
`

export const person_update_query = gql`
mutation update_person($personId: uuid!, $changeMap: person_insert_input!){
  update_person(
    where: { id: { _eq: $personId } },
    _set: $changeMap
  ) {
    affected_rows
    returning {
         ...PersonFields
    }
  }
}
${ person_fields}
`

export const person_upsert_query = gql`
mutation upsert_person($changeMap: person_insert_input!, $changeCols: [person_update_column!]!){
  person: insert_person(
    objects: [$changeMap],
    on_conflict: {
    constraint: person_pkey,
    update_columns: $changeCols
  }
  ) {
    returning {
         ...PersonFields
    }
  }
}
${ person_fields}
`

export class EditPersonInfo extends SaveableQuery {
  get friendly_name() { return "Person" }
  get required_keys_impl() {
    return ["id"];
  }
  get gql_impl() {
    return person_info_query;
  }
  data_impl({ data: { person: { returning: [d] } } }) {
    return d;
  }
  /*
  add_keys_impl(data) {
    if (!data.updated) {
      data.updated = new Date();
      return data;
    }
  }*/
  get mutate_gql_impl() {
    return person_upsert_query;
  }
  get save_queries_impl() {
    return [];
  }
}

