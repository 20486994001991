import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { ComputeSimulationTotal } from "../pension-timeline-logic.js";

export class AgeEvent extends Event {
    constructor(dob, age) {
        super();
        this.dob = new EventDate(dob);
        //this.age = age;
        this.date = new EventDate(dob, Math.floor(age), "years");
        let frac_age = age - Math.floor(age);

        if (frac_age > 0) {
            let next_year = new EventDate(this.date, 1, "year");
            let span_ms = next_year - this.date;
            let frac_ms = span_ms * frac_age;
            this.date = new EventDate(this.date - 0 + frac_ms);
        }
    }

    get age() { return this.dob.yearsUntil(this.date); }
    get id() { return `AGE:${this.age}:${this.date - 0}`; }
    get str() { return `Age ${this.age} reached @${this.date}`; }

    apply(state) {
        if (state.endpoint < this.date) { //FIXME: was state.runpoint
            // not reachable within timeline bounds
            return false;
        }
        if (state.date.equals(this.date)) {
            return true;
        }
        state.pending_events.push(this);
        return false;
    }

    check_for_end_condition(state) {
        if (!this.end_cond_reached && state.end_cond(this)) {
            let sim_total = new ComputeSimulationTotal(this.date);
            sim_total.insert(state);
        }
    }
}
