//THIS HAS BEEN DEPRECATED. NOT USED IN THE CALCULATION. MAYBE REMOVE?

import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { MONEY, comp_stl, EstimatedContribution, round2 } from "../pension-timeline-logic.js";

export class RecordEOYInterest extends Event {
    get id() { return `EOYINT:${this.date - 0}`; }
    get str() { return `EOYINT @${this.date} ${this.amount ? MONEY(this.amount) : ''}`; }

    constructor(date) {
        super();
        this.date = new EventDate(new Date(date.getFullYear() + 1, 0, 0)); // dec 31
        this.amount = null;
    }

    //FIXME: if there are midyear contributions we need still need to estimate contributions for rest of year
    needs_estimated_contrib(state) {
        let date = state.date;
        let cutoff = new EventDate(new Date(date.getFullYear(), 0, 1)); // 1 year prior
        if (state.last_contribution.date.yearsUntil(state.date) < 1) {
            if (state.show_logs) console.warn("%cCALC", comp_stl, "#### CHK CTRB EST ####", state.date, state.last_contribution.date);
            return false;
        }
        // FIXME: Technically we aren't checking if employment span is a contributing status
        let all = state.anyActivity('employment')
            .filter(e => state.contributing
                && e.span_begins <= date
                && (!e.span_ends || e.span_ends > cutoff));
        // .filter(e => !(this.recorded.has(contrib_year) && this.recorded.get(contrib_year).has(e.employer_code)));
        return (all.length > 0);
    }

    apply(state) {
        if (state.date.equals(this.date)) {
            //if (state.projection && state.date >= state.runpoint && state.last_contribution && this.needs_estimated_contrib(state)) {
            //    //                if (state.show_logs) console.warn("%cCALC", comp_stl, "#### DO CTRB EST ####", state.date, this.date);
            //    let est = new EstimatedContribution(this.date, state, "This is RecordEOYInterest.apply");
            //    console.warn("how many times does this get called", est);
            //    est.apply(state);
            //    //                if (state.show_logs) console.log("%cCALC", comp_stl, state.contribs);
            //}

            //            if (state.show_logs) console.warn("%cCALC", comp_stl, "#### DID CTRB EST ####", state.date, this.date, [...state.contribs]);
            let years = 0;
            let nominal_interest = 0;
            let amt = 0;
            let cancelation = 0;
            if (state.last_contribution) {
                //years = state.last_contribution.date.yearsUntil(this.date);
                years = state.contribs.length > 0 ? state.contribs[state.contribs.length - 1].date.yearsUntil(this.date) : 0;
                nominal_interest = (state.contribution_balance * (0.05 * (Math.round(years * 12) / 12)));
                nominal_interest = round2(nominal_interest);
            }
            let incremental_interest = nominal_interest - state.ytd_deposited_interest;
            this.amount = incremental_interest;
            if (Math.abs(this.amount - round2(state.ytd_contribution_interest)) > 0.25) {
                if (state.show_logs) console.warn("%cCALC", comp_stl, state.date, "EOYINT: int and ytd not the same", nominal_interest, state.ytd_contribution_interest);
            }

            if (this.amount > 0) {
                let { total_contribs, total_int } = state.contribs
                    .filter(c => c.total_contribs > 0)
                    .reduce((tots, ctr) => ({
                        total_contribs: tots.total_contribs + ctr.amt,
                        total_int: tots.total_int + ctr.interest
                    }),
                        {
                            total_contribs: amt,
                            total_int: incremental_interest
                        });
                //                console.log("CONT+INT",total_contribs, total_int);         
                total_contribs = round2(total_contribs);
                total_int = round2(total_int);
                //let total_int = round2(state.contribs.reduce((tot, ctr) => tot + ctr.interest, interest));
                //TODO: need to confirm whether we want to use nominal vs incremental interest in contribution_balance
                state.contribution_balance = round2(state.contribution_balance + incremental_interest + amt - cancelation); //total_contribs + total_int;
                state.contribution_interest = round2(state.contribution_interest + incremental_interest); //total_int;
                state.ytd_deposited_interest += round2(incremental_interest);

                let ctrb = {
                    type: 'interest',
                    contribution_id: null,
                    contribution_date: this.date,
                    date: this.date,
                    years_since_last: years,
                    auto_int: true,
                    amt: 0,
                    canceled: 0,
                    interest: incremental_interest,
                    total_contribs: total_contribs,
                    total_interest: total_int,
                    balance: state.contribution_balance,
                };
                state.push_contrib(ctrb);
                //if (state.show_logs) console.log(`%cCALC`, comp_stl, "AUTO INT", `d: ${this.date.nb_str}, ysl: ${years},  int: ${interest} -- tc: ${total_contribs} ti: ${total_int} bal: ${state.contribution_balance}`);
                //console.table(`%cCALC`, comp_stl, state.contribs.slice());
            }
            // runpoint cleanup
            //if (state.date < state.runpoint && state.contribution_balance > 0) {
            if (state.date < state.endpoint && state.contribution_balance > 0) {
                this.date = new EventDate(new Date(this.date.getFullYear() + 2, 0, 0));
                this.amount = null;
                state.pending_events.push(this);
            }

            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
