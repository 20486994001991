import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { COMPUTE_CONTRIBUTION_INTEREST, MONEY, round2, Tag, EmploymentBegin } from "../pension-timeline-logic.js";
import { WithdrawalRepaymentEligibilityExpires } from "./WithdrawalRepaymentEligibilityExpires.js";
import { WithdrawalYTDContribActualize } from "./WithdrawalYTDContribActualize.js";
import {EmploymentEnd} from "./EmploymentEnd.js";

export class Withdrawal extends Event {
    get is_primary() { return true; }
    get id() { return `WDRAWL:${this.withdrawal.id}:${this.date - 0}:${this.total}`; }
    get str() { return `WITHDRAWAL @${this.date} $${this.total} (int = $${this.interest}, taxbl = $${this.taxable})`; }

    constructor(withdrawal) {
        super();
        this.withdrawal = withdrawal;
        this.date = new EventDate(withdrawal.withdrawal_date);
        this.total = withdrawal.total;
        this.interest = withdrawal.interest;
        this.taxable = withdrawal.taxable;
        this.ytd_contributions = null; //withdrawal.ytd_contributions;

        this.pred_total = 0;
        this.pred_interest = 0;
        this.pred_taxable = null;
        this.pred_ytd_interest = 0;
    }

    backInterestThrough(d) {
        return COMPUTE_CONTRIBUTION_INTEREST(this.total, this.date.yearsUntil(d));
    }

    apply(state) {
        if (state.date.equals(this.date)) {
            if (state.contribution_balance === 0) { // no recorded contributions b/c of migrated withdrawal
                //state.recordError({ level: "migration", code: "no contrib record", evt: this, msgs: ["missing contribution records", "b/c of migrated withdrawal"] });
            }
            let contribs = state.contribution_balance > 0 ? state.contribution_balance : this.total;
            if (this.total > 0 && this.ytd_contributions === null) {
                // infer ytd from total vs total contrib 
                this.ytd_contributions = this.total - contribs; // (state.contribution_balance + state.ytd_contribution_interest);
            }
            /*
            this.pred_total = state.contribution_balance + state.ytd_contribution_interest + this.ytd_contributions;
            this.pred_interest = state.contribution_interest + state.ytd_contribution_interest;
            this.pred_ytd_interest = state.ytd_contribution_interest;
            if (state.show_logs) console.warn(`%cCALC`, comp_stl, `${this.str}`);
            if (state.show_logs) console.warn(`%cCALC`, comp_stl, `ytd_int = ${state.ytd_contribution_interest}`);
            if (state.show_logs) console.warn(`%cCALC`, comp_stl, `last_contrib = ${state.last_contribution ? state.last_contribution.date : 'none'}`);
            if (state.show_logs) console.warn(`%cCALC`, comp_stl, `last_contrib.years = ${state.last_contribution ? state.last_contribution.date.yearsUntil(state.date) : 'no contrib'}`);
            if (state.show_logs) console.warn(`%cCALC`, comp_stl, `calc = ${state.contribution_balance} (bal) * .05 * ${state.last_contribution ? state.last_contribution.date.yearsUntil(state.date) : 0} (years)`);
            */
            //if (!state.vested) { this.taxable = this.interest } // non-vested means taxable total = interest total 
            // REMOVED 
            //this.withdrawn_balance = state.contribution_balance + this.ytd_contributions;
            //this.withdrawn_interest = state.contribution_interest;
            // FOR COMPLETELY EXPLICIT AMOUNTS:
            this.withdrawn_balance = contribs;
            this.withdrawn_interest = this.interest;

            this.pred_total = this.total;
            this.pred_interest = this.interest;
            this.pred_ytd_interest = 0;

            if (this.withdrawn_balance === 0) {
                this.withdrawn_balance = this.total;
            }
            if (this.withdrawn_interest === 0) {
                this.withdrawn_interest = this.interest;
            }

            if (this.total === 0) {
                state.recordError({
                    level: 'alert',
                    code: "withdrawal problem",
                    brief_text: "nothing withdrawn",
                    force: false,
                    date: this.expected_date,
                    msgs: [
                        "nothing withdrawn",
                        `contrib balance = ${MONEY(state.contribution_balance)}`
                    ],
                    evt: this
                });
            }

            state.contribution_balance = 0;
            state.contribution_interest = 0;
            state.ytd_deposited_interest -= round2(this.interest);

            state.contribs = [];

            state.withdrawn = state.date;
            state.addDisjointServicePeriod(this.date, this.withdrawal.id, "withdrawal"); // divide service prior to withdrawal for benefit calculations
            this.repaid = false;

            state.push_contrib({
                type: 'withdrawal',
                is_repayment: false,
                is_withdrawal: true,
                contribution_id: null,
                withdrawal_id: this.withdrawal.id,
                contribution_date: state.date,
                date: state.date,
                amt: -this.total,
                auto_int: false,
                canceled: 0,
                interest: -this.interest,
                total_contribs: 0,
                total_interest: 0,
                balance: 0
            });

            state.addActivity('withdrawal', this);
            state.removeTag(Tag.ContribIn);
            state.addTag(this.id, Tag.ContributionsRemoved);
            //state.addTag(this.id, Tag.OptedOut);
            //FIXME: contrib cancel does nothing?
            state.addContributionCancelation(this, this.ytd_contributions, new EventDate(new Date(state.date.getFullYear() + 1, 1, 0)));
            new WithdrawalYTDContribActualize(this.date, this.ytd_contributions).insert(state);

            //if (state.show_logs) console.log(`%cCALC`, comp_stl, this.str, `${state.vested ? 'vested' : 'not vested'} at time of withdrawal`);
            if (!state.vested) {
                this.saved_service = state.saveService();
                this.reset_lbl = state.resetService(this, '(withdrawal)');
            }

            const lbls = [
                `${MONEY(this.pred_total)} withdrawn`,
                `${this.taxable !== null ? MONEY(this.taxable) : 'TBD'} taxable ${this.withdrawn_interest > 0 ? `, ${MONEY(this.pred_interest)} in interest` : ''}`
            ];

            this.label = state.recordLabel({ date: this.date, series: "withdrawal", labels: lbls, event: this, data: this.withdrawal });

            if (state.employed) {
                state.addPermanentWithdrawal(this.id, state.date, this.withdrawal.id);
                state.participating = null;
                state.contributing = null;
                this.lbl = state.recordLabel({ date: this.date, series: "opt_out", labels: ["permanently withdrawn"], event: this, data: this.participation });


                // the employment span after this withdrawal should be "non participating",
                // so we insert a synthetic end and begin
                //
                let emp = state.employment;
                let transition_date = new EventDate(state.date);
                const ineligible_emp = {
                    ...emp,
                    span_ends: transition_date
                }
                state.reviseEmploymentSpanEnd(emp.id, transition_date);

                let newbegin = new EmploymentBegin(ineligible_emp, transition_date, true);
                let newend = new EmploymentEnd(emp, transition_date);

                newend.synthesized_by = this;
                newend.insert(state);
                newbegin.synthesized_by = this;
                newbegin.insert(state);
                // the synthesized begin will take care of inserting the final end, so set a flag
                // on the current emp so it doesn't do it
                this.emitted_end = true;
            } else {
                new WithdrawalRepaymentEligibilityExpires(this, state.next_employed).insert(state);
            }
            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
