// import { SimpleTimeline } from "../../client/src/benefits-app/pension-timeline-logic"
import {SimpleTimeline} from 'benefits/src/benefits-app/pension-timeline-logic.js';

export const sanitizePII = (obj, propsToRemove=['ssn', 'addresses', 'emails','first_name', 'last_name', 'middle_name', 'phones', 'suffix', 'person_suffix_code', 'person_title_code', 'title', 'person_gender_code', 'gender', 'notes', 'tags', 'bookmarks', 'changes', 'data_checkpoints', 'timeline_cache', 'migrations', 'benefits_paid']) => {
    if(Array.isArray(obj)){
        return obj.map(item => sanitizePII(item, propsToRemove))
    } else if (obj instanceof Date) {
        return obj
    }
    else if (obj !== null && typeof obj === 'object'){
        return Object.keys(obj).reduce((acc, key) => {
            if(!propsToRemove.includes(key)){
                acc[key] = sanitizePII(obj[key], propsToRemove)
            }
            return acc;
        }, {})
    }

    return obj
}

const writeCalcInput = async(obj) => {
    const personObj = obj.debug_capture.person
    const projectionObj = obj.debug_capture.projection
    const factorObj = obj.debug_capture.db_factors

    const personString = personObj ? JSON.stringify(personObj, Object.keys(personObj).sort()) : 'null';
    const projectionString = projectionObj ? JSON.stringify(projectionObj, Object.keys(projectionObj).sort()) : 'null'
    const factorString = factorObj ? JSON.stringify(factorObj, Object.keys(factorObj).sort()) : 'null'

    const encoder = new TextEncoder();
    const data = encoder.encode(personString + projectionString + factorString);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashString = Array.from(new Uint8Array(hashBuffer)).map(b => b.toString(16).padStart(2, '0')).join('').slice(0, 6);
    const fileName = `${obj.debug_capture.person.id}__${new Date(obj.debug_capture.runpoint).toISOString().split('.')[0]}Z__${hashString}.json`

    const sanitized = sanitizePII(obj.debug_capture);

    const {runpoint, person, projection, db_factors} = sanitized
    const build = null;
    
    //run sanitized data through calc again:
    const timeline = new SimpleTimeline(
        person, 
        projection, 
        build, 
        db_factors, 
        false, 
        runpoint);
    const result = timeline.run();

    const {debug_capture} = result;


    const blob = new Blob([JSON.stringify(debug_capture, null, 2)], {type: "application/json"});
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
};

export const generateCalcInputFile = (obj) => {
    try{
        console.log(`Writing the following obj to file: ${JSON.stringify(obj, null, 2)}`)
        writeCalcInput(obj)
    } catch (err){
        console.error("Error writing input file: ", err);
    }
}
