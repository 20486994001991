import { comp_stl } from "../pension-timeline-logic.js";

/*
maybe to add:
Tag.AdjustedService

*/
export class Event {
    constructor() {
        this.date_insufficient = 0;
        this.next_count = 0;
    }
    apply(state) {
        // return true if applied, false if not applicable or no-op
        // must push self back on events list if still potentially applicable
        return false;
    }
    insert(state, creator) {
        if (this.duplication_key && creator) {
            if (state.event_duplication_prevention_map.has(this.duplication_key)) {
                console.error("%cCALC", comp_stl, this.person_id, `!!! DUPLICATE !!!  >>> ${this.str} <<< from: <<[${creator.str}]>>`);
                if (state.show_logs) console.log("%cCALC", comp_stl, "PREVIOUS FROM", state.event_duplication_prevention_map.get(this.duplication_key).str);
                this.basis = null;
            } else {
                state.event_duplication_prevention_map.set(this.duplication_key, creator);
            }
        }

        state.pending_events.push(this);
        if (this.subscribe) {
            this.subscribe(state);
        }
    }

    get str() { return "TIMELINE EVENT"; }
    get id() { return "TIMELINEEVENT"; }

    get interesting() {
        return false;
    }
    snapshot() {
        return (({ id, date, uuid }) => ({ id, date, uuid }))(this);
    }
}
