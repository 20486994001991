import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { DEBUG_HOURS, SuspensionServiceAdjustment, comp_stl } from "../pension-timeline-logic.js";

export class ServiceMonth extends Event {
    get id() { return `SVCMNTH:${this.basis - 0}:${this.number}`; }
    get str() { return `MONTH[${this.number}] @${this.date} [${this.basis} + ${this.number}]`; }

    resetDate() {
        this.date = new EventDate(this.basis, this.number);
        this.eom = new EventDate(this.date, 1);
        this.duplication_key = `${this.basis}::${this.number}`;
    }
    updateNumber(date, at_least) {
        // find the correct service month boundary for the given date
        let incr = at_least ? Math.ceil(this.date.monthsUntil(date)) : Math.floor(this.date.monthsUntil(date));
        DEBUG_HOURS(1, `updating from ${this.date} to ${at_least ? 'at least' : '<='} ${date} => ${this.date.monthsUntil(date)}=${incr} months => ${new EventDate(this.basis, this.number + incr)}`);
        this.number += incr;
        this.resetDate();
    }
    constructor(basis, number, evt) {
        super();
        this.basis = basis;
        this.number = number;
        this.date = new EventDate(basis, number);
        this.eom = new EventDate(this.date, 1);
        this.duplication_key = `${basis}::${number}`;
        this.last_applied = null;
    }

    periodFromDate(d) {
        return Math.floor(this.basis.monthsUntil(d));
    }

    appliedFor(date) {
        return this.last_applied && this.last_applied >= this.periodFromDate(date);
    }
    serviceChange(state, { was, now }) {
        if (state.basis !== this.basis) {
            // we're not relevant any longer
            this.stopEvents(state);
            return;
        }
        if (now && !this.appliedFor(state.date)) {
            DEBUG_HOURS(2, `${this.str}@${state.date}: woke up by in_service change: ${was} => ${now}`);
            this.updateNumber(state.date, now);
            this.addService(state);
        } else {
            this.svc_handler = state.subscribe('in_service', (s, a) => this.serviceChange(s, a)); // keep alive
            DEBUG_HOURS(1, `${this.str}@${state.date}: ignoring in_service change: ${was} => ${now}`);
        }
    }

    stopEvents(state) {
        if (this.svc_handler) {
            DEBUG_HOURS(2, `${state.date} stopping events`);
            state.unsubscribe(this.svc_handler);
            this.svc_handler = null;
        }
    }

    startEvents(state) {
        this.stopEvents(state);
        DEBUG_HOURS(2, `${state.date} starting events`);
        this.svc_handler = state.subscribe('in_service', (s, a) => this.serviceChange(s, a));
    }

    applyBanked(state) {
        /*
        apply 'unlimited' bank hours, and 'limited' bank hours
        */
        let adjustment = 0;
        let type = null;

        // try 'unlimited' bank, if any
        let unlimited = state.service_bank.find(b => b.expires >= state.date && b.type === "unlimited");
        if (unlimited) {
            adjustment += 190;
            type = "unlimited";
        } else {
            // there weren't any unlimited banks to use
            // so try the finite pool
            let limited = state.service_bank.filter(b => b.amount > 0 && b.expires >= state.date && b.type === "limited");
            for (b of limited) {
                let wanted = 190 - adjustment;
                let withdrawal = wanted <= b.amount ? wanted : b.amount;
                adjustment += withdrawal;
                b.amount -= withdrawal;
                type = "limited";
            }
        }
        if (adjustment > 0) {
            new SuspensionServiceAdjustment(this.date, type, adjustment).insert(state);
            DEBUG_HOURS(1, `${this.str}@${state.date}: applied suspension adj = ${adjustment}`);
        }
    }
    applyFT(state) {
        // working FT, not opted out, not withdrawn, and if on leave, it's paid; apply full monthly increment
        state.addServiceHours(190, state.date, this);
        state.addMonthlySalaryData(this, this.basis, this.number, this.eom);
        //let period = this.periodFromDate(state.date);
        DEBUG_HOURS(1, `${this.str}@${state.date}: normal 190 => ${state.ptd_service_hours} (${state.service_hours})`);
    }
    addService(state) {
        if (state.on_adjusted_suspension) {
            this.applyBanked(state);
        } else if (state.full_time) {
            this.applyFT(state);
        } else if (!state.part_time) {
            if (state.show_logs) console.warn("%cCALC", comp_stl, `${this.str}@${state.date}: addService(), but nothing to do?`);
        }
        this.last_applied = this.number;
        this.number += 1;
        this.resetDate();

        state.log_service('hours');

        this.stopEvents(state); // if any
        state.deferred_events.push(this);
    }

    apply(state) {
        if (state.date >= this.date) {
            //if (state.show_logs) console.log(`%cCALC`, comp_stl, "SVCMTH", state.date, state.service_hours, state.ptd_vesting_years, state.vesting_years)
            if (state.basis !== this.basis || state.date >= state.endpoint || state.date.equals(state.endpoint)) {
                this.stopEvents(state);
                DEBUG_HOURS(2, `${this.str}@${state.date}: farewell cruel world`);
                return false; // forget about us
            }
            if (state.in_service) {
                this.addService(state);
                return true;
            } else {
                DEBUG_HOURS(2, `${this.str}@${state.date}: NOT in service, sleeping`);
                this.startEvents(state);
                return false;
            }
        }
        state.pending_events.push(this);
        return false;
    }
}
