import { Event } from "./Event.js";
import { EventDate } from "../eventdate.js";
import { MONEY, DEBUG_CTRB } from "../pension-timeline-logic.js";


export class WithdrawalYTDContribActualize extends Event {
    get id() { return `WDRAW_YTD_ACTUAL:${this.date - 0}`; }
    get str() { return `WITHDRAWAL YTD ACTUAL: ${MONEY(this.remaining)} remaining @${this.date} (withdrawal: ${MONEY(this.ytd_contrib)} from ${this.withdrawal_date})`; }

    constructor(withdrawal_date, ytd_contrib) {
        super();
        this.withdrawal_date = withdrawal_date;
        this.expected_date = new EventDate(new Date(withdrawal_date.getFullYear(), 11, 31));
        this.allowed_date = new EventDate(new Date(withdrawal_date.getFullYear() + 1, 3, 1));
        this.ytd_contrib = ytd_contrib;
        this.date = this.expected_date;
        this.contribs = [];
        this.remaining = ytd_contrib;
    }

    subscribe(state) {
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  SUBSCRIBING`);
        if (this._basis_sub) {
            state.unsubscribe(this._basis_sub);
            this._basis_sub = null;
        }
        if (this._contrib_sub) {
            state.unsubscribe(this._contrib_sub);
            this._contrib_sub = null;
        }
        this._basis_sub = state.subscribe('basis_change', (s, b) => this.basisChange(s, b));
        this._sub = state.subscribe('contribution', (s, c) => this.contribution(s, c));
    }

    basisChange(state, basis) {
        DEBUG_CTRB(1, `${state.date}:[${this.str}]  BASIS=${basis} -> DATE=${this.date}`);
    }

    contribution(state, ctrb) {
        if (ctrb.date <= this.expected_date || (this.remaining > 0 && ctrb.date <= this.allowed_date)) {
            this.contribs.push(ctrb);
            this.remaining = this.remaining - ctrb.contribution.ee_pension_contribution;
        }
    }
    apply(state) {

        //if (state.date > state.runpoint) return false;
        if (state.date > state.endpoint) return false;
        if (state.date.equals(this.date)) {
            if (this.remaining > 0 && state.date < this.allowed_date) {
                // wait a couple more months
                this.date = this.allowed_date;
                state.pending_events.push(this);
                return false;
            }
            DEBUG_CTRB(1, `${state.date}:[${this.str}]  APPLYING`);

            if (this.remaining > 0) {
                state.recordError({
                    level: 'alert',
                    code: "wrong contrib amount",
                    brief_text: "low/missing final contrib",
                    force: false,
                    date: this.expected_date,
                    msgs: [
                        "insufficient final contributions",
                        `expected ${MONEY(this.ytd_contrib)} from withdrawal @${this.withdrawal_date.nb_str}`,
                        `received only ${this.ytd_contrib - this.remaining}`
                    ],
                    evt: this
                });
            } else if (this.remaining < 0) {
                state.recordError({
                    level: 'alert',
                    code: "wrong contrib amount",
                    brief_text: "unexpected final contrib",
                    force: false,
                    date: this.expected_date,
                    msgs: [
                        "surplus final contributions",
                        `expected ${MONEY(this.ytd_contrib)} from withdrawal @${this.withdrawal_date.nb_str}`,
                        `received ${this.ytd_contrib + (-this.remaining)}`
                    ],
                    evt: this
                });
            }

            state.unsubscribe(this._basis_sub);
            state.unsubscribe(this._sub);

            return true;
        }
        state.pending_events.push(this);
        return false;
    }
    get interesting() {
        return true;
    }
}
